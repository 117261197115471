/*
 * 업무구분: 가입설계
 * 화 면 명: MSPPI250P
 * 화면설명: 발행 팝업
 * 접근권한: 
 * 작 성 일: 2023.03.16
 * 작 성 자: ---
 */
<template>
  <ur-page-container class="msp" title="발행" type="subpage" :show-title="true" :topButton="true" :topButtonBottom="180" @on-header-left-click="fn_HistoryBack('close')" :action-type="currTab === '2' ?  'no' : lv_EltrnSignBtnDisable ? actionSlotDisabled : actionSlot">
   <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="pal2430">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment msp-segment-ty1">
          <mo-segment-wrapper solid primary v-model="currTab">
            <mo-segment-button value="1" @click="fn_onClickTabLabel('1')">상품설명서(제안용)</mo-segment-button>
            <mo-segment-button v-if="lv_ShowAfter" value="2" @click="fn_onClickTabLabel('2')">청약서</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>
    </template>
    <!-- // 고정영역 -->
    <!-- 컨텐츠 영역 -->

    <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box">
        <span class="ns-title mb10">색상</span>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment pb30">
          <mo-segment-wrapper solid primary v-model="lv_Color" @class="chip-type-wrap">
            <mo-segment-button v-for="(item, index) in lv_ColorTypes" :key="index" :value="item.key">{{item.label}}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container> 
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="h10px bgcolor-3"></ur-box-container>

      <template v-if="lv_ShowMiddle || lv_ItemLists_1.length > 0">
        <!-- 상품설명서 START -->
        <ur-box-container v-show="currTab === '1'" alignV="start" componentid="" direction="column" class="ns-list-area pb0">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum ">
          <mo-list-item>
            <mo-checkbox v-model="lv_AllCheck1" :value="lv_ChkCnt1 === lv_ItemLists_1.length" size="small" @input="fn_SelectAllItemsFunc_1"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title" @click="fn_SelectAllItemsFunc_1(!lv_AllCheck1)">
                  <span class="sum">총<span class="count">{{lv_ChkCnt1}}</span>건</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa1624">
            <mo-list :list-data="lv_ItemLists_1">
              <template #list-item="{item}">
                <mo-list-item class="bd-b-Ty1">
                  <mo-checkbox v-model="item.checked" :disabled="fn_DisableCheck_1(item)"/>
                  <div class="list-item__contents" @click="fn_ClickRowItem1(item)">
                    <div class="list-item__contents__title fexTy5 mb0">
                      <span class="name fwn">{{item.zaPblCntnt}}</span>
                      <mo-badge class="badge-box sm" text="" shape="status" :class="fn_SetClass(item.title02)">{{item.title02}}</mo-badge>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container> 
        </ur-box-container>
        <!-- 상품설명서 END -->
        <!-- 청약서 START -->
        <ur-box-container v-show="currTab === '2'" alignV="start" componentid="" direction="column" class="ns-list-area pb0">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum ">
            <mo-list-item>
              <mo-checkbox v-model="lv_AllCheck2" :value="lv_ChkCnt2 === lv_ItemLists.length" size="small" @input="fn_SelectAllItemsFunc"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title" @click="fn_SelectAllItemsFunc(!lv_AllCheck2)">
                  <span class="sum">총<span class="count">{{lv_ChkCnt2}}</span>건</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list list-pa1624">
            <mo-list :list-data="lv_ItemLists">
              <template #list-item="{item}">
                <mo-list-item class="bd-b-Ty1">
                  <mo-checkbox v-model="item.checked"
                               :disabled="fn_DisableCheck(item)"
                               />
                  <div class="list-item__contents" @click="fn_ClickRowItem(item)">
                    <div class="list-item__contents__title fexTy5 mb0">
                      <span class="name fwn">{{item.zaPblCntnt}}</span>
                      <mo-badge class="badge-box sm" text="" shape="status" :class="fn_SetClass(item.title02)">{{item.title02}}</mo-badge>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container> 
        </ur-box-container>
        <!-- 청약서 END -->
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="h10px bgcolor-3"></ur-box-container> 
        <!-- 계약자 주소 START -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024 pt15 pb70 bdBN">
          <mo-list-item>
            <div class="list-item__contents full">
              <div class="list-item__contents__title fexTy5">
                <mo-checkbox v-model="lv_Chk2" @input="fn_CheckAddressData(1)"/> 
                <span class="name txtSkip fwn" @click="fn_CheckAddressData">계약자 주소 인쇄</span> 
              </div>
              <div class="list-item__contents__info pt5 pb0">  
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="lv_AddressType" @click="fn_ChangeAddressType" :disabled="!lv_Chk2" class="chip-type-wrap disabled-type2">
                    <mo-segment-button v-for="(item, index) in lv_AddressTypes" :key="index" :value="item.key">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
              <div class="list-item__contents__info pt20 pb0">
                <mo-text-field class="form-input-name full" underline v-model="lv_Address" readonly :disabled="!lv_Chk2" placeholder="주소를 입력해 주세요" />
              </div>  
            </div> 
          </mo-list-item>  
        </ur-box-container>
        <!-- 계약자 주소 END -->
      </template>
    </ur-box-container>

    <!-- 버튼 영역 보일때 공간 띄워주기 위한 세팅 -->
    <div v-show="lv_isBottomBtn" class="ns-height150"></div>

    <ur-box-container direction="column" alignV="start" class="bottom-sheet-ty--1 ty--3 box-shadow-type-4">
      <!-- 상품설명서 -->
      <ur-box-container v-show="currTab === '1' && lv_isPymInfoObjYnBtn" alignH="between" componentid="" direction="row" class="mb50 pt0">
        <div class="ns-btn-relative-area pl24 pr24">
          <div class="relative-div mb30 mt10">
            <mo-button class="btn_toolbar maxauto" v-show="lv_isPymInfoObjYnBtn" @click="fn_PymInfoIapConfirm">고객지급정보 안내</mo-button>
          </div>
        </div>
      </ur-box-container>
      <!-- 청약서 -->
      <!-- GA매니저 가입설계 대행시에만 버튼 보임 -->
      <ur-box-container v-show="currTab === '2' && lv_BtnCnt > 0 && vGssAuthYN === 'Y'" alignH="between" componentid="" direction="row" class="mb50 pt0">
        <div class="ns-btn-relative-area pl24 pr24">
          <div class="relative-div mb30 mt10">
            <mo-button class="btn_toolbar ty--blue maxauto" @click="fn_ConfirmPrcsPreSancNextPrc('btnMobps')" :disabled="lv_gaSaveDisable">저장</mo-button>
            <mo-button class="btn_toolbar ty--blue maxauto" @click="fn_GAMoveTSSPS112P" :disabled="!lv_gaSaveDisable">모바일청약</mo-button>
          </div>
        </div>
      </ur-box-container>
      <!-- GA매니저 가입설계 대행시에만 버튼 보임 -->
      
      <ur-box-container v-show="currTab === '2' && lv_BtnCnt > 0 && vGssAuthYN !== 'Y'" alignH="between" componentid="" direction="row" class="mb80 pt0" >
        <div class="ns-btn-relative-area pl24 pr24">
	        <template v-if="lv_PreSancObjNum === '1'">
	    		<!-- 1.사전결재 -->    
	            <div class="full fex-rw">
	              <mo-button class="ns-btn-round white sm w100px minWmax" @click="fn_BtnPreSancPrcsClickHandler()">사전결재</mo-button>
	              <div class="crTy-bk7 fs14rem ml10">
	                <span class="dsInline indent15 crTy-blue3">※ 최종 완료하려면 사전결재 요청후, 반드시 [전자서명] 또는 [모바일청약] 버튼을 눌러주세요.</span><!-- crTy-blue3 추가 2024.12.04:mijin-->
	              </div>
	            </div>
	            <!-- 추가 2024.12.04:mijin-->
	            <div class="full mt5">
	              <div class="crTy-bk7 fs14rem ml10">
	                <span class="dsInline indent10">&bull; 사전결재 완료시, 현장접수 가능</span><br/>
	                <span class="dsInline indent10">&bull; 결재완료시 알림이 옵니다.</span><br/>
	                <span class="dsInline indent10">&bull; 설계청약(메뉴) → 사전결재 현황에서 확인 가능합니다.</span>
	              </div>
	            </div>
	        </template>        
	        <template v-if="lv_PreSancObjNum === '2'">
	        	<!-- 2.사전결재 진행중 -->
		        <div class="full fexTy3">
		        	<div>
		        		<mo-icon icon="msp-round-blue">msp-round-blue</mo-icon>
		        		<span>사전결재 진행중</span>
		        	</div>
		        	<div class="lhauto">
		        		<span>사전결재 현황</span>
		        		<mo-icon icon="msp-arrow-right">msp-arrow-right</mo-icon>
		        	</div>
		        </div>
		        <div class="crTy-bk7 fs13rem mt5">※ 사전결재완료시, 현장접수 가능</div>
	        </template>
	        <template v-if="lv_PreSancObjNum === '3'">
		        <!-- 3.사전결재완료 -->
		        <div class="full fexTy3" v-show="!lv_EltrnSignBtnDisable">
		        	<div class="lhauto">
		            	<mo-icon icon="msp-task_alt">msp-task_alt</mo-icon>
		            	<span class="ml3 crTy-blue3">신청완료</span>
		            </div>
		            <div class="lhauto">
		            	<span>사전결재 현황</span>
		            	<mo-icon icon="msp-arrow-right">msp-arrow-right</mo-icon>
		            </div>
		        </div>
		        <div class="crTy-bk7 fs13rem mt5 ml21">※ 사전결재완료시, 현장접수 가능</div>-->
	        </template>
            <!-- #2020모바일청약, 모바일청약 버튼 신규
              전자서명 버튼 표시 조건
              + 기본 조건 : lv_ShowAfter
              + 모바일청약 가능 상품 확인 : moblOfrObjYn (UF1PQL0395_LocalCallBack > zPIEntplPblItmListDto.zaMoblOfrObjYn > moblOfrObjYn) : ep에서 설정
              + 모바일청약 가능여부 : isMobpsSrvcPsb (모바일영업지원 관리자 > 업무Config)
              + 모바일청약 버튼 표시 여부 : lv_ShowMobpsSignBtn (MSPPI250P.vue에서 검증)
              + 계약자 가상화폐 업체 여부 : pblAMLFg (MSPPI250P.vue에서 검증) ASR230200315_(가입설계)가상화폐제공업체 AML로직 적용
            -->

          <!--2025.01  모바일청약 활성화를 위한 UI개선 테스트위하여 잠시 주석
           *******lv_isEltrnBtn -> 조건주가함 local일때는 무조건 활성화 되도록 변경해둠 (테스트를 위하여)******
          <div class="relative-div mb30 mt10">          
            <template v-if="lv_Env === 'local'">
              <mo-button class="btn_toolbar ty--blue maxauto" @click="fn_ConfirmPrcsPreSancNextPrc('btnNsEusPrcs')" :disabled="lv_EltrnSignBtnDisable">전자서명</mo-button>
            </template>
            <template v-else>
              <mo-button class="btn_toolbar ty--blue maxauto" v-if="lv_isEltrnBtn" @click="fn_ConfirmPrcsPreSancNextPrc('btnNsEusPrcs')" :disabled="lv_EltrnSignBtnDisable">전자서명</mo-button>
            </template>
            <mo-button class="btn_toolbar ty--blue maxauto" v-if="lv_isMobileBtn" @click="fn_ConfirmPrcsPreSancNextPrc('btnMobps')" :disabled="lv_EltrnSignBtnDisable">모바일청약</mo-button>
            <mo-button :class="lv_BtnCnt === 1 ? 'btn_toolbar maxauto' : 'btn_toolbar'" v-if="lv_isPymInfoObjYnBtn" @click="fn_PymInfoIapConfirm">고객지급정보 안내</mo-button>
          </div>
          -->

          <div class="bottomButtonBox" :class="[{'type1':lv_isEltrnBtn===true && lv_isMobileBtn===true}, {'type2':lv_isEltrnBtn===false && lv_isMobileBtn===true}]">        
            <mo-button class="btn_toolbar" :class="{'ty--blue':lv_isMobileBtn === false }" v-if="lv_isEltrnBtn" @click="fn_ConfirmPrcsPreSancNextPrc('btnNsEusPrcs')" :disabled="lv_EltrnSignBtnDisable">전자서명</mo-button>
            <mo-button class="btn_toolbar ty--blue" v-if="lv_isMobileBtn" @click="fn_ConfirmPrcsPreSancNextPrc('btnMobps')" :disabled="lv_EltrnSignBtnDisable">모바일청약</mo-button>
               <div v-if="lv_isEltrnBtn===true && lv_isMobileBtn===true && lv_showHideTooltip === true" class="chat-bubble type-M1 type1" @click="lv_showHideTooltip=false"> 
                                     모바일 청약으로 더욱 쉽고 빠르게<br>청약해 보세요.<br>
               &dot; 입력하다 조금만 틀려도 바로 고쳐줘요<br>
               &dot; 서명은 한 번만 해도 돼요<br>
               &dot; 별도의 앱 설치가 필요 없어요
                <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
              </div>
            <mo-button :class="lv_BtnCnt === 1 ? 'btn_toolbar maxauto' : 'btn_toolbar'" v-if="lv_isPymInfoObjYnBtn" @click="fn_PymInfoIapConfirm">고객지급정보 안내</mo-button>
          </div>

        </div>
      </ur-box-container>
      <ur-box-container alignH="between" componentid="" direction="row">
        <div class="btn_icon_box--jjh ty--2">
          <mo-button componentid="" class="ns-btn-round message" v-if="vGssAuthYN !== 'Y'" :disabled="lv_EltrnSignBtnDisable" @click="fn_BtnNsEusPrcsClickHandler('btnMnlLms')">문자</mo-button>
          <mo-button componentid="" class="ns-btn-round email_link2" v-if="vGssAuthYN !== 'Y'" :disabled="lv_EltrnSignBtnDisable" @click="fn_BtnNsEusPrcsClickHandler('btnEmail')">이메일</mo-button>
          <mo-button componentid="" class="ns-btn-round print2" v-if="vGssAuthYN !== 'Y'" :disabled="lv_EltrnSignBtnDisable" @click="fn_ConfirmPrcsPreSancNextPrc('btnPrint')">인쇄</mo-button>
          <mo-button componentid="" class="ns-btn-round preview" :disabled="lv_EltrnSignBtnDisable" @click="fn_ConfirmPrcsPreSancNextPrc('btnPreview')">미리보기</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
    <!-- // 컨텐츠 영역 -->

    <!-- 
      [바텀시트 영역]
      1. 언어선택 : refSelectLanguage
      2. 고객 지급정보 : refPymInfoIapFlag
      3. 이메일 발송 전 확인 : refPopEmailConfirmFlag
      4. 미리보기 : refPrcsEntplEltrnSignAlertFlag
      5. 인쇄 : refProcPrintFlag
      6. 미리보기 재시도 : refShowRePreview
      7. 인쇄 재시도 : refShowRetryPrintFlag
      8. 전자청약 : refPrcsEtapSend
      9. 기본 : refShowCheck
      10. 무고지 관련 Confirm : refShowNoDsclsConfirm
      11. 일반 에러 메시지 : refAlertMsg
      12. 이메일 등록 컨펌 메시지 : refRegEmailAddr
      13. 사전결재 : refPrcsPreSancAlertFlag
    -->
    <!-- Popup : 언어선택 -->
    <!-- <mo-bottom-sheet preventTouchClose ref="refSelectLanguage" class="ns-bottom-sheet msp-bottomSt-alert"> -->
    <mo-bottom-sheet ref="refSelectLanguage" class="ns-bottom-sheet" @closed="fn_CloseBottomSheet('refSelectLanguage')">
      <template v-slot:title>언어 선택</template>
      <template v-slot:action>  
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type mb20">
          <mo-button @click="fn_ClickBtnLag('1')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '1' }">영어<mo-icon icon="msp-check-bold"/></mo-button><!-- class명 on시 파란색 선택 입니다. -->
          <mo-button @click="fn_ClickBtnLag('2')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '2' }">일본어<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_ClickBtnLag('3')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '3' }">베트남어<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_ClickBtnLag('4')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '4' }">중국어<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_ClickBtnLag('5')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '5' }">태국어<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_ClickBtnLag('6')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '6' }">러시아어<mo-icon icon="msp-check-bold"/></mo-button>
          <mo-button @click="fn_ClickBtnLag('7')" class="ns-btn-check" :class="{ on: lv_SelectedLag === '7' }">몽골어<mo-icon icon="msp-check-bold"/></mo-button>
        </ur-box-container>
      </template>
      <!-- class명 on시 파란색 선택 입니다. -->
    </mo-bottom-sheet>
    <!-- 언어선택 Popup End -->

    <!-- 2022.07.29_ASR220600568_[사랑on 가설] 지급정보 관련 알림톡 발송_youngki78.lee -->
    <!-- 고객 지급정보 안내 -->
    <mo-bottom-sheet ref="refPymInfoIapFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <!-- <div class="bottom-sheet-ty--1 ty--2 pt14" v-if="lv_isPymInfoIapFlag"> -->
        <div class="content-area">
          <div class="fs17rem">
            <p>당타사 지급정보를 고객이 직접 확인할 수 있도록 고객님 핸드폰으로 알림톡을 전송합니다.</p>
            <p>필수컨설팅 동의가 확보된 고객 본인만 조회할 수 있으며 컨설턴트 등 제3자는 조회 불가합니다.</p>
            <p>
              컨설턴트는 고객 상담과정에서 취득한 정보를 제공받은 목적 외로 이용하거나 제3자에게 누설할 수 없습니다. 
            </p>
            <p>아래 휴대폰 번호 확인 후 전송 바랍니다.</p>
          </div> 
          <div class="round-box bgcolor-blue fs16rem mt20">
            <div class="fexTy5">
              <span class="min70 fs14rem crTy-bk7">고객명</span>
              <span class="ml12 crTy-blue2">{{lv_ShowPymInfoIapMsg.custNm}}</span>
            </div>
            <div class="fexTy5 mt4">
              <span class="min70 fs14rem crTy-bk7">휴대폰번호</span>
              <span class="ml12 crTy-blue2">{{lv_ShowPymInfoIapMsg.celno}}</span>
            </div>
          </div>        
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="$refs.refPymInfoIapFlag.close()" class="ns-btn-round white">취소</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_SendPymInfoIap" class="ns-btn-round blue">발송</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 이메일 발송전 확인 -->
    <mo-bottom-sheet ref="refPopEmailConfirmFlag" class="ns-bottom-sheet msp-bottomSt-alert" @closed="fn_CloseBottomSheet('refPopEmailConfirmFlag')">
      <!-- content 영역 -->
        <div class="content-area">
          <div class="round-box bgcolor-blue fs16rem">
            <div class="fexTy5">
              <span class="min40 fs14rem crTy-bk7">계약자</span>
              <span class="ml17">{{lv_PopEmailConfirmMsg.zaInsrdNm}}</span>
            </div>
            <div class="fexTy2 mt4">
              <span class="min40 fs14rem crTy-bk7">이메일</span>
              <span class="ml17 crTy-blue2">{{lv_PopEmailConfirmMsg.emailAddr}}</span>
            </div>
          </div>
          <div class="fs17rem mt20">
            이메일 주소가 틀리거나 없는 경우에는 상품설명서(제안용)을 이메일로 받으실 수 없습니다.<br/>
            이메일이 없는 경우에는 반드시 [등록/수정] 버튼을 눌러서 이메일 주소를 등록해 주시기 바랍니다.
          </div>           
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_MoveCustmerCard" class="ns-btn-round white">등록/수정</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_Command_P7" class="ns-btn-round blue">발송</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 미리보기, 전자서명, 모바일청약, E-mail 전송 Alert -->
    <mo-bottom-sheet ref="refPrcsEntplEltrnSignAlertFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_PrcsEntplEltrnSignAlertMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelprcsEntplEltrnSign" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmPrcsEntplEltrnSign" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 2025.01 모바일청약UI Alert -->
    <mo-bottom-sheet ref="refPrcsEntplEltrnSignAlertFlagNEW" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_PrcsEntplEltrnSignAlertMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ConfirmPrcsEntplEltrnSign" name="전자서명">전자서명</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_ConfirmPrcsPreSancNextPrc('btnMobpsP6')" name="모바일청약">모바일청약</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!--사전결재 Alert -->

    <!-- Popup: 지급정보안내 -->
    <mo-bottom-sheet ref="refPrcsPreSancAlertFlag" :close-btn="false" class="ns-bottom-sheet msp-bottomSt-alert"><!--class="ns-bottom-sheet closebtn"-->
      <!--<div class="ns-btn-close" @click="fn_CancelprcsPreSanc"></div>-->
      <div class="content-area">
        <div class="ment txt-center w100 crTy-bk1">
        	<span class="fwn fs20">
    			<p class="ment">※ 대체/유의계약 대상건입니다.</p>
    		</span>
        	<span class="fwm">영업관리자 사전결재 요청하시겠습니까?</span><br/>
          <span class="fwn fs16">
          	<p class="ment" v-html="lv_AtntRplcContTxt"></p>
          </span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs ty--2 mbm4 mt20 ">
          <div class="relative-div">
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_CancelprcsPreSanc" class="ns-btn-round white dsD">아니오<span class="dsD fs13rem fwn mt2">(지점 접수만 가능)</span></mo-button>
            <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_ConfirmPrcsPreSanc" class="ns-btn-round blue">예</mo-button>
          </div>
          <!-- 중앙정력 class txt-center w100 추가 2024.10.25:mijin -->
          <!--<div class="crTy-bk7 fs13rem mt10 w50 ls--15">※ 아니오 클릭 시, 지점 접수만 가능</div>-->
        </ur-box-container>
      </div>
    </mo-bottom-sheet>
    <!-- Snackbar(toast) -->
    <mo-snackbar ref="actionSnackbar" stackable :duration="2500"> 
      사전결재 요청이 완료되었습니다.<br/>최종 완료하려면 [전자서명] 또는 [모바일청약] 버튼을 눌러주세요. 
    </mo-snackbar>
    <!-- // Popup: 지급정보안내 -->    
    
    <!-- 인쇄 팝업 -->
    <mo-bottom-sheet ref="refProcPrintFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_ProcPrintMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPrint" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_RequestPrint" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 미리보기 다시 팝업 -->
    <mo-bottom-sheet ref="refShowRePreview" class="ns-bottom-sheet msp-bottomSt-alert" @closed="fn_CancelCheckNas">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_ShowRePreviewMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelCheckNas" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_RetryCheckNas" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 인쇄 다시 팝업 -->
    <mo-bottom-sheet ref="refShowRetryPrintFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt" style="display:flex;">
          <img src="@/assets/img/icon/icon-bichumi-earth.png" style="width:152px; object-fit:contain; padding:0 20px 0 20px;"/>
          <p class="ment" v-html="lv_ShowRetryPrintMsg" style="padding:0 20px 0 0"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AlertPrcsClickHandler('btnPreview')" name="미리보기">미리보기</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelPrint" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_RetryPrint" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 전자 청약 전송 팝업 -->
    <mo-bottom-sheet ref="refPrcsEtapSend" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="this.lv_PrcsEtapSendMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_PrcsEtapSendCancel" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_PrcsEtapSend" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 기본 Confirm -->
    <mo-bottom-sheet preventTouchClose ref="refShowCheck" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="rsltMsgArray[0]"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CheckRestAlert" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>
    
    <!-- 무고지 관련 Confirm -->
    <mo-bottom-sheet preventTouchClose ref="refShowNoDsclsConfirm" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_noDsclsMessage[0]"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_showNoDsclsAlert(true)" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 기본 에러 alertMsg -->
    <mo-bottom-sheet ref="refAlertMsg" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_AlertMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$refs.refAlertMsg.close()" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 이메일 등록 컨펌 refRegEmailAddr -->
    <mo-bottom-sheet ref="refRegEmailAddr" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_RegEmailAddrMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$refs.refRegEmailAddr.close()" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveCustmerCard" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- [선심사 및 영업시스템 고도화PJT] 추가. 선심사 진행 중지여부 후 청약서 발행여부 확인 -->
    <mo-bottom-sheet preventTouchClose ref="refNundwConfirm" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_NundwConfirmMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$refs.refNundwConfirm.close()" name="아니오">아니오</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DelNundwInfo" name="예">예</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 오류메시지 START -->
    <mo-bottom-sheet ref="refBottomSheetError" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        <div class="ns-btn-close" @click="refBottomSheetError_close" name="닫기"></div>
      </template>
      
      <ur-box-container direction="column" alignV="start" alignH="center" class="con-area">   
        <mo-icon class="popupGuideIcon">errorIcon</mo-icon>
        <div class="popupGuideMessage" v-html="refBottomSheetError_errorMessge"></div>
        <div class="popupGuideMessageDetail" v-html="refBottomSheetError_errorMessgeDetail"></div>
        <div class="popupGuideCodeWrap" v-if="refBottomSheetError_errorCode.length && refBottomSheetError_errorCode !== 'X' && refBottomSheetError_errorCode !== 'NBC'">
          <div class="popupGuideToggleBtnWrap" @click="refBottomSheetError_showErrorCode">오류 코드 보기 <mo-icon icon="msp-arrow-down" class="popupGuideToggleBtn" :class="{'toggleUp': refBottomSheetError_errorCodeExpand}"></mo-icon></div>
          <div class="popupGuideCodeDetail" v-if="refBottomSheetError_errorCodeExpand" style="overflow:scroll; height:60vh;" v-html="refBottomSheetError_errorCode"/><!-- {{refBottomSheetError_errorCode}}</div>  -->
        </div>
        <div class="popupGuideLinks" v-if="refBottomSheetError_errorCode !== 'X' && refBottomSheetError_errorCode !== 'NBC'">

          <div><img src="@/assets/img/profile/ico_custmer.png" class="popupGuidePhoneIcon"> 문의 <strong>02-311-4500</strong></div>
          <!-- <div @click="fn_chatBotContent"><mo-icon>speechBalloon</mo-icon> 챗봇문의</div> -->
        </div>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div" v-if="refBottomSheetError_errorCode === 'X'">
            <mo-button componentid="mo_button_004" color="primary" shape="solid" size="medium" @click="refBottomSheetError_confirm('btnPrint')" class="ns-btn-round white">인쇄</mo-button>
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" @click="refBottomSheetError_confirm('btnPreview')" class="ns-btn-round blue">미리보기</mo-button>
          </div>
          <div class="relative-div" v-else>
            <mo-button componentid="mo_button_005" color="normal" shape="border" size="medium" @click="refBottomSheetError_close" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 오류메시지 END -->

    <!-- 메시지 로딩 -->
    <div class="msp loading--dim msp-in-page" v-show="isLoadingMsg">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-page-container>
</template>
<script>
import Gaf from '@/ui/pi/common/Gaf'
import GafMainFunc from '@/ui/pi/common/GafMainFunc'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import SHA256 from '@/ui/ps/comm/PSSha256'
import DateUtil from '@/ui/ps/comm/PSDateUtil'
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MSPCM300P from '@/ui/cm/MSPCM300P' // 고객카드 수정
import MSPSA063P from '@/ui/sa/MSPSA063P' // 화상상담
import MSPPI251P from '@/ui/pi/MSPPI251P' // 수신고객목록
import MSPPI252P from '@/ui/pi/MSPPI252P' // 터치On 문자발송
import MSPPI253P from '@/ui/pi/MSPPI253P' // 이메일 내용 입력
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPPI250P',
  screenId: 'MSPPI250P',
  isBackKeyHandle: true,
  components: {
    MSPCM300P,
    MSPSA063P,
    MSPPI251P,
    MSPPI252P,
    MSPPI253P
  },
  props: {
    'pParams': null,
    up: { // 옵션 리스트 위로펼침 여부
      type: Boolean,
      default: false
    }
  },
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      refBottomSheetError_errorMessge: '',
      refBottomSheetError_errorMessgeDetail: '',
      refBottomSheetError_errorCode: '',
      refBottomSheetError_errorCodeExpand: false,
      gachangeTF: false, // GA설계대행건 여부
      gaMobpsSrnId: '',
      gaMnputDataPage: {},
      lv_gaSaveDisable: false,
      vGssAuthYN: '', //ga매니저 권한
      name: 'MSPPI250P',
      isLoading: true,
      lv_isMobileEnv: '', // 스마트폰, 태블릿 환경(T:Tablet, P:Phone, O: other)
      popupMSPCM300P: null, // 고객카드 팝업
      popupMSPSA063P: null, // 화상상담 팝업
      popupMSPPI251P: null, // 수신고객목록 팝업
      popupMSPPI252P: null, // 터치On 문자 발송 팝업
      popupMSPPI253P: null, // 이메일 발송 팝업
      lv_ChgCustInfo: false, 
      // ---------------------------------------
      // lv_isSelectLanguage: false, // 언어선택-refSelectLanguage
      // lv_isPymInfoIapFlag: false, // 지급정보 관련 알림톡-refPymInfoIapFlag
      // lv_isPopEmailConfirmFlag: false, // 이메일 발송전 확인-refPopEmailConfirmFlag
      // ---------------------------------------
      // 언어선택 -----
      lv_SelectedLag: '', // 선택한 언어 index
      // 상품설명서 전송(LMS, 이메일) --- start
      lv_TargetType: '',
      sendMenuValue: {key: '', value: '', label: ''},
      sendMenuList: [{
        label: 'LMS 전송',
        key: 'MnlLMS',
        value: 'MnlLMS'
      },
      {
        label: '이메일전송',
        key: 'EMAIL',
        value: 'EMAIL'
      }],
      lv_IsShowTSSSA016P: false, // 수신고객목록 팝업창 호출 변수
      custListObj: [], // 수신고객목록에 전달하는 고객목록
      lv_SelectedItemList: [], // 선택된 자료 목록 저장
      lv_IsMadeMultiItem: true, // 다건 자료 선택
      lv_SelectedSingleItem: null, // 단건 자료 선택

      lv_IsShowTSSPI391P: false, // MnlLMS 내용입력/발송 팝업창 호출 변수
      // lv_IsShowMSPPI252P: false, // MnlLMS 내용입력/발송 팝업창 호출 변수
      lv_SmsTargetCustList: [], // MnlLMS 대상고객 목록
      lv_preface: '', // MnlLMS 문자 내용
      lv_pdfApndFilePathNm: '', // pdf 생성요청일(pdf 서버생성 폴더명)

      lv_RegEmailAddrMsg: '', // 이메일주소 없는 경우 등록 여부 컨펌메시지
      // 상품설명서 전송(MnlLMS, 이메일) --- end

      // 지워야할 변수
      lv_Item_Lists: [], // 로직에서 처리할 데이터 - itemLists와 동기화 필요
      // lv_IsSelectAll: true,
      // lv_SuccessSendEmailFlag: false,
      // lv_SuccessSendEmailmsg: '',
      // lv_ServerChoice: 'local',
      // STORAGE_ROOT_PATH: '/storage/emulated/0', // 로칼 업로드 파일 경로
      // PATH_PDF_DOWNLOAD: 'file.path.ofr.bs', // 서버 다운로드 Property 정보
      // PATH_PDF_UPLOAD: 'file.path.ofr.as', // 서버 업로드 Property 정보
      // LOCAL_PATH_PDF_UPLOAD: '/sli/temp/pdf_finish', // 로칼 업로드 파일 경로
      // 집주소
      homFrnoPstcd: '',
      homBknoPstcd: '',
      homAddr: '',
      // 직장주소
      jobpFrnoPstcd: '',
      jobpBknoPstcd: '',
      jobpAddr: '',
      isAlertMessage001: false,
      // chkvalue: '',
      // sliceCnt: 0,
      // custInfo: {},
      // MB_testFlg: false, // 테스트 모드
      lmsChlCustId: '',
      lv_MoIncompletePDFDataListVO: {},
      lv_Pdf_code: '', // 전자서명 받을 pdf파일명
      lv_ReTryYn: 'N', // 재시도여부
      fstIsuMthScCd: '', // 최초교부방법

      // ================== 화면 Binding 변수 ==================
      lv_AllCheck1: false, // 상품설명서(제안용) 전체 체크박스
      lv_AllCheck2: false, // 청약서 전체 체크박스
      lv_Color: '1', // default : 1 (흑백)
      // lv_Color: [{key: '1', label: '흑백'}],
      lv_ColorTypes: [
        {key: '1', label: '흑백'},
        {key: '2', label: '컬러'}
      ],
      lv_Chk2: false,
      lv_AddressType: '1', // default : 1(자택)
      // lv_AddressType: [{key: '1', label: '자택'}],
      lv_AddressTypes: [
        {key: '1', label: '자택'},
        {key: '2', label: '직장'}
      ],
      lv_AddressData: [],
      lv_Address: '', // '07380, 서울 영등포구 도림로 374, 다동 0924호 (신길7동,건영아파트)',

      lv_Chk1: [], // ['1'], 
      lv_ItemLists: [], // 화면에 표시할 목록 리스트
      lv_ItemLists_1: [], // 제안용 화면에 표시할 목록 리스트
      lv_SelectItems: [],
      lv_SelectItems_1: [], // 상품설명서 선택발행 리스트중 선택된 아이템 리스트
      lv_IsDataListEmpty: false,

      // POPUP show flag
      // lv_ShowTSSPI392P: false,
      lv_LangScCdArray: [], // 외국인 가입자 - 언어 선택
      lv_ShowTSSPI073P: false,
      lv_ParamForEmail: {}, // 이메일 팝업에 보낼 정보

      // 알림, 오류 메시지 출력
      lv_ShowErrorPopup: false,
      lv_ShowAlertMsgFlag: false,
      lv_AlertMsg: '',
      // lv_msgArr: [],

      // 무고지 알림
      lv_showNoDsclsConfirm: false,
      lv_noDsclsMessage: [],
      lv_msgArr: [],

      // 인쇄 메시지 출력
      lv_ProcPrintFlag: false,
      lv_ProcPrintMsg: '인쇄를 하시겠습니까?',
      lv_ShowRetryPrintFlag: false, // 예/아니오 팝업
      lv_ShowRetryPrintMsg: '', // 예/아니오 팝업

      // 진행 여부 확인
      lv_PrcsEntplEltrnSignAlertFlag: false,
      lv_PrcsEntplEltrnSignAlertMsg: '',
      lv_EltrnSignBtnDisable: false, // 진행 여부 "예"로 버튼 비활성화

      // 전자 청약 전송
      lv_PrcsEtapSend: false,
      lv_PrcsEtapSendMsg: '',

      // E-mail 전송 메시지 출력
      lv_PopEmailConfirmMsg: '',
      lv_PopEmailConfirmFlag: false,

      // 청약서 내용물 출력 여부 flag
      lv_ShowAfter: false,
      lv_ShowMiddle: false,

      // 미리보기에서 NAS에 file 있는 지 다시 체크하기 위한 Flag, msg
      lv_ShowRePreview: false,
      lv_ShowRePreviewMsg: '',

      // 지급정보 알림톡 관련 버튼 활성여부, Flag, msg
      lv_PymInfoIapBtnDisable: false,
      lv_ShowPymInfoIapFlag: false,
      lv_ShowPymInfoIapMsg: '',

      // Button Show flag
      lv_ShowPrintBtn: false, // 인쇄버튼 보이기
      lv_ShowEltrnOfrBtn: false, // 전자 청약 버튼 보이기
      lv_ShowEltrnSignBtn: false, // 전자 서명 버튼 보이기
      lv_chkBnf21: true, // 전자 서명 버튼 보이기(미성년 주피자인 경우 수익자 조건)
      lv_ShowMobpsSignBtn: true, // #2020모바일청약, 모바일청약 버튼 보이기
      lv_mnisdNm: '', // lv_chkBnf21 수익자 체크를 위한 주피보험자명
      lv_mnisdRrn: '', // lv_chkBnf21 수익자 체크를 위한 주피보험자 주민번호
      lv_eltrnSignBtnDisableModelList: [], // 전자서명 불가 알림 출력 및 블로킹 기기모델명 목록
      lv_rsltMNo: '', // 기기 모델명

      // constants
      LOCAL_PATH_PDF_DOWNLOAD: 'sli/tss/pdf', // '/sli/temp/newpdf/', // 로칼 다운로드 파일 경로

      // ================== 전역변수 ==================
      lv_JointRecuit: false, // 공동모집여부

      // 100M 의 데이터 (Tmodel, Pmodel, abdapolicy)
      tmodel: {},
      pmodel: {},
      abdapolicy: [],
      // USER INFO
      headerVO: {},
      lv_Celno: '', // 핸드폰번호

      // PDF 관련 변수
      lv_PreView_PDF_FileNm: '', // 미리보기 시 로컬에 다운받은 파일명
      lv_EncrKey: '', // 암호키
      lv_TimestampId: '',

      // 이노이즈 검수를 위한 변수
      EntplEltrnSign_opName: '', // 이런거 시른데 만든 변수 1
      EntplEltrnSign_entplSignYN: '', // 이런거 시른데 만든 변수 1

      // -- 추가하는 변수들
      sandType: '가설', // 가설 ||청약
      pdfPath: '',
      pdtmannualproposal: false, // 문서 미리보기 할때 암호화 여부 (청약서일 때만 True)

      MB_vCustYn: false, // 가상고객여부 체크
      MB_prcd: '',
      MB_prdtVcd: '',
      MB_prmnlCntnt: '', // 상품설명전문내용
      MB_entplCntnt: '', // 가입설계전문내용
      MB_strPlanlId: '', // 설계ID
      MB_strCnvrPlanId: '', // 전환설계ID
      MB_strGenUsHptsFrmCd: '', // TODO 일반용도가설양식코드
      MB_strPlanScCd: '', // 설계구분코드
      MB_contrChnlCustId: '', // 계약자 채널고객ID
      MB_clctCnsltRrn: '', // 컨설턴트주민번호
      MB_strSalesVprYN: '', // 변액미자격여부
      MB_numEntplPblCnt: 0, // 아이템 카운트( 개수 )
      MB_NoDSCLSFlg: false, // 고지사항 미출력여부

      zzaftRctmFg: '1', // 출금동의서 존재여부(후입금 선택여부) - 0 미선택, 1 선택
      isMoblPftSale: false, // 모바일 완전판매 대상여부
      STR_currentAction: '', // AEUS 분기용 : 현재 기능처리 구분
      MODCEmailTransTotVO: {}, // MODCEmailTransTotVO; //이메일 전송용 VO
      rewriteMode: false,
      resendMode: false,
      saveCachePath: '',
      checkIntervalType: '',
      pollingCnt: 0,
      lmsObject: {}, // LMS parameter를 담는 Object
      paramVO: {}, // SE4AuthVO = new SE4AuthVO();

      emailAddr: '',
      lmsYn: '0', // LMS 전자서명 발송여부 - 0 미선택, 1 동의, 2 미동의
      MB_GAPrintYn: 'N', // GA설계사 인쇄
      MB_spcaContCnvrPrdtTypCd: '', // 스페셜계약전환상품유형코드
      // InsrdCd21_Cnt: 0, // !쓰는 곳 없음!
      // InsrdCd23_Cnt: 0, // !쓰는 곳 없음!

      /**
       * 수령방법에 따른 교부방법 결과 코드값
       * 2018.05.23 수정
       * 관련 : openPopupLMS, popupLMSCloseHandler
       * 01 : email, 02 : 서면 ,03 : 모바일+Email , 04 : 모바일
       * getConditionStr() 에서 전자서명 뷰어쪽으로 전달 되어 전자서명 완료후
       * pdfSignCompleteHandler fstIsuMthScCd.fstIsuMthScCd 통해 전달 셋팅 된다
       * 전역변수 fstIsuMthScCd는 Json 형태의 스트링 문자열 값임 낚이지 말것..
       */
      lv_ShowCheck: false,
      rsltMsgArray: [], // 인쇄 후 순차적 메세지 처리용 배열

      lmsFstIsuMthScCd: '',
      nsFormFileList: [], // PDF 구성 폼파일 리스트
      isNsEusPrcs: false, // 신규 전자청약 Viewer 진행 여부Flag
      tbtPblFileName: '', // 임시저장건 파일명(영수증번호)
      objmsgAuth: {},
      // resultHptsPblHstrListVO: {}, // MOSEHptsPblHstrListVO; //폴링용 전역 변수
      resultHptsPblHstrListVO:
      {
        entplId: '', //  가입설계ID
        hptsCvplId: '', //  가설전환플랜ID
        planScCd: '', //  설계구분코드
        trnsRsltCd: '', //  전송결과코드
        trnsRsltCntnt: '', //  전송결과내용
        mldrPrevCnfCd: '', //  자금세탁방지확인코드
        mldrPrevCnfRsltCntnt: '', //  자금세탁방지확인결과내용
        prtYn: '', //  출력여부
        apndFileCntnt: '', //  첨부파일내용
        chnlDrtOfrfmYn: '', //  채널다이렉트청약서여부
        nwTmchOfrYn: '', //  신TM청약여부
        eltrnOfrYn: '', //  전자청약여부
        pblYn: '', //  발행여부
        vainsUnQulfYn: '', //  변액미자격여부
        objYn: '', //  대상여부
        rskPrpnsExcdYn: '', //  위험성향초과여부
        dlExprShtHighRskYn: '', //  거래경험부족고위험여부
        vrtCustYn: '', //  가상고객여부
        prspCustYn: '', //  가망고객여부
        pblObjYn: '', //  발행대상여부
        eltrnSignYn: '', //  전자서명여부
        planId: '', //  설계ID
        tbtPblKndNm: '', //  태블릿발행종류명
        tbtPblNo: '', //  태블릿발행번호
        atPrntYn: '', //  자동인쇄여부
        hptsLineCd: '', //  가설종목코드
        PIOfrInfoVO: {}, // MOSEOfrInfoVO = new MOSEOfrInfoVO;    //  청약정보VO
        PIHptsPblHstrVO: {}, // ArrayCollection = new ArrayCollection();    //  발행이력관리VO
        PIFrgnPrdtExplnLangVO: {} // ArrayCollection = new ArrayCollection();    //  외국인상품설명언어VO
      },
      pvmnotice: {}, // 고지사항,
      zPblPpslChkListDtoList: [], // 선택발행 리스트
      currTab: '1', // 선택된 체크박스 구분 1:상품설명서(제안용), 2:청약서
      moblOfrObjYn: 'N', // #2020모바일청약, 모바일청약대상 상품여부
      isMobpsSrvcPsb: false, // #2020모바일청약, 모바일청약가능여부(모바일영업지원 업무Config)
      isAsgnProxyClamPsb: false, // #지정대리청구인 지정가능여부
      isTSSCT601P: false, // 고객별화상상담자료 목록 팝업
      lv_NofacCnslCust: {}, // 비대면화상상담 고객
      isShowBtn: false, // 화상상담 버튼 보이기(상설일때 안보임)
      isNofacCnslAuth: true, // 화상상담 권한

      MB_pymInfoObjYn: false, // 모바일지급조회가능여부
      MB_zMobspSendPymDto: {}, // 모바일지급조회 발송데이터

      pblAMLFg: true, // 모바일청약 버튼 컨트롤(계약자가 법인이자 가상화폐 취급업체인경우 버튼 미출력)
      chk990YN: 'N', // 서비스안내장990출력여부
      chkPdtmannualproposal: 'N', // 서비스안내장미리보기시 암호화여부
      // =============================================================
      temp1: '<div slot="action" class="icon-wrapper mr14"><mo-button shape="border" class="ns-btn-round btn-title-style-1 white" @click="fn_Move">화상상담</mo-button></div>',
      temp2: '<div slot="action" class="icon-wrapper mr14"><mo-button shape="border" class="ns-btn-round btn-title-style-1 white" disabled>화상상담</mo-button></div>',

      isLoadingMsg: false,
      loadingMsg:'청약서 발행 준비중입니다.',
      loadingSubMsg:'잠시만 기다려주세요.',

      // [선심사 및 영업시스템 고도화PJT] 추가
      lv_zNundwAplInfoDto: {}, // 선심사신청정보
      lv_NundwConfirmMsg: '', // 선심사 중단여부 메시지
      lv_NundwMndtYn: '', // 선심사 필수구분
      lv_ZaDsclsJdgPrgStatCd: '', // 선심사 진행구분코드
      lv_ZaDsclsExpJdgPrgStatCd: '', // 선심사 진행예상구분코드-미결(50)과 발행불가 상태 체크할때 사용함
      lv_NundwYn: '', // 간편심사/AEUS심사 구분용
      
      // 유의대체계약 사전결재 추가 시작
      lv_AtntRplcContTxt: '',
      lv_ResultAtntRplcContTypCdList: '',
      lv_AtntRplcContTypeCdList: '',
      lv_PreSancReqYn: '', // 사전결재 여부 : Y: 예, N: 아니오
      lv_PreSancObjNum: '0', // 사전결재 상태값 0: 사전결재 없음, 1: 사전결재, 2: 사전결재진행중, 3: 결재완료
      lv_PreSancNextFn: '', // 다음 진행
      lv_AtntRplcContTypeCds: [
                      {key: '80', label: '본인계약'},
                      {key: '81', label: '단기반복해지계약'},
                      {key: '82', label: '분산모집계약'},
                      {key: '83', label: 'Just달성계약'},
                      {key: '84', label: '대체계약'}
                    ],
      lv_PreSancNextFn : '',
      // 유의대체계약 사전결재 추가 종료
      lv_showHideTooltip: true  // 2025.01 툴팁추가
      //2025.01.14 모청유도 팝업에서 모바일청약 클릭 삭제요청 (ASR250100111 1안 ->2안으로 변경  최주연프로요청) 
      //lv_MoblOfrP6: 'N'   // 2025.01 전자서명버튼 -> 전자서명하시겠습니까> -> 모바일청약으로 우회한경우 Y로 업데이트함 
    }
  },
  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeRouteEnter(to, from, next) { 
    // 스마트폰, 태블릿 환경인지 실행
    // piCommonUtil.fn_SetMobileEnv((rslt)=>{
    //   console.log(' >>> piCommonUtil.fn_SetMobileEnv 호출결과 piCommonUtil.fn_IsMobileEnv() = ' + piCommonUtil.fn_IsMobileEnv())
    //   next()
    // })
  },
  beforeCreate () {
    // 스마트폰, 태블릿 환경인지 실행
    // piCommonUtil.fn_SetMobileEnv((rslt)=>{
    //   console.log(' >>> piCommonUtil.fn_SetMobileEnv 호출결과 piCommonUtil.fn_IsMobileEnv() = ' + piCommonUtil.fn_IsMobileEnv())
    // })
  },
  async created () {
    let lv_vm = this
    // 상품설명서에서 보여질 화상상담 버튼
    this.actionSlot = {
      actionComponent: {
        template: lv_vm.temp1,
        // template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1 white" @click="fn_Move">화상상담</mo-button></div>',
        methods: {
          fn_Move () {
            lv_vm.fn_BtnNsEusPrcsClickHandler('nofacCnsl')
          },
        }
      }
    }
    // 상품설명서에서 보여질 화상상담 버튼 비활성화
    this.actionSlotDisabled = {
      actionComponent: {
        template: lv_vm.temp2,
      }
    }
    
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.lv_isMobileEnv = await piCommonUtil.fn_SetMobileEnv()
    // this.lv_isMobileEnv = piCommonUtil.fn_IsMobileEnv()
    // window.vue.getStore('toast').dispatch('ADD', `MobileEnv : ${this.lv_isMobileEnv}`)

    window.vue.getStore('progress').dispatch('SHOW')
    this.selectedItem = false
    this.MB_prdtVcd = this.productVersionNo = 1
    this.headerVO = Object.assign({}, this.getStore('userInfo').getters.getUserInfo)
    // GA 매니저 대행 컨설턴트 변경시
    if(this.$cmUtil.fn_GachangeTF()){
      this.headerVO.userEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      this.headerVO.userNm = this.getStore('cmStore').getters.getState.gssEplyInfo.gssEplyNm
      this.headerVO.trtrEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      this.gachangeTF = true
    } else {
      this.gachangeTF = false
    }
    //ga매니저 권한
    this.vGssAuthYN = this.getStore('cmStore').getters.getState.gssAuthYN

    this.lv_Celno = ''

    // 전자서명 불가 알림 출력 및 블로킹 기기모델명 목록 조회 및 본 기기 모델명 조회
    await this.fn_getEltrnSignBtnDisableModel()

    this.fn_PrepareInit()
  },
  mounted () {
    // 필수 적용 함수
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy () {
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    window.vue.getStoreMap().delete(this.name) // Gaf stroe 제거
    // [선심사 및 영업시스템 고도화PJT] init Store 수정
    if (this.pParams.hasOwnProperty('pageId') && this.pParams.pageId === 'MSPPI820P') {
      Gaf.initStore('MSPPI820P')
      GafMainFunc.setStore('MSPPI820P')
    } else {
      Gaf.initStore('MSPPI001M')
      GafMainFunc.setStore('MSPPI001M')
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    lv_isBottomBtn () {
      if (this.currTab === '1' && this.lv_ItemLists_1.length > 0) {
      // if (this.currTab === '1' && this.lv_ChkCnt1 > 0) {
        return true
      } else if (this.currTab === '2' && this.lv_ItemLists.length > 0) {
      // } else if (this.currTab === '2' && this.lv_ChkCnt2 > 0) {
        return true
      }
      return false
    },
    lv_ChkCnt1 () { // 상품설명서 체크 갯수
      if (this.lv_ItemLists_1.length > 0) {
        let chkCnt = this.lv_ItemLists_1.filter(item => {
          if (item.hasOwnProperty('checked') && item.checked) {
            return true
          } else return false
        })
        if (this.lv_ItemLists_1.length === chkCnt.length) this.lv_AllCheck1 = true
        else this.lv_AllCheck1 = false
        return !_.isEmpty(chkCnt) ? chkCnt.length : 0
      } else return 0
    },
    lv_ChkCnt2 () { // 청약서 체크 갯수
      if (this.lv_ItemLists.length > 0) {
        let chkCnt = this.lv_ItemLists.filter(item => {
          if (item.hasOwnProperty('checked') && item.checked) {
            return true
          } else return false
        })
        if (this.lv_ItemLists.length === chkCnt.length) this.lv_AllCheck2 = true
        else this.lv_AllCheck2 = false
        return !_.isEmpty(chkCnt) ? chkCnt.length : 0
      } else return 0
    },
    lv_BtnCnt () { // 버튼 갯수
      let totCnt = 0
      if (this.currTab === '2') {
        // 전자서명 버튼 visible 조건
        if (this.lv_isMobileEnv === 'T' && this.lv_ShowAfter && this.lv_ShowEltrnSignBtn && this.lv_chkBnf21) {
          totCnt++
        }
        // 모바일청약 버튼 visible 조건
        if (this.lv_ShowAfter && this.moblOfrObjYn === 'Y' && this.isMobpsSrvcPsb === true && this.lv_ShowMobpsSignBtn === true && this.pblAMLFg === true) {
          totCnt++
        }
        // 고객지급정보 버튼 visible 조건
        if (!this.MB_vCustYn && this.MB_pymInfoObjYn) {
          totCnt++
        }
      }
      return totCnt
    },
    lv_NofacCnslAuthBtn () { // 화상상담 버튼
      if (this.lv_isMobileEnv === 'T' && this.isNofacCnslAuth && this.isShowBtn) {
        return true
      } else {
        if (this.lv_Env === 'local') return true
      }
      return false
    },
    lv_isEltrnBtn () { // 전자서명 버튼
      if (this.lv_isMobileEnv === 'T' && this.lv_ShowAfter && this.lv_ShowEltrnSignBtn && this.lv_chkBnf21) {
        return true
      } else {
        // PC버전에서 전자서명 보이도록 조건 추가
        if (this.lv_isMobileEnv === 'O' && this.lv_ShowAfter && this.lv_ShowEltrnSignBtn && this.lv_chkBnf21 && (this.lv_Env === 'stage' || this.lv_Env === 'development' || this.lv_Env === 'stage2')) {
          return true
        } else if (this.lv_Env === 'development') { // 개발계에서는 태블릿/핸드폰 상관없이 보이도록 처리
          return true
        } else if (this.lv_Env === 'local') { // 2025.01 로컬에서도 전자서명 버튼 무조건 보이도록 변경함 
           return true
        } 
        return false
      }
    },
    lv_isMobileBtn () { // 모바일 청약 버튼
      // 모바일청약 버튼 visible 조건
      if (this.lv_ShowAfter && this.moblOfrObjYn === 'Y' && this.isMobpsSrvcPsb === true && this.lv_ShowMobpsSignBtn === true && this.pblAMLFg === true) {
        return true
      } else {
        return false
      }
    },
    lv_isPymInfoObjYnBtn () { // 고객지급안내 버튼
      // 고객지급정보 버튼 visible 조건
      if (!this.MB_vCustYn && this.MB_pymInfoObjYn) {
        return true
      } else {
        return false
      }
    },
    lv_CntOpenBs () { // 현재 열린 바텀시트 갯수 체크
      let totCnt = 0
      for (let chkBs in this.$refs) {
        if (chkBs.includes('ref') && this.$refs[chkBs].status === 'open') {
          totCnt++
        }
      }
      console.log('lv_CntOpenBs ===> ', totCnt)
      return totCnt
    },
    lv_Env () {
      return process.env.NODE_ENV
    },
    getProgress() {
      return this.getStore('progress').getters.getState
    },
  },
  watch: {
    lv_ItemLists_1: { // 상품설계서 목록
      deep: true,
      handler (item) {
        if (this.lv_ChkCnt1 === 1) {
          // 선택발행 리스트 클릭하면 필수출력 목록 자동 체크 처리
          this.lv_ItemLists_1.forEach(inItem => {
            if (inItem.zaPblCntnt && inItem.zaPblCntnt.includes('필수출력')) {
              if (!inItem.checked) inItem.checked = true
            }
          })
        }
        // 선택발행 목록中 "연금전환 제도 안내" 는 "연금전환 금액 예시" 에 선행
        if (this.lv_ChkCnt1 > 0) {
          let tmp = this.lv_ItemLists_1.filter((item) => { return item.checked && item.zaPblCntnt === '연금전환 금액 예시' })
          if (tmp && tmp.length > 0) {
            this.lv_ItemLists_1.forEach(inItem => {
              if (inItem.zaPblCntnt && inItem.zaPblCntnt.includes('연금전환 제도 안내')) {
                if (!inItem.checked) inItem.checked = true
              }
            })
          }
        }

        // 선택발행 리스트 클릭하면 상설제안용탭 체크
        if (this.lv_ChkCnt1 > 0 && !this.lv_Chk1.includes('1')) {
          this.lv_Chk1.push('1')
          this.fn_InputTab1()
        }

        // 선택발행 리스트에 서비스안내장만있을때 체크해제 하여도 상설제안용탭은 유지한다.
        if (this.lv_ItemLists_1.length === 1 && this.lv_ItemLists_1[0].zaSno === '990') {
          this.chk990YN = 'Y'
        } else {
          this.chk990YN = 'N'
        }

        // 선택발행 리스트 아무것도 클릭 안했으면 상설제안용탭 체크 해제
        if (this.lv_ItemLists_1.length > 0 && this.lv_ChkCnt1 === 0 && this.chk990YN === 'N') {
          let idx = this.lv_Chk1.indexOf('1')
          if (idx > -1) {
            this.lv_Chk1.splice(idx, 1)
            this.fn_InputTab1()
          }
        }
      }
    },
    lv_ItemLists: { // 청약서 목록
      deep: true,
      handler (item) {
        // checked 된 리스트
        let chkList = this.lv_ItemLists.filter(chkItem => chkItem.checked)
        
        // 청약서 클릭하면 청약서에 포함된 항목 전부 체크 처리
        if (this.lv_ChkCnt2 === 1 && chkList[0].title01 === '청약서') {
          this.lv_ItemLists.forEach(inItem => {
            if (inItem.title01 === '청약서') inItem.checked = true
          })
          return
        }

        // 체크된 리스트에 청약서가 있는 지 확인
        let hasAppForm = false
        for (let i = 0; i < chkList.length; i++) {
          if (chkList[i].zaPblCntnt === '청약서') {
            hasAppForm = true
            break
          }
        }

        // 청약서가 체크되지 않고 체크된 항목들이 있다면 청약서 체크
        if (this.lv_ChkCnt2 > 0 && !hasAppForm) {
          if (this.lv_ChkCnt2 === 1) {
            this.lv_ItemLists.forEach(inItem => {
              if (inItem.title01 === '청약서') inItem.checked = true
            })
          } else {
            this.lv_ItemLists.forEach(inItem => inItem.checked = false)
          }
        }

        // 청약서 서류 리스트 클릭하면 청약서탭 체크
        if (this.lv_ChkCnt2 > 0 && !this.lv_Chk1.includes('2')) {
          this.lv_Chk1.push('2')
          this.fn_InputTab2()
        }

        // 청약서 서류 리스트 아무것도 클릭 안했으면 청약서탭 체크 해제
        if (this.lv_ItemLists.length > 0 && this.lv_ChkCnt2 === 0) {
          let idx = this.lv_Chk1.indexOf('2')
          if (idx > -1) {
            this.lv_Chk1.splice(idx, 1)
            this.fn_InputTab2()
          }
        }
        
        // 체크된 항목에 '상품설명서-외국인'이 있으면 언어선택팝업 (TSSPI392P) 호출
        for (let i = 0; i < chkList.length; i++) {
          if (chkList[i].zaPblCntnt === '상품설명서-외국인') {
            // this.lv_isSelectLanguage = true // contents 영역 노출을 위해
            this.$refs.refSelectLanguage.open()
          }
        }
      }
    },
    lv_Chk1 () {
      // 인쇄 활성화 처리: ASR190800438_사랑On_실손상품 인쇄버튼 블로킹
      // 조건상품 : LP0275***, LP0397***, LP0554***,  LP0587***
      // 삼성생명 실손의료비 보장보험 3.0 (LP0275011)
      // 삼성생명 노후실손의료비보장보험 6.0 (LP0397009)
      // 삼성생명 간편가입 실손의료비보장보험2.0 (LP0554003)
      // 삼성생명 실손의료비 보장보험[태아용] (LP0587001)- 출시예정
      // ※ 미성년자 주피가입時, 계약자 ≠ 친권자 설계 件의 경우 전자서명 불가케이스이나
      // 시스템적으로 이를 구분할 수 없음
      // 따라서 해당케이스의 경우에 한하여 인쇄 /전자서명 버튼 모두 활성화
      this.lv_ShowPrintBtn = true
      // ASR210700703_전자서명 발행時 실손상품 인쇄불가 로직 해제요청
      // 실손상품일경우 (LP0275***, LP0397***, LP0554***,  LP0587***) 인쇄 불가 로직 해제(인쇄가능)
      // if (this.lv_Chk1.includes('2')) {
      //   let zzprcdId = this.abdapolicy[0].abdapolpr[0].zzprcdId
      //   if (zzprcdId.indexOf('LP0275') > -1 || zzprcdId.indexOf('LP0397') > -1 || zzprcdId.indexOf('LP0554') > -1 || zzprcdId.indexOf('LP0587') > -1) {
      //     if (this.lv_ShowAfter && this.lv_ShowEltrnSignBtn && !this.fn_AgeCheckInsrd21()) {
      //       this.lv_ShowPrintBtn = false
      //     }
      //   }
      // }
      if (this.currTab === '1') {
      // if (this.lv_Chk1.includes('1') && !this.lv_Chk1.includes('2')) {
        this.isShowBtn = true
      } else { // 청약서는 화상상담 진행 불가
        this.isShowBtn = false
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    // doubleRaf (callback) {
    //   requestAnimationFrame(() => {
    //     requestAnimationFrame(callback)
    //   })
    // },
    /******************************************************************************
    * Function명 : fn_HistoryBack
    * 설명        : 
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_HistoryBack (type) {
      // 1. 언어선택 : refSelectLanguage
      // 3. 고객 지급정보 : refPymInfoIapFlag
      // 4. 이메일 발송 전 확인 : refPopEmailConfirmFlag
      // 5. 미리보기 : refPrcsEntplEltrnSignAlertFlag
      // 6. 인쇄 : refProcPrintFlag
      // 7. 미리보기 재시도 : refShowRePreview
      // 8. 인쇄 재시도 : refShowRetryPrintFlag
      // 9. 전자청약 : refPrcsEtapSend
      // 10. 기본 : refShowCheck
      // 11. 무고지 관련 Confirm : refShowNoDsclsConfirm
      // 12. 일반 에러 메시지 : refAlertMsg
      // 13. 사전결재 : refPrcsPreSancAlertFlag
      if (_.isEmpty(type)) {
        // 안드로이드 백버튼
        console.log('AOS back button ========================')
        let chkFlag = this.fn_CheckOpenBottomSheet()
        if (chkFlag) {
          this.fn_CloseBottomSheet(null)
        } else {
          if (!this.isLoading && !this.getProgress.isShow) this.$emit('cancelPopup')
        }
      } else {
        if (!this.isLoading && !this.getProgress.isShow) this.$emit('cancelPopup')
      }
    },
    /************************************************************************************************
     * Function명  : fn_CheckOpenBottomSheet
     * 설명        : 바텀 시트 열림 여부
     ************************************************************************************************/
    fn_CheckOpenBottomSheet () {
      for (let chkBs in this.$refs) {
        if (chkBs.includes('ref') && this.$refs[chkBs].status === 'open') {
          return true
        }
      }
      return false
    },
    /************************************************************************************************
     * Function명  : fn_CloseBottomSheet
     * 설명        : 바텀 시트 종료
     ************************************************************************************************/
    fn_CloseBottomSheet(name) {
      if (!_.isEmpty(name) && !_.isEmpty(this.$refs[name])) {
        if (name === 'refPopEmailConfirmFlag') {
          // this.lv_isPopEmailConfirmFlag = false
          // NEXT 사랑On : 버튼 disabled 해제
          this.lv_EltrnSignBtnDisable = false
        } else if (name === 'refSelectLanguage') {
          if (this.lv_LangScCdArray.length === 0) {
            // this.lv_isSelectLanguage = false
            this.$emit('cancelPopup')
          }
        }
        this.$refs[name].close()
      } else {
        for (let chkRef in this.$refs) {
          if (chkRef.includes('ref') && this.$refs[chkRef].status === 'open') {
            if (chkRef === 'refSelectLanguage') {
              this.$refs[chkRef].close()
              // this.lv_isSelectLanguage = false
              this.$emit('cancelPopup')
            } else {
              if (chkRef === 'refPymInfoIapFlag') { // 지급정보 관련 알림톡
                // this.lv_isPymInfoIapFlag = false
              }
              if (chkRef === 'refPopEmailConfirmFlag') { // 이메일 발송전 확인
                // this.lv_isPopEmailConfirmFlag = false
                // NEXT 사랑On : 버튼 disabled 해제
                this.lv_EltrnSignBtnDisable = false
              }
              if (chkRef === 'refShowRePreview') { // 미리보기 재시도
                // NEXT 사랑On : 버튼 disabled 해제
                this.lv_EltrnSignBtnDisable = false
              }
              this.$refs[chkRef].close()
            }
            break
          }
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_SetClass
     * 설명        : 발행물 종류에 따른 클래스 리턴
     ************************************************************************************************/
    fn_SetClass (item) {
      switch (item) {
        case '청약서':
          return 'lightblue5'
          break
        case '안내장':
          return 'lightyellow'
          break
        case '별첨':
          return 'lightgray2'
          break
        default:
          if (item.includes('상품설명서')) return 'lightgreen1'
          else return ''
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ShowErrorMsg
     * 설명        : 알럿 바텀 메시지 노출
     ************************************************************************************************/
    fn_ShowErrorMsg () {
      window.vue.getStore('progress').dispatch('HIDE') // 로딩바 지우기
      if (this.lv_AlertMsg.length > 0) {
        this.$refs.refAlertMsg.open()
      }
    },
    /************************************************************************************************
     * Function명  : fn_PrepareInit
     * 설명        : 화면 시작하기 전에 100M 의 Gaf 값을 저장하고 기본적 정보 세팅
     ************************************************************************************************/
    fn_PrepareInit () {
      this.tmodel = this.pParams.tmodel
      this.pmodel = this.pParams.pmodel
      this.abdapolicy = this.pParams.abdapolicy

      this.MB_prcd = this.zzprcdId = this.pmodel.abdapolicy[0].abdapolpr[0].zzprcdId // : "LP0479005"  // 'LP0275009'
      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]
      this.MB_vCustYn = ( this.tmodel.rltnrInfo[0].zaVcustYn === 'Y' ) // 가상고객여부
      this.paramVO.rrn = _rltnrInfo_11.zzcustRrnId
      this.paramVO.custNm = _rltnrInfo_11.zaInsrdNm

      this.lv_EltrnSignBtnDisable = false // 버튼 비활성화 False

      Gaf.initStore(this.name)
      GafMainFunc.setStore(this.name)
      Gaf.setProcessNo('1001') // SET_PROCESS_NO = '1001':L
      Gaf.addPanelList(this)
      Gaf.setObj({pmodel: this.pmodel, tmodel: this.tmodel})

      this.fn_Init()
      // 발행을 위한 데이터 하나를 요청하는 것이지요

      // ASR190700084 수익자 지정 미성년자 전자서명 블로킹 로직 추가
      // if (!this.MB_vCustYn && this.fn_AgeCheckInsrd21()) {
      //   this.fn_chkBnf21()
      // } else {
      //   this.fn_RequestSearch()
      // }

      // #2020모바일청약, 모바일청약은 수익자 검증이 필요하여 항상 호출
      // ASR190700084 수익자 지정 미성년자 전자서명 블로킹 로직 추가 => 이동 URLPQL00155_LocalCallBack
      // if (!this.MB_vCustYn && this.fn_AgeCheckInsrd21()) {
      if (!this.MB_vCustYn) {
        this.fn_chkBnf21()
      } else {
        this.fn_RequestSearch()
      }

      // 고객정보조회 ===> fn_RequestSearch 후로 바뀜 19.04.09
      // if ( this.MB_vCustYn === false && !piCommonUtil.isEmpty2(_rltnrInfo_11.zaAgtFileId)) {
      //   this.fn_GetCustInfo(_rltnrInfo_11)
      // }
    },
    /************************************************************************************************
     * Function명  : fn_Init
     * 설명        : 고객 정보/내부 변수 설정
     ************************************************************************************************/
    fn_Init () {
      let tmodel = this.tmodel
      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]

      this.MB_vCustYn = tmodel.rltnrInfo[0].zaVcustYn === 'Y' // 가상고객여부
      this.MB_strPlanlId = this.pParams.zaPlanId // 설계ID
      this.MB_strGenUsHptsFrmCd = '' // 일반용도가설양식코드
      this.MB_strPlanScCd = '01' // 설계구분코드
      this.MB_contrChnlCustId = _rltnrInfo_11.zaAgtFileId // 계약자 채널고객ID
      this.lmsChlCustId = this.MB_contrChnlCustId
    },
    /************************************************************************************************
     * Function명  : fn_chkBnf21
     * 설명        : 전자서명 블로킹 기준: 피보험자 미성년자 계약의 경우 수익자가 피보험자/ 상속인 아닌 값 입력시
     ************************************************************************************************/
    fn_chkBnf21 () {
      // ASR190700084_수익자 지정 미성년자 전자서명 블로킹_2019.07.16
      let inputData = {
        tmodel: {
          searchCriteria: {
            zaPlanId: Gaf.getObj('pmodel').abdapolicy[0].zaPlanId
          }
        }
      }
      window.vue.getStore('progress').dispatch('NONE')
      this.fn_isLoadingMsg(true)
      Gaf.execute('MSPPI250P', 'URLPQL00155', inputData, false, null, this)
    },
    /************************************************************************************************
     * Function명  : URLPQL00155_LocalCallBack
     * 설명        : URLPQL00155 콜백 함수
     * bnfrRolCd  : 31: 만기수익자(기본값:계약자), 32: 분할수익자(기본값:계약자), 33: 연금수익자(기본값:계약자),
     *              34: 상해수익자(기본값:피보험자), 35: 사망수익자(기본값:상속자), 39: 연금후수익자(기본값:상속자)
     ************************************************************************************************/
    URLPQL00155_LocalCallBack (event, serviceName) {
      // window.vue.getStore('progress').dispatch('SHOW') // progress 없어지지 않기 위한 코드
      window.vue.getStore('progress').dispatch('FULL')
      this.fn_isLoadingMsg(false)
      
      let lv_bnfrInfoList = null
      if ( !_.isEmpty(event) && !_.isEmpty(event.tmodel)) {
      // if (event !== null && event !== undefined && event.tmodel !== null && event.tmodel !== undefined) {
        lv_bnfrInfoList = event.tmodel.bnfrInfo

        // #2020모바일청약, 수익자 목록 조회
        this.tmodel.bnfrInfo = event.tmodel.bnfrInfo
      }
      // ASR190700084 수익자 지정 미성년자 전자서명 블로킹 로직 추가 => 이동 URLPQL00155_LocalCallBack
      if (!this.MB_vCustYn && this.fn_AgeCheckInsrd21()) {
        if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0) {
          let itemIdx = 0
          let itemsCnt = lv_bnfrInfoList.length
          for ( itemIdx; itemIdx < itemsCnt; itemIdx++ ) {
            let lv_bnfrInfo = lv_bnfrInfoList[itemIdx]
            if (lv_bnfrInfo.zzbnfrRolCd !== null || lv_bnfrInfo.zzbnfrRolCd !== undefined) {
              // 피보험자가 미성년자(만19세미만)인 경우
              // 1. 상해시 수익자가 피보험자가 아니거나
              // 2. 사망시 수익자가 상속인이 아닐경우
              // 1~2 조건 中 1개이상 해당시 전자서명 블로킹
              if ((lv_bnfrInfo.zzbnfrRolCd === '34' && (lv_bnfrInfo.custNm !== this.lv_mnisdNm || lv_bnfrInfo.knb.substr(0, 6) !== this.lv_mnisdRrn.substr(0, 6))) || (lv_bnfrInfo.zzbnfrRolCd === '35' && lv_bnfrInfo.custNm !== '상속인')) {
                this.lv_chkBnf21 = false
                break
              }
            }
          }
        } // if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0) {
      } // if (!this.MB_vCustYn && this.fn_AgeCheckInsrd21()) {

      this.fn_RequestSearch()

      // #2020모바일청약, 모바일청약 버튼 표시 로직
      this.fn_chkMobpsSignBtn()

      // 20201116, 지정대리청구인 지정가능여부 확인
      this.fn_AsgnProxyClamPsb()
    },
    /************************************************************************************************
     * Function명  : fn_RequestSearch
     * 설명        : 발행건 요청하는 메서드
     ************************************************************************************************/
    fn_RequestSearch () {
      let abdapolicy = this.abdapolicy[0]
      let abdapolpr = abdapolicy.abdapolpr[0]

      let _rltnrInfo = this.tmodel.rltnrInfo
      if (!piCommonUtil.isObj(_rltnrInfo)) {
        return
      }
      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]
      // 임시고객여부 설정(채널고객구분코드 '02'의 경우 임시고객)
      if (piCommonUtil.isObj(_rltnrInfo_11.zzcustRrnId) && _rltnrInfo_11.zzcustRrnId.length === 13 && _rltnrInfo_11.zaChnlCustScCd === '02' ) { // _rltnrInfo_11.zzcustRrnId.substr(7, 6) === '000000' ) {
        this.MB_strTmpCustYn = 'Y' // 임시고객여부
      }

      // 임시고객여부 설정(전가입대상 체크)
      for (let r in _rltnrInfo) {
        if (piCommonUtil.isObj(_rltnrInfo[r].zzcustRrnId) && _rltnrInfo[r].zzcustRrnId.length === 13 && _rltnrInfo[r].zaChnlCustScCd === '02' ) { // _rltnrInfo[r].zzcustRrnId.substr(7, 6) === '000000' ) {
          // 태아예외처리
          if (_rltnrInfo[r].zaInsrdNm === '태아' && _rltnrInfo[r].zzcustRrnId.substr(0, 7) === '0000003') continue
          this.MB_strTmpCustYn = 'Y' // 임시고객여부
          break
        }
      }

      // 조회 파라미터 셋팅
      let inputJson = {}
      inputJson.bukrs = 'L001' // Company code  생명 L001, 화재 F100
      inputJson.spras = 'K' // 언어코드

      // 발행목록 조회 서비스
      inputJson.zaPlanId = this.MB_strPlanlId
      inputJson.zaPlanScCd = this.MB_strPlanScCd
      inputJson.zaUntySlmtBasInfoNo = this.MB_strGenUsHptsFrmCd
      inputJson.zaVainsUnQulfYn = this.MB_strSalesVprYN
      inputJson.zaIlstIrtTypCd = abdapolpr.aldapolpr.zzilsITCLVl
      inputJson.zaContrAgtFileId = _rltnrInfo_11.zaAgtFileId // 계약자 에이젼트ID
      inputJson.productBaseId = abdapolpr.productBaseId
      inputJson.zzrmvlPrcdCd = ''
      inputJson.zzprmtrPrdTCd = abdapolpr.aldapolpr.zzprmtrPrdLCd
      inputJson.zzPrcdId = abdapolpr.zzprcdId // 2023.02 서비스안내장추가

      // 보험거래문서디지털화: 선택발행 목록 조회를 위한 상품코드 파람 추가
      inputJson.zaZZSELPRNTPrcd = Gaf.getTObj('prodInfo').baseInfoDto.productname

      // 고객유형을 설정한다.
      if (this.MB_vCustYn) {
        inputJson.zaPrspCustYn = 'N' // 가상고객일 때 N
      } else if (this.MB_strTmpCustYn === 'Y') {
        inputJson.zaPrspCustYn = 'T' // 임시고객일 때 T
      } else {
        inputJson.zaPrspCustYn = 'Y' // 가망고객일 때 Y
      }

      // Gaf.execute('MSPPI250P', 'URLPQL00067', inputJson, true, null, this)
      // 보험거래문서디지털화 : URL 변경
      window.vue.getStore('progress').dispatch('NONE')
      this.fn_isLoadingMsg(true)
      Gaf.execute('MSPPI250P', 'UF1PQL0395', inputJson, true, null, this)
    },
    /************************************************************************************************
     * Function명  : UF1PQL0395_LocalCallBack
     * 설명        : UF1PQL0395_LocalCallBack
     ************************************************************************************************/
    UF1PQL0395_LocalCallBack (result) {
      // 고객정보조회 Service START ===================================================================
      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]
      if ( this.MB_vCustYn === false && !piCommonUtil.isEmpty2(_rltnrInfo_11.zaAgtFileId)) {
        this.fn_GetCustInfo(_rltnrInfo_11)
      }
      // 고객정보조회 Service END ===================================================================

      let zPIEntplPblItmListDto = result

      //* ***********************************************************************************************************************************************
      this.MB_strGenUsHptsFrmCd = zPIEntplPblItmListDto.zaUntySlmtBasInfoNo // 통합영업자료기본정보번호
      // console.log('this.MB_strGenUsHptsFrmCd', this.MB_strGenUsHptsFrmCd)
      this.MB_strCnsltImsiYn = zPIEntplPblItmListDto.zaTmpYn // 임시설계사여부
      this.MB_strSalesVprYN = zPIEntplPblItmListDto.zaVainsUnQulfYn // 변액미자격여부
      // console.log('변액미자격여부 >>>>>>>> ', this.MB_strSalesVprYN)
      this.MB_strHptsPblYn = zPIEntplPblItmListDto.zaHptsPblYn // (가설발행여부)
      this.MB_NoDSCLSFlg = false // 고지사항 미출력여부 초기화
      this.moblOfrObjYn = zPIEntplPblItmListDto.zaMoblOfrObjYn // #2020모바일청약, 모바일청약대상 상품여부 확인
      let zzprcdId = this.abdapolicy[0].abdapolpr[0].zzprcdId
      let pblEtrtFlg = false // 발행예외처리구분값(true의 경우, 청약서만 발행목록에 표시)

      // ===================================================================
      // [선심사 및 영업시스템 고도화PJT] 추가
      // 선심사 상태 체크하여 알럿 메시지 및 청약서 목록 안보이도록 처리
      // ===================================================================
      this.lv_NundwMndtYn = zPIEntplPblItmListDto.nundwMndtYn // 선심사 필수구분
      this.lv_NundwYn = zPIEntplPblItmListDto.nundwYn // 선심사 여부(간편심사/AEUS심사 구분용)
      this.lv_ZaDsclsJdgPrgStatCd = zPIEntplPblItmListDto.zaDsclsJdgPrgStatCd // 선심사 진행구분코드
      this.lv_ZaDsclsExpJdgPrgStatCd = zPIEntplPblItmListDto.zaDsclsExpJdgPrgStatCd // 선심사 진행예상구분코드
      // console.log('nundwMndtYn: ', zPIEntplPblItmListDto.nundwMndtYn, ' / lv_ZaDsclsExpJdgPrgStatCd: ', zPIEntplPblItmListDto.zaDsclsExpJdgPrgStatCd, ' / zaDsclsExpJdgPrgStatCd: ', zPIEntplPblItmListDto.zaDsclsExpJdgPrgStatCd)
      // ===================================================================

      // 상품코드 앞 6자리가 'LP0257'인 경우(희망사랑보험)
      if (zzprcdId.indexOf('LP0257') > -1) {
        pblEtrtFlg = true
      }

      // 전자서명 활성화 처리
      this.lv_ShowEltrnSignBtn = false
      if (zPIEntplPblItmListDto.zaEltrnSignYn !== undefined && zPIEntplPblItmListDto.zaEltrnSignYn === 'Y') {
        if (this.lv_chkBnf21) {
          this.lv_ShowEltrnSignBtn = true
        } else {
          // this.lv_ShowErrorPopup = true
          this.lv_AlertMsg = '미성년자 피보험자 계약의 수익자 지정시,</br>태블릿 전자서명은 불가합니다.</br>(모바일청약 / 지류청약 활용바랍니다)'
          this.fn_ShowErrorMsg()
          // this.lv_AlertMsg = '미성년자 피보험자 계약의 수익자 지정시,\n태블릿 전자서명은 불가합니다.\n(모바일청약 / 지류청약 활용바랍니다)'
        }
      }

      if (zPIEntplPblItmListDto.zaCnsltEltrnOfrYn === 'Y') { // 컨설턴드전자청약여부
        this.isAlertMessage001 = true // 컨설턴트 본인 계약으로 \n전자청약을 활용해 주시기 바랍니다.
      }
      this.MB_strApndFileNmList = '' // 첨부파일명List 초기화

      // document.getElementById('tmpDiv1').style.display = 'none' // 외국인 상설 언어선택 비표시 초기화

      // ============================================
      // 1)    상품설명서(제안용) 목록 구성
      // ============================================
      let zPIEntplPblItmInfoDto = zPIEntplPblItmListDto.zPIEntplPblItmInfoDto

      if (zPIEntplPblItmInfoDto !== null && !pblEtrtFlg) {
        zPIEntplPblItmInfoDto = {
          'title01': '상품설명서(제안용)',
          'title02': '전체',
          'checkField': 'Y',
          'checked': false // NEXT사랑On 추가
        }
        this.lv_Item_Lists.push(zPIEntplPblItmInfoDto)
        this.MB_numEntplPblCnt++

        // 보험거래문서디지털화 : 상품설명서(제안용) 선택발행 목록 구성
        if (zPIEntplPblItmListDto.zPblPpslChkListDtoList && zPIEntplPblItmListDto.zPblPpslChkListDtoList.length > 0) {
          // zaKey 순으로 정렬
          let _orderBy = require('lodash/orderBy')
          this.zPblPpslChkListDtoList = _orderBy(zPIEntplPblItmListDto.zPblPpslChkListDtoList, 'zaKey', 'asc')
          // this.ppslSellistCnt = this.zPblPpslChkListDtoList.length

          this.zPblPpslChkListDtoList.forEach((el, i) => {
            zPIEntplPblItmInfoDto = {
              title01: ' ',
              title02: '상품설명서(제안용)',
              zaPblCntnt: el.zaValue,
              zaHptsPblItmCntnt: '',
              zaPblNos: '1',
              zaApndFileNm: '',
              checkField: 'N',
              checked: false // NEXT사랑On 추가
            }
            this.lv_ItemLists_1.push(zPIEntplPblItmInfoDto)
            // this.MB_numEntplPblCnt++
          })
        }
      }

      // 스페셜계약전환상품유형코드가 (A1)공시이율형종신전환,(A2)공시이율형연금전환,(B1)연금저축계약승계 의 경우 수작업청약건으므로 상품설명서를 발행리스트에 강제설정.
      if (!this.MB_vCustYn && (this.MB_spcaContCnvrPrdtTypCd === 'A1' || this.MB_spcaContCnvrPrdtTypCd === 'A2' || this.MB_spcaContCnvrPrdtTypCd === 'B1')) {
        let zPIEntplPblItmInfoDto2 = {
          title01: '상품설명서',
          title02: '고객보관용',
          zaSno: 102,
          zaPblCntnt: '상품설명서-개인',
          zaHptsPblItmCntnt: '',
          zaPblNos: '1',
          zaApndFileNm: '',
          checkField: 'N',
          checked: false // NEXT사랑On 추가
        }

        this.lv_ItemLists.push(zPIEntplPblItmInfoDto2)
        this.MB_numEntplPblCnt++

        let zPIEntplPblItmInfoDto3 = {
          title01: '상품설명서',
          title02: '회사보관용',
          zaSno: 103,
          zaPblCntnt: '상품설명서-회사',
          zaHptsPblItmCntnt: '',
          zaPblNos: '1',
          zaApndFileNm: '',
          checkField: 'N',
          checked: false // NEXT사랑On 추가
        }
        this.lv_ItemLists.push(zPIEntplPblItmInfoDto3)
        this.MB_numEntplPblCnt++
      }

      this.MB_zPIOfrAddrInfoDtoList = zPIEntplPblItmListDto.zPIOfrAddrInfoDtoList

      // 주소정보에서 이메일주소를 설정한다.
      if (this.MB_zPIOfrAddrInfoDtoList !== null && this.MB_zPIOfrAddrInfoDtoList !== undefined && this.MB_zPIOfrAddrInfoDtoList.length > 0) {
        this.MB_EmailAddr = this.MB_zPIOfrAddrInfoDtoList[0].zaEmailAddr
        // console.log('MB_zPIOfrAddrInfoDtoList', this.MB_zPIOfrAddrInfoDtoList)
      }

      // ============================================
      // 2)    청약서 목록 구성
      // ===========================================
      let zPIOfrPblItmInfoDtoList = zPIEntplPblItmListDto.zPIOfrPblItmInfoDtoList

      if (zPIOfrPblItmInfoDtoList !== undefined && zPIOfrPblItmInfoDtoList !== null) {
        this.MB_strEltrnOfrYn = zPIEntplPblItmListDto.zaEltrnOfrYn // (전차청약여부)

        // 2022.07.29_ASR220600568_[사랑on 가설] 지급정보 관련 알림톡 발송_youngki78.lee
        // 모바일 지급조회 정보 구성
        this.MB_pymInfoObjYn = (zPIEntplPblItmListDto.pymInfoObjYn === 'Y') // 모바일지급조회가능여부
        this.MB_zMobspSendPymDto = zPIEntplPblItmListDto.zMobspSendPymDto // 모바일지급조회 발송데이터

        // 첨부파일명목록
        let attachFileNmList = ''

        // 청약서를 발행항목에 설정한다.
        let cntGrid = {
          'title01': '청약서',
          'title02': '청약서',
          'zaPblCntnt': '청약서',
          'zaSno': '101',
          'zaPblNos': '1',
          'zaApndFileNm': '청약서',
          'checkField': 'N',
          'checked': false // NEXT사랑On 추가
        }
        this.lv_ItemLists.push(cntGrid)
        // this.attachFileNmList += '청약서/'    // 2017.06.30 첨부목록 청약서 제외

        // 청약관련발행항목을 설정한다.
        this.lv_JointRecuit = false
        if (!this.pblEtrtFlg) {
          for ( let i = 0; i < zPIOfrPblItmInfoDtoList.length; i++) {
            // 고지사항 미출력의 경우, 발행목록에서 제외한다.
            if (zPIOfrPblItmInfoDtoList[i].zaSno === '199') {
              this.MB_NoDSCLSFlg = true
              continue
            }

            if (zPIOfrPblItmInfoDtoList[i].zaSno === '136') this.lv_JointRecuit = true
            let entplObj = {}

            entplObj.title01 = '청약서'

            if (zPIOfrPblItmInfoDtoList[i].zaSno === '102' ||
                zPIOfrPblItmInfoDtoList[i].zaSno === '103' ||
                zPIOfrPblItmInfoDtoList[i].zaSno === '124' ||
                zPIOfrPblItmInfoDtoList[i].zaSno === '140' ||
                zPIOfrPblItmInfoDtoList[i].zaSno === '150') {
              entplObj.title02 = '청약서'
              // entplObj.title01 = zPIOfrPblItmInfoDtoList[i].zaPblCntnt
            } else {
              entplObj.title02 = '별첨'
              // entplObj.title01 = zPIOfrPblItmInfoDtoList[i].zaPblCntnt
            }

            entplObj.zaSno = zPIOfrPblItmInfoDtoList[i].zaSno
            entplObj.zaPblCntnt = zPIOfrPblItmInfoDtoList[i].zaPblCntnt
            entplObj.zaPblNos = zPIOfrPblItmInfoDtoList[i].zaPblNos
            entplObj.zaApndFileNm = zPIOfrPblItmInfoDtoList[i].zaApndFileNm
            entplObj.checkField = 'N'
            entplObj.checked = false // NEXT사랑On 추가

            this.lv_ItemLists.push(entplObj)

            // 첨부파일리스트 설정
            if (zPIOfrPblItmInfoDtoList[i].zaSno !== '103' &&
                // zPIOfrPblItmInfoDtoList[i].zaSno !== '113' &&
                // zPIOfrPblItmInfoDtoList[i].zaSno !== '114' &&
                zPIOfrPblItmInfoDtoList[i].zaSno !== '116') {
              attachFileNmList += zPIOfrPblItmInfoDtoList[i].zaApndFileNm + '/'
            }
            // 출금동의서 존재여부(후입금 선택여부): 발행목록에 160로된 "출금동의서"가 구성된 경우라면,후입금이 아님
            if (zPIOfrPblItmInfoDtoList[i].zaSno === '160') {
              this.zzaftRctmFg = '0'
            }
            // ASR230100923_(가입설계)가상화폐제공업체 AML로직 적용
            // 204,205 정치적 주요인물(PEP)추가 확인서 / (ASR230800828_231103)
            if (zPIOfrPblItmInfoDtoList[i].zaSno === '174' || zPIOfrPblItmInfoDtoList[i].zaSno === '204' || zPIOfrPblItmInfoDtoList[i].zaSno === '205') {
              this.pblAMLFg = false
              // this.getStore('toast').dispatch('ADD', '174 확인')
            }
          }
        }

        // 첨부파일명목록의 마지막에 붙어있는 “/”제거
        this.MB_strApndFileNmList = attachFileNmList.substring(0, attachFileNmList.length - 1)
        // 청약서 분류(청약서/별첨)로 구분하여 행을 merge하여 발행물디자인명과 발행물형태명이 반복되지 않게 함(화면참조)

        // 위치 이동 됨
        // let cntGridCnt = this.lv_ItemLists.length

        // // 강제선택대상인 발행물처리
        // for ( let i = 0; i < cntGridCnt + 1; i++ ) {
        // //    let sno = grid.getCellData(i, "zaSno");
        //   let sno = this.lv_ItemLists[i].zaSno
        //   //    grid.setCellData(i, 'ENABLED', true);
        //   // 상설(체결용)등 필수서류 체크박스는 ‘편집불가’상태로 처리
        //   if (sno === '102' || sno === '103' || sno === '109' || sno === '136' || sno === '140' || sno === '142' || sno === '143' || sno === '145' || sno === '146' || sno === '147' || sno === '148' || sno === '151' || sno === '152' || sno === '153' || sno === '160') {
        //     // grid.setCellData(i, 'ENABLED', false);
        //     console.log('ENABLED - false')
        //   } else {
        //     // grid.setCellData(i, 'ENABLED', true);
        //     console.log('ENABLED - true')
        //   }

        //   //    // 외국인 상설 언어선태 표시처리
        //   if (sno === '140') {
        //     //  document.getElementById("tmpDiv1").style.display = "block";
        //     // 외국인 처리 필요
        //   }
        // }

        // 주소필드에 조회 결과의 자택/직장 주소 값을 셋팅함(필드정의 참조)
        if (this.MB_zPIOfrAddrInfoDtoList !== null && this.MB_zPIOfrAddrInfoDtoList.length > 0 ) {
          for (let j = 0; j < this.MB_zPIOfrAddrInfoDtoList.length; j++ ) {
            // 주소구분코드(1:자택, 2:직장)
            let zPIOfrAddrInfoDto = this.MB_zPIOfrAddrInfoDtoList[j]
            if (zPIOfrAddrInfoDto.zaAddrScCd === '1') {
              this.lv_AddressData[0] = zPIOfrAddrInfoDto
            } else {
              this.lv_AddressData[1] = zPIOfrAddrInfoDto
            }
          }
          if ( this.lv_AddressData[0] !== undefined && this.lv_AddressData[0] !== '' && this.lv_AddressData[0] !== {} ) {
            if (this.lv_AddressData[0].zaPstcd.length !== 0 || this.lv_AddressData[0].zaAddr.length !== 0) {
              this.lv_Address = this.lv_AddressData[0].zaPstcd + ', ' + this.lv_AddressData[0].zaAddr
            }
          } else {
            if (this.lv_AddressData[1].zaPstcd.length !== 0 || this.lv_AddressData[1].zaAddr.length !== 0) {
              this.lv_Address = this.lv_AddressData[1].zaPstcd + ', ' + this.lv_AddressData[1].zaAddr
            }
          }
          // if ( this.lv_AddressData[0] !== undefined && this.lv_AddressData[0] !== '' && this.lv_AddressData[0] !== {} ) {
          //   this.lv_Address = this.lv_AddressData[0].zaPstcd + ', ' + this.lv_AddressData[0].zaAddr
          // } else {
          //   this.lv_Address = this.lv_AddressData[1].zaPstcd + ', ' + this.lv_AddressData[1].zaAddr
          // }
        }

        // console.log('this.MB_strEltrnOfrYn / ', this.MB_strEltrnOfrYn)
        // 전자청약버튼 활성화 처리
        this.lv_ShowEltrnOfrBtn = false
        if (!this.MB_vCustYn && this.MB_strEltrnOfrYn !== undefined && this.MB_strEltrnOfrYn === 'Y') {
          this.lv_ShowEltrnOfrBtn = true
        }
      }
      // ============================================
      // 3)    서비스안내장 목록 구성
      // - 서비스안내장 대상상품 & 청약서발행목록 有 -> 청약서목록에구성
      // - 서비스안내장 대상상품 & 청약서발행목록 無 -> 부분발행목록리스트에 추가
      // ===========================================
      let zGudnlPblDto = zPIEntplPblItmListDto.zGudnlPblDto
      if (zGudnlPblDto !== undefined && zGudnlPblDto !== null && !piCommonUtil.isEmpty(zGudnlPblDto.zaUntySlmtBasInfoNo)) {
        let zPIEntplPblItmInfoDto4 = {
          title01: '상품설명서(제안용)',
          title02: '안내장',
          zaSno: '990',
          zaPblCntnt: '건강관리서비스 안내장',
          zaHptsPblItmCntnt: '',
          zaPblNos: '1',
          zaApndFileNm: '',
          zaUntySlmtBasInfoNo: zGudnlPblDto.zaUntySlmtBasInfoNo,
          checkField: 'N',
          checked: false // NEXT사랑On 추가
        }

        let checkZpIEntplPblItmListDto = zPIEntplPblItmListDto.zPIOfrPblItmInfoDtoList
        if (checkZpIEntplPblItmListDto !== undefined && checkZpIEntplPblItmListDto !== null) {
          this.lv_ItemLists.push(zPIEntplPblItmInfoDto4)
          // PDF true여부
          this.chkPdtmannualproposal = 'N'
          this.MB_numEntplPblCnt++
        } else {
          this.lv_ItemLists_1.push(zPIEntplPblItmInfoDto4)
          this.chkPdtmannualproposal = 'Y'
          this.MB_numEntplPblCnt++
        }
      }

      // 가설 이메일 전송 여부 조회(이메일)
      // this.fn_Command_S2() // - 20181218 : 이영기 선임 : S2는 사용하지 않음

      console.log('this.lv_ItemLists', this.lv_ItemLists)

      if ( this.lv_ItemLists.length === 0 ) {
        this.lv_ShowAfter = false
        this.lv_IsDataListEmpty = true
        this.lv_Chk1.push('1')
        this.fn_InputTab1()

        // 보험거래문서디지털화 : 선택발행 리스트 ALL CHECK
        this.$nextTick(function () {
          if (this.lv_ChkCnt1 !== this.lv_ItemLists_1.length) {
            this.fn_SelectAllItemsFunc_1()
          }
          // if (this.lv_SelectItems_1.length !== this.lv_ItemLists_1.length) {
          //   this.fn_SelectAllItemsFunc_1()
          // }
        })
      } else {
        this.lv_ShowAfter = true
        this.lv_Chk1.push('2')
        this.currTab = '2'
        this.fn_InputTab2()
      }
      // ===================================================================
      // [선심사 및 영업시스템 고도화PJT] 추가
      // ===================================================================
      this.fn_ChkNundwInfo()
      // ===================================================================

      // 유의대체계약 사전결재 추가 시작
      var lv_Vm = this
      if(!piCommonUtil.isEmpty(zPIEntplPblItmListDto.atntRplcContTypCdList)){
    	  this.lv_PreSancObjNum = '1'   
    	  this.lv_ResultAtntRplcContTypCdList = zPIEntplPblItmListDto.atntRplcContTypCdList
    	  let resultList = this.lv_ResultAtntRplcContTypCdList.split(',')
    	  for (let i = 0; i < resultList.length; i++){
    		  for (let j = 0; j < this.lv_AtntRplcContTypeCds.length; j++ ) {
    			  if (resultList[i] === this.lv_AtntRplcContTypeCds[j].key) {
    				  if(i === 0){
    					  this.lv_AtntRplcContTypeCdList += this.lv_AtntRplcContTypeCds[j].label
    				  }else{
    					  this.lv_AtntRplcContTypeCdList += ', ' + this.lv_AtntRplcContTypeCds[j].label
    				  }
    				  break
    			  }
    		  }
    	  }
      }else{
    	  this.lv_PreSancObjNum = '0' // 사전결재 상태값 0: 사전결재 없음, 1: 사전결재, 2: 사전결재진행중, 3: 결재완료
          this.lv_ResultAtntRplcContTypCdList = ''
          this.lv_AtntRplcContTypeCdList = ''
          this.lv_PreSancReqYn = ''
          this.lv_AtntRplcContTxt = ''
      }
      // 유의대체계약 사전결재 추가 종료
      // window.vue.getStore('progress').dispatch('HIDE') // 차트 생성후 로딩바 지우기
      window.vue.getStore('progress').dispatch('FULL')
      this.fn_isLoadingMsg(false)
      this.isLoading = false
    },
    /************************************************************************************************
    * Function명  : fn_AgeCheckInsrd21
    * 설명        : 주피보험자 미성년자 여부 체크 리턴
    ************************************************************************************************/
    fn_AgeCheckInsrd21 () {
      let retrunValue = false
      let zzleglAgeVl = -1
      let rltnrInfoList = []
      let cnt = 0
      let msg = ''

      rltnrInfoList = this.tmodel.rltnrInfo
      cnt = (rltnrInfoList) ? rltnrInfoList.length : 0

      if (cnt > 0 ) {
        for ( let i = 0; i < cnt; ++i) {
          if ( rltnrInfoList[i].zzcontvInsrdCd === '21' ) { // 주피보험자
            zzleglAgeVl = rltnrInfoList[i].zzleglAgeVl
            if (zzleglAgeVl <= 18 ) {
              this.lv_mnisdNm = rltnrInfoList[i].zaInsrdNm
              this.lv_mnisdRrn = rltnrInfoList[i].zzcustRrnId
              retrunValue = true
            }
            break
          }
        }// for i
      }
      if ( zzleglAgeVl === -1 ) {
        msg += '주피보험자 나이를 알 수 없습니다.'
      }

      if ( msg !== '' ) {
        this.lv_AlertMsg = msg
        this.fn_ShowErrorMsg()
        // this.lv_ShowErrorPopup = true
      }

      return retrunValue
    },
    // ====================================== 전자청약 관련 함수 start ====================================== //
    /************************************************************************************************
    * Function명  : fn_BtnP8clickHandler
    * 설명        : 전자청약 처리
    *               미사용
    ************************************************************************************************/
    fn_BtnP8clickHandler (opName) {
      if (!this.fn_ChkValid(opName)) {
        window.vue.getStore('progress').dispatch('HIDE')
        return
      }
      this.lv_PrcsEtapSendMsg = '전자청약을 전송하시겠습니까?'
      // this.lv_PrcsEtapSend = true
      this.$refs.refPrcsEtapSend.open()
      this.EntplEltrnSign_opName = opName
    },
    fn_PrcsEtapSend () {
      // this.lv_PrcsEtapSend = false
      this.$refs.refPrcsEtapSend.close()
      this.$nextTick(function () {
        window.vue.getStore('progress').dispatch('SHOW')
        this.$nextTick(() => {
          this.fn_PrcsEtapSendNext()
        })
      })
    },
    fn_PrcsEtapSendNext () {
      let opName = this.EntplEltrnSign_opName
      this.EntplEltrnSign_opName = ''

      this.fn_PblCommFunc(opName, 'N', 'URLPQL00068', 'Y')
    },
    fn_PrcsEtapSendCancel () {
      // this.lv_PrcsEtapSend = false
      this.$refs.refPrcsEtapSend.close()
      this.EntplEltrnSign_opName = ''
    },
    // ====================================== 전자청약 관련 함수 end ====================================== //
    /************************************************************************************************
     * Function명  : fn_onClickTabLabel
     * 설명        : 탭이동 효과 : v-show
     ************************************************************************************************/
    fn_onClickTabLabel (tab) {
      // 현재탭과 동일한 탭이면 탭이동 안한
      // if (this.currTab === tab) {
      //   return
      // }
      if (this.currTab === tab) {
        return
      }
      // this.currTab = this.$bizUtil.cloneDeep(this.lv_Chk1)
      this.currTab = tab
      this.lv_Chk1 = [tab]
      // 상품설명서(제안용) tab으로 이동되었고, 선택발행 리스트가 없으면 목록 영역 안보이게.
      if (this.currTab === '1' && this.lv_ItemLists_1.length === 0 ) {
        this.lv_ShowMiddle = false
      } else {
        this.lv_ShowMiddle = true
      }
    },
    /************************************************************************************************
     * Function명  : fn_InputTab1
     * 설명        : 상품설명서 제안용 버튼 클릭시
     ************************************************************************************************/
    fn_InputTab1 () {
      if (this.lv_ItemLists_1.length > 0 && this.lv_Chk1.includes('1')) {
      // if (this.lv_ItemLists_1.length > 0 && this.lv_Chk1.includes('1')) {
        if (this.lv_ChkCnt1 === 0) {
          if (this.lv_ChkCnt1 !== this.lv_ItemLists_1.length) {
            this.fn_SelectAllItemsFunc_1()
          }
        }
        // if (this.lv_SelectItems_1.length === 0) {
        //   this.$nextTick(function () {
        //     if (this.lv_SelectItems_1.length !== this.lv_ItemLists_1.length) {
        //       this.fn_SelectAllItemsFunc_1()
        //     }
        //   })
        // }
      } else {
        // this.lv_SelectItems_1.splice(0)
      }
      // 보험거래문서디지털화 : 현재 상품설명서 탭인 경우 선택발행 리스트가 없으면 목록 영역 숨기기
      if (this.currTab === '1' && this.lv_ItemLists_1.length === 0) {
        this.lv_ShowMiddle = false
      }
    },
    /************************************************************************************************
     * Function명  : fn_InputTab2
     * 설명        : 청약서 버튼 클릭시
     ************************************************************************************************/
    fn_InputTab2 () {
      // console.log('fn_InputTab2 =============')
      if (this.lv_Chk1.includes('2')) {
        if (this.currTab === '2') {
          this.lv_ShowMiddle = true
        }
        if (this.lv_ChkCnt2 !== this.lv_ItemLists.length) {
          this.fn_SelectAllItemsFunc()
        }
        // this.$nextTick(function () {
        //   if (this.lv_ChkCnt1 !== this.lv_ItemLists.length) {
        //     this.fn_SelectAllItemsFunc()
        //   }
        // })
      } else {
        // this.lv_SelectItems.splice(0)
      }
    },
    /************************************************************************************************
     * Function명  : fn_SetZZSELData
     * 설명        : 선택발행 여부 Y/N 세팅(보험거래문서지디털화)
     ************************************************************************************************/
    fn_SetZZSELData () {
      let chkList = []
      // 4/28
      if (this.currTab === '1') {
        chkList = this.lv_ItemLists_1.filter(item => item.checked)
      }
      // let chkList = this.lv_ItemLists_1.filter(item => item.checked)
      this.zPblPpslChkListDtoList.forEach((el, i) => {
        let idx = chkList.findIndex((item, idx) => {
        // let idx = this.lv_SelectItems_1.findIndex((item, idx) => {
          return item['zaPblCntnt'] === el['zaValue']
        })
        if (idx > -1) {
          el.zaSelYn = 'Y'
        } else {
          el.zaSelYn = 'N'
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_SelectAllItemsFunc_1
     * 설명        : 상품설명서 제안용 선택발행 목록 check box 모두 선택
     ************************************************************************************************/
    fn_SelectAllItemsFunc_1 (state) {
      if (this.lv_ChkCnt1 === this.lv_ItemLists_1.length) {
        this.lv_ItemLists_1.forEach((item) => {
          item.checked = false
        })
      } else {
        this.lv_ItemLists_1.forEach((item) => {
          item.checked = true
        })
      }
      // if (this.lv_SelectItems_1.length === this.lv_ItemLists_1.length) {
      //   this.lv_SelectItems_1 = []
      // } else {
      //   this.lv_SelectItems_1 = []
      //   this.lv_ItemLists_1.forEach((item) => {
      //     this.lv_SelectItems_1.push(item)
      //   })
      // }
    },
    /************************************************************************************************
     * Function명  : fn_SelectAllItemsFunc
     * 설명        : check box 모두 선택
     ************************************************************************************************/
    fn_SelectAllItemsFunc (state) {
      if (this.lv_ChkCnt2 === this.lv_ItemLists.length) {
      // if (this.lv_AllSel2) {
        this.lv_ItemLists.forEach((item) => {
          item.checked = false
        })
      } else {
        this.lv_ItemLists.forEach((item) => {
          item.checked = true
        })
      }
      // if (this.lv_SelectItems.length === this.lv_ItemLists.length) {
      //   this.lv_SelectItems = []
      // } else {
      //   this.lv_SelectItems = []
      //   this.lv_ItemLists.forEach((item) => {
      //     this.lv_SelectItems.push(item)
      //   })
      // }
    },
    /************************************************************************************************
     * Function명  : fn_GetCustInfo
     * 설명        : 고객카드정보 조회(이메일,핸드폰)
     ************************************************************************************************/
    fn_GetCustInfo (_rltnrInfo_11) {
      let _rltnrInfo = this.tmodel.rltnrInfo
      if (piCommonUtil.isEmpty(_rltnrInfo)) {
        return
      }

      let param = {
        cnsltNo: this.headerVO.userEno,
        custId: '',
        custNm: _rltnrInfo_11.zaInsrdNm,
        chnlCustId: _rltnrInfo_11.zaAgtFileId
      }
      
      piCommonUtil.invoke('UF1MDI0270', param, this.fn_ResponseDnlSuitaData, null, this.fn_ResponseDnlSuitaData, this, this)
    },
    /************************************************************************************************
     * Function명  : fn_ResponseDnlSuitaData
     * 설명        : 고객카드정보 조회(이메일,핸드폰) 후 처리
     ************************************************************************************************/
    fn_ResponseDnlSuitaData (returnData) {
    // 서비스 후처리
      if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
        let celno = returnData.celno
        let emailAddr = returnData.emailAddr
        this.lv_Celno = celno
        this.MB_EmailAddr = emailAddr === ' ' ? '' : emailAddr
        // NEXT 사랑On
        if (this.lv_ChgCustInfo) {
          window.vue.getStore('progress').dispatch('HIDE')
          if (_.isEmpty(this.$refs.refPopEmailConfirmFlag) || this.$refs.refPopEmailConfirmFlag.status === 'close') {
            this.fn_BtnNsEusPrcsClickHandler('btnEmail')
          }
          this.lv_ChgCustInfo = false
        }
        if (this.$refs.refPopEmailConfirmFlag && this.$refs.refPopEmailConfirmFlag.status === 'open') {
          window.vue.getStore('progress').dispatch('HIDE')
          if (this.MB_EmailAddr === '') {
            this.$refs.refPopEmailConfirmFlag.close()
            this.lv_RegEmailAddrMsg = '고객(계약자)의 이메일 주소가 등록되지 않았습니다.</br>이메일 주소를 등록하시겠습니까?'
            this.$refs.refRegEmailAddr.open()
            return
          } else {
            this.lv_PopEmailConfirmMsg.emailAddr = emailAddr
          }
        }
        // console.log('Ah~~~~~~~~~~~~~~~~~ check this...........', celno )
      } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
        // console.log('#Ah~~~~~~~~~~~~~~~~~ check this...........')
      }
    },
    /************************************************************************************************
     * Function명  : fn_chkMobpsSignBtn
     * 설명        : #2020모바일청약, 모바일청약 버튼 표시 조건
     ************************************************************************************************/
    fn_chkMobpsSignBtn () {
      this.lv_ShowMobpsSignBtn = true

      // 종피보험자가 미성년자인경우 모바일청약 불가
      let _rltnrInfo = this.tmodel.rltnrInfo
      let _rltnrInfo_23 = ''
      if (!piCommonUtil.isEmpty(_rltnrInfo)) {
        _rltnrInfo_23 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '23' })[0] // 종피보험자
      }

      if (_rltnrInfo_23 !== null && _rltnrInfo_23 !== undefined) {
        let birthDay23 = _rltnrInfo_23.zzcustRrnId.substr(0, 6)

        if (birthDay23 === '000000') {
          // 태아
          this.lv_ShowMobpsSignBtn = false
        } else if ( !this.$bizUtil.compareCurrentAge(birthDay23, 19) ) {
          // 종피보험자 19세 미만
          this.lv_ShowMobpsSignBtn = false
        }
      }

      // 법인명의 개인보험에 종피보험자가 있는 경우 모바일청약 불가
      let rltnrInfoList = this.tmodel.rltnrInfo
      let cnt = (rltnrInfoList) ? rltnrInfoList.length : 0
      if (cnt > 0 ) {
        for ( let i = 0; i < cnt; ++i) {
          // 계약관점피보험자유형코드, 11:계약자
          // 고객상세유형코드, 21:법인사업자
          if ( rltnrInfoList[i].zzcontvInsrdCd === '11' && rltnrInfoList[i].zzcusDtlTypCd === '21') {
            // 종피보험자가 있는지 확인
            _rltnrInfo_23 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '23' })[0] // 종피보험자
            if (_rltnrInfo_23 !== null && _rltnrInfo_23 !== undefined) {
              this.lv_ShowMobpsSignBtn = false
            }
            break
          }
        }// for i
      }

      // 모바일청약 가능여부 확인(모바일영업지원 업무 Config)
      let auth = 'S'
      let trnstId = 'txTSSPS50S5'
      let lv_Vm = this
      let param = {}

      this.post(lv_Vm, param, trnstId, auth, null, true)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            lv_Vm.isMobpsSrvcPsb = response.body.isMobpsSrvcPsb
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(window.vue.error)
    },
    /************************************************************************************************
     * Function명  : fn_AsgnProxyClamPsb
     * 설명        : 지정대리청구인 지정가능여부
     *             : 2020 1116
     ************************************************************************************************/
    fn_AsgnProxyClamPsb () {
      this.isAsgnProxyClamPsb = true

      /* 지정대리청구인 선택란 활성화 조건
         : 계약자=피보험자
         : 계약자=수익자 상해/만기/분할/연금선수익자 中 지정된 모든 수익자
      */

      // 계약자 정보 조회
      let _rltnrInfo = this.tmodel.rltnrInfo
      let _rltnrInfo_11 = '' // 계약자
      let _rltnrInfo_21 = '' // 주피보험자
      if (!piCommonUtil.isEmpty(_rltnrInfo)) {
        _rltnrInfo_11 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]
        _rltnrInfo_21 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '21' })[0]
      } else {
        this.isAsgnProxyClamPsb = false
      }

      // 계약자와 피보험자 상이여부 검증
      if (_rltnrInfo_11.zaAgtFileId !== _rltnrInfo_21.zaAgtFileId) {
        this.isAsgnProxyClamPsb = false
      }

      // 수익자 검증
      let lv_bnfrInfoList = this.tmodel.bnfrInfo
      if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0) {
        let itemIdx = 0
        let itemsCnt = lv_bnfrInfoList.length
        // let hasDthBnfr = false
        let hasOthBnfr = false
        for ( itemIdx; itemIdx < itemsCnt; itemIdx++ ) {
          let lv_bnfrInfo = lv_bnfrInfoList[itemIdx]
          /* bnfrRolCd  : 31: 만기수익자(기본값:계약자), 32: 분할수익자(기본값:계약자), 33: 연금수익자(기본값:계약자),
                          34: 상해수익자(기본값:피보험자), 35: 사망수익자(기본값:상속자), 39: 연금후수익자(기본값:상속자) */
          if ((lv_bnfrInfo.zzbnfrRolCd !== null || lv_bnfrInfo.zzbnfrRolCd !== undefined )) {
            // ASR211000708_지정대리청구 로직 수정 및 신규 팝업 반영_사랑온 가입설계
            // 1) 사망수익자 이외의 수익자가 없으면 비활성
            // if ( lv_bnfrInfo.zzbnfrRolCd === '35' ) hasDthBnfr = true
            if ( lv_bnfrInfo.zzbnfrRolCd === '31' || // 31: 만기수익자
             lv_bnfrInfo.zzbnfrRolCd === '32' || // 32: 분할수익자
             lv_bnfrInfo.zzbnfrRolCd === '33' || // 33: 연금선수익자
             lv_bnfrInfo.zzbnfrRolCd === '34' ) { // 34: 상해수익자
              hasOthBnfr = true
            }
            // 2) 사망/연금후 수익자 이외의 수익자가 계약자와 불일치할 경우, 비활성
            if ( lv_bnfrInfo.zzbnfrRolCd === '35' || lv_bnfrInfo.zzbnfrRolCd === '39') {
              // 지정대리청구인 비활성화 제외 조건 : (35:사망수익자, 39:연금후수익자)
              continue
            } else if ( lv_bnfrInfo.knb !== '0000000000000' && (lv_bnfrInfo.custNm !== _rltnrInfo_11.zaInsrdNm || lv_bnfrInfo.knb.substr(0, 6) !== _rltnrInfo_11.zzcustRrnId.substr(0, 6)) ) {
              // 계약자와 수익자가 불일치할 경우, 지정대리청구인 비활성
              this.isAsgnProxyClamPsb = false
            }
          }
        }
        // 상기 1) 사망수익자 이외의 수익자가 없으면 비활성
        if ( !hasOthBnfr ) {
          this.isAsgnProxyClamPsb = false
        }
      } // if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0)
    },
    /************************************************************************************************
     * Function명  : fn_PblCommFunc
     * 설명        : 발행 공통처리 ex) (opName, "N", "URLPQL00068", "Y");
     ************************************************************************************************/
    fn_PblCommFunc (opName, pblYn, urlId, eltrnOfrYn, eltrnSignYn) {
      // input parameter 셋팅
      let zPIHptsPblHstrListDto = this.fn_SetCommInputParam(opName, eltrnSignYn)

      zPIHptsPblHstrListDto.zaPblYn = pblYn // 인쇄여부
      zPIHptsPblHstrListDto.zaEltrnOfrYn = eltrnOfrYn // 전자청약여부
      zPIHptsPblHstrListDto.zaEltrnSignYn = eltrnSignYn // 전자서명여부

      // #2020모바일청약, 전자서명방법구분코드 추가
      // - PQM에서 계약채널구분코드(15:일반모집(전자서명), 16:일반모집(모바일청약))를 구분하기 위해서
      //   변수 추가
      zPIHptsPblHstrListDto.zaEltrnSignMthCd = 'T' // 전자서명방법코드, T:태블릿, M:모바일
      if (opName === 'P10') {
        zPIHptsPblHstrListDto.zaEltrnSignMthCd = 'M' // M:모바일
      }

      // DI채널 상담메인 구분자조회 - 사랑On 미사용
      // zPIHptsPblHstrListDto.zaChnlClSalesScCd = headerVO.srvcMngScCd;

      // 보험거래문서디지털화 : 상품설계서 선택발행 여부 Y/N 세팅
      if (this.zPblPpslChkListDtoList.length > 0) {
        this.fn_SetZZSELData()
        zPIHptsPblHstrListDto.zPblPpslChkListDtoList = this.zPblPpslChkListDtoList
      }

      this.tmodel.zPIHptsPblHstrListDto = zPIHptsPblHstrListDto

      // 발행 서비스 호출
      let inputJson = {
        pmodel: this.pmodel,
        tmodel: this.tmodel
      }
      
      // console.log('발행 서비스 input ===')
      if (urlId === 'URLPQL00068' || urlId === 'URLPQL00399') {
        Gaf.execute('MSPPI250P', urlId, inputJson, true, null, this)
      } else if (urlId === 'URLPQL00186' || urlId === 'UF1PQL0388') {
        let nextServiceFn = () => {
          this.fn_TurnOnProgress()
        }
        Gaf.execute('MSPPI250P', urlId, inputJson, true, nextServiceFn, this)
      }
    },
    /************************************************************************************************
     * Function명  : fn_TurnOnProgress
     * 설명        : nas 에 pdf 가 있는 지 확인할 때 여러번 post하는데 이때 progress 를 show 해줘야함
     ************************************************************************************************/
    fn_TurnOnProgress () {
      // window.vue.getStore('progress').dispatch('UPDATE', true)
    },
    /************************************************************************************************
     * Function명  : fn_SetCommInputParam
     * 설명        : 발행처리시 필요한 IN값 설정
     ************************************************************************************************/
    fn_SetCommInputParam (opName, eltrnSignYn = 'N') {
      if (eltrnSignYn === '') eltrnSignYn = 'N'
      let checkIndices = this.fn_CheckindexArray()
      if ( checkIndices.length <= 0 ) {
        console.log('선택된 아이템이 없어서 에러 입니다.')
        return
      }

      let zPIHptsPblHstrListDto = {}
      let zPIHptsPblHstrDtoList = []
      let zPIHptsPblHstrDto = {}

      // 8 발행목록 조회를 제외한 모든 Event의 서비스 공통 input parameter의 셋팅(미리보기,인쇄,PDF저장,E-mail,전자청약)
      zPIHptsPblHstrListDto.zaPlanId = this.MB_strPlanlId
      zPIHptsPblHstrListDto.zaPlanScCd = this.MB_strPlanScCd
      zPIHptsPblHstrListDto.zaHptsCvplId = this.MB_strCnvrPlanId
      zPIHptsPblHstrListDto.zaVainsUnQulfYn = this.MB_strSalesVprYN

      // 발행전송구분코드 설정(내외부망 구분)
      // 접근망구분값(1:LAN外), 조직종류코드(17:GA,21:DI)의 경우, 외부망으로 설정.
      // if (this.headerVO.srvcMngScCd === 'GA' || this.headerVO.srvcMngScCd === 'DI') {
      //   zPIHptsPblHstrListDto.zaPblTrnsScCd = '2'
      // } else {
      //   zPIHptsPblHstrListDto.zaPblTrnsScCd = '1'
      // }

      // 1: 내부망 or 인쇄, 2: LTE 외부망, 3: 미리보기
      if ( opName === 'P1' ) {
        zPIHptsPblHstrListDto.zaPblTrnsScCd = '1' // 인쇄
      } else if ( opName === 'P2' ) {
        zPIHptsPblHstrListDto.zaPblTrnsScCd = '3' // 미리보기
      } else if ( opName === 'MnlLMS' ) {
        zPIHptsPblHstrListDto.zaPblTrnsScCd = '' // 상설(제안서) LMS 전송
      } else if (this.$MenuManager.fn_GetInternalNetwork()) {
        zPIHptsPblHstrListDto.zaPblTrnsScCd = '1' // 내부망
      } else {
        zPIHptsPblHstrListDto.zaPblTrnsScCd = '2' // 외부망
      }

      // 상품명,상품코드 설정
      let abdapolicy = this.abdapolicy[0]
      let abdapolpr = abdapolicy.abdapolpr[0]
      let zaPrdtNm = abdapolpr.ztpqlCboextPrcsAbdapolpr.zaPrdtNm
      let zaPrcd = this.MB_prcd

      // 합계보험료, 실납입보험료 설정
      let polprAbdaprem = abdapolpr.polprAbdaprem[0] // POLPR_ABDAPREM
      let zaSmtotPrm = polprAbdaprem.premaftertaxAm ? polprAbdaprem.premaftertaxAm : '0' // 합계보험료(=세후보험료)
      let zaRlpadPrm = polprAbdaprem.polprAldaprem.zzrlpadPrmAm ? polprAbdaprem.polprAldaprem.zzrlpadPrmAm : '0' // 실납입보험료

      // let chkIdx = 0 -- 사용 안함
      // console.log(checkIndices, 'checkIndices')
      for (let i = 0; i < checkIndices.length; i++) {
        let selectObj = checkIndices[i]
        let strSno = selectObj['zaSno']
        let numSno = parseInt(strSno, 10)
        // console.log('checkIndices numSno == ', numSno)

        // 전자서명시 상품설명서(회사보관용)은 세팅 안함
        // #2020모바일청약, 모바일청약을 조건에 추가
        if (opName === 'P6' || opName === 'P10') {
          if (numSno === 103) continue
        }

        // PDF저장,e-mail전송, 비대면화상상담(P44)의 경우,상설(제안용)만 설정한다.
        if ((opName === 'P3' || opName === 'P4' || opName === 'MnlLMS' || opName === 'P44') && numSno !== 0) continue

        zPIHptsPblHstrDto = {}

        // 공통값 설정
        zPIHptsPblHstrDto.zaPblObjScCd = strSno
        zPIHptsPblHstrDto.zaUntySlmtId = this.MB_strGenUsHptsFrmCd
        zPIHptsPblHstrDto.zaUntySlmtBasInfoNo = this.MB_strGenUsHptsFrmCd
        zPIHptsPblHstrDto.zaPUsPrdtExplnFrmCd = this.MB_strUsPrdtExplnFrmCd
        zPIHptsPblHstrDto.zaPblScNm = selectObj.zaPblCntnt
        zPIHptsPblHstrDto.zaPrdtNm = zaPrdtNm
        zPIHptsPblHstrDto.zaPrcd = zaPrcd
        zPIHptsPblHstrDto.zaSmtotPrm = zaSmtotPrm
        zPIHptsPblHstrDto.zaRlpadPrm = zaRlpadPrm
        zPIHptsPblHstrDto.zEntplTlgmCntntDto = {entplTlgmCntnt: 'test'} // 임시로 설정
        if (this.gachangeTF){
          zPIHptsPblHstrDto.zaRecmFpEno = this.headerVO.userId // 컨설턴트 번호
          zPIHptsPblHstrDto.zaRecmEplyScCd = '30'
        }

        // 발행컬러를 셋팅하는데 인쇄일 경우 가입설계서, 본계약은 화면에서 선택한 컬러 구분값을 셋팅함
        // 그외 회사보관용 상품설명서만 흑백(B), 나머지는 컬러(C)로 강제 설정
        if (numSno === 0) {
          zPIHptsPblHstrDto.zaColorScCd = this.lv_Color === '1' ? 'M' : 'C'
          // zPIHptsPblHstrDto.zaColorScCd = this.lv_Color[0].key === '1' ? 'M' : 'C'
        } else {
          // 2023.07 회사보관용도 컬러로 출력되도록 변경요청(장유휘프로)
          // if (numSno === 103) {
          //  zPIHptsPblHstrDto.zaColorScCd = 'M'
          // } else {
          zPIHptsPblHstrDto.zaColorScCd = 'C'
          // }
        }

        // 상품설명서 제안용은 암호화되어 있지 않기 때문에 구분 지을 전역변수를 설정
        this.pdtmannualproposal = false
        console.log('numSno : ', numSno)
        if (numSno === 0) {
          this.pdtmannualproposal = true
        }

        if (numSno === 0 || (numSno >= 100 && numSno <= 103)) {
          zPIHptsPblHstrDto.zaSrcFileNm = ''
          zPIHptsPblHstrDto.zaPblNos = selectObj.zaPblNos

          // parent화면에서 넘어온 가입설계 전문의 길이가 4000Byte가 넘어 두개의 인덱스로 구분되었을때
          if (this.MB_vCustYn) {
            if (numSno === 0) {
              zPIHptsPblHstrDto.zaPrcd = this.MB_prcd
              zPIHptsPblHstrDto.zaPrdtVcd = this.MB_prdtVcd
            } else if (numSno === 102 || numSno === 103) {
              zPIHptsPblHstrDto.zaHptsPblItmCntnt = this.MB_prmnlCntnt
            }
          } else {
            if (numSno === 0 || numSno === 102 || numSno === 103) {
              zPIHptsPblHstrDto.zaHptsPblItmCntnt = this.MB_entplCntnt
            } else {
              zPIHptsPblHstrDto.zaHptsPblItmCntnt = ''
            }
          }
        } else if (numSno >= 104 && numSno <= 152 && opName !== 'P3' && opName !== 'MnlLMS') {
          // 청약서별첨양식의 대한 값을 넘겨준다
          zPIHptsPblHstrDto.zaSrcFileNm = selectObj.zaSrcFileNm
          zPIHptsPblHstrDto.zaPblNos = selectObj.zaPblNos
          if (numSno === 115) {
            zPIHptsPblHstrDto.zaHptsPblItmCntnt = this.MB_prpslCntnt
          } else {
            zPIHptsPblHstrDto.zaHptsPblItmCntnt = ''
          }
        } else if (numSno >= 310 && numSno <= 311 && opName !== 'P3' && opName !== 'P4' && opName !== 'MnlLMS') {
          // 적합성가입희망확인서의 대한 값을 넘겨준다
          zPIHptsPblHstrDto.zaSrcFileNm = selectObj.zaHptsPblItmCntnt
          zPIHptsPblHstrDto.zaPblNos = selectObj.zaPblNos
        } else if (numSno === 990) {
          // 2023.02 서비스안내장에 관한 값을 넘겨준다.
          if (this.chkPdtmannualproposal === 'Y') {
            this.pdtmannualproposal = true
          }
          zPIHptsPblHstrDto.zaUntySlmtBasInfoNo = selectObj.zaUntySlmtBasInfoNo
        }

        zPIHptsPblHstrDtoList.push(zPIHptsPblHstrDto)

        zPIHptsPblHstrListDto.zaApndFileCntnt = this.MB_strApndFileNmList
      }

      // 전자서명인 경우 발행목록 제일 마지막 인덱스에 저장용 가설전문 세팅
      /* 보험거래문서디지털화 : 20200915 EPL37 테이블에 저장된 0번 전문을 사용하는 곳이 없으므로, 영구적으로 막음
         가설CI와 협의 완료
      // #2020모바일청약, 모바일청약도 가설전문 셋팅
      if (opName === 'P6' || opName === 'P10') {
        if (eltrnSignYn === 'Y') {
          zPIHptsPblHstrDto = {}
          zPIHptsPblHstrDto.zaPblObjScCd = '0'
          zPIHptsPblHstrDto.zaUntySlmtId = this.MB_strGenUsHptsFrmCd
          zPIHptsPblHstrDto.zaUntySlmtBasInfoNo = this.MB_strGenUsHptsFrmCd
          zPIHptsPblHstrDto.zaPUsPrdtExplnFrmCd = this.MB_strUsPrdtExplnFrmCd
          zPIHptsPblHstrDto.zaPblScNm = '전체'
          zPIHptsPblHstrDto.zaPrdtNm = zaPrdtNm
          zPIHptsPblHstrDto.zaPrcd = zaPrcd
          zPIHptsPblHstrDto.zaSmtotPrm = zaSmtotPrm
          zPIHptsPblHstrDto.zaRlpadPrm = zaRlpadPrm

          zPIHptsPblHstrDto.zaPblNos = '0'
          zPIHptsPblHstrDto.zaColorScCd = 'M'

          if (this.MB_vCustYn) {
            zPIHptsPblHstrDto.zaPrcd = this.MB_prcd
            zPIHptsPblHstrDto.zaPrdtVcd = this.MB_prdtVcd
          } else {
            zPIHptsPblHstrDto.zaHptsPblItmCntnt = this.MB_entplCntnt
          }

          zPIHptsPblHstrDtoList.push(zPIHptsPblHstrDto)
        }
      }
      */

      // 청약서 제일 앞에 전자서명 동의서 추가
      // #2020모바일청약, 모바일청약을 조건에 추가
      if (opName === 'P6' || opName === 'P10') {
        if (eltrnSignYn === 'Y') {
          zPIHptsPblHstrDto = {}
          if (this.sandType === '청약') {
            zPIHptsPblHstrDto.zaPblObjScCd = '131'
            zPIHptsPblHstrDto.zaPblScNm = '전자서명 동의서'
            zPIHptsPblHstrDtoList.push(zPIHptsPblHstrDto)
          }
        }
      }

      zPIHptsPblHstrListDto.zPIHptsPblHstrDto = zPIHptsPblHstrDtoList

      // 청약서 주소정보 설정
      if (this.MB_zPIOfrAddrInfoDtoList !== null && this.MB_zPIOfrAddrInfoDtoList !== undefined && this.MB_zPIOfrAddrInfoDtoList.length > 0) {
        for ( let i = 0; i < this.MB_zPIOfrAddrInfoDtoList.length; i++) {
          let zPIOfrAddrInfoDto = this.MB_zPIOfrAddrInfoDtoList[i]

          // 화면에서 선택한 주소구분코드와 고객주소정보의 주소구분코드(1:자택, 2:직장)와 동일한 주소정보를 설정한다.
          if (this.lv_AddressType.key === zPIOfrAddrInfoDto.zaAddrScCd) {
            zPIHptsPblHstrListDto.zPIOfrAddrInfoDto = zPIOfrAddrInfoDto
          }
        }
      }

      // 인쇄 || 미리보기 || 전자청약 || 전자서명
      // if (opName === 'P1' || opName === 'P2' || opName === 'P5' || opName === 'P6') {
      // #2020모바일청약, 모바일청약 조건 추가
      // 인쇄 || 미리보기 || 전자청약 || 전자서명 || 모바일청약
      if (opName === 'P1' || opName === 'P2' || opName === 'P5' || opName === 'P6' || opName === 'P10') {
        if (this.fn_ForeignerLangChk()) { // 발행목록에 상품설명서-외국인이 있을 때
          let arrLangCd = this.lv_LangScCdArray // 7번의 선택한 외국어 값Array
          zPIHptsPblHstrListDto.zaLangScCd = ''
          for (let i = 0; i < arrLangCd.length; i++) {
            if (i > 0) zPIHptsPblHstrListDto.zaLangScCd += ','
            zPIHptsPblHstrListDto.zaLangScCd += arrLangCd[i]
          }
        }
      }

      // 고객유형을 설정한다.
      if (this.MB_vCustYn) { // 가상고객 여부
        zPIHptsPblHstrListDto.zaPrspCustYn = 'N' // 가상고객일 때 N
      } else if (this.MB_strTmpCustYn === 'Y') {
        zPIHptsPblHstrListDto.zaPrspCustYn = 'T' // 임시고객일 때 T
      } else {
        zPIHptsPblHstrListDto.zaPrspCustYn = 'Y' // 가망고객일 때 Y
      }

      // 유의대체계약 사전결재 추가 시작
      if(this.lv_PreSancObjNum === '1'){
          zPIHptsPblHstrListDto.preSancReqYn = this.lv_PreSancReqYn
          zPIHptsPblHstrListDto.atntRplcContTypCdList = this.lv_ResultAtntRplcContTypCdList
          console.log('preSancReqYn : ' + zPIHptsPblHstrListDto.preSancReqYn)
          console.log('atntRplcContTypCdList : ' + zPIHptsPblHstrListDto.atntRplcContTypCdList)    	  
      }
	  // 유의대체계약 사전결재 추가 종료
      // console.log(zPIHptsPblHstrListDto, 'zPIHptsPblHstrListDto')
      console.log('this.pdtmannualproposal : ' + this.pdtmannualproposal)
      return zPIHptsPblHstrListDto
    },
    /************************************************************************************************
     * Function명  : fn_CheckindexArray
     * 설명        : 상품설명서(제안용) 과 청약서에서 체크된 리스트를 반환한다.
     ************************************************************************************************/
    fn_CheckindexArray () {
      let checkIndices = []
      // console.log('fn_CheckindexArray ==> ', this.lv_Chk1)
      // 상품설명서(제안용) 체크
      if (this.currTab === '1') {
        let isCheked = this.lv_ItemLists_1.find(findItem => findItem.zaSno === '990')
        if (this.lv_ItemLists_1.length > 0 && this.lv_ChkCnt1 === 0 && _.isEmpty(isCheked)) {
          return []
        }
        this.lv_Item_Lists[0].zaSno = 0
        checkIndices.push(this.lv_Item_Lists[0])
      // if (this.lv_Chk1.includes('1')) {
        if ( this.lv_ChkCnt1 > 0 ) {
          // this.lv_Item_Lists[0].zaSno = 0
          // checkIndices.push(this.lv_Item_Lists[0])
        // if ( this.lv_ChkCnt1 > 0 ) {
          this.lv_ItemLists_1.forEach(item => {
            if (item.checked && item.zaSno === '990') {
              checkIndices.push(item)
            }
          })
        }
        // if ( this.lv_SelectItems_1.length > 0 ) {
        //   for ( let i = 0; i < this.lv_SelectItems_1.length; i++ ) {
        //     if (this.lv_SelectItems_1[i].zaSno === '990') {
        //       checkIndices.push(this.lv_SelectItems_1[i])
        //     }
        //   }
        // }
      }

      // 청약서 리스트 체크
      if ( this.currTab === '2' &&  this.lv_ChkCnt2 > 0 ) {
      // if ( this.lv_ChkCnt2 > 0 ) {
        this.lv_ItemLists.forEach(item => {
          if (item.checked) {
            checkIndices.push(item)
          }
        })
      }
      // if ( this.lv_SelectItems.length > 0 ) {
      //   for ( let i = 0; i < this.lv_SelectItems.length; i++ ) {
      //     checkIndices.push(this.lv_SelectItems[i])
      //   }
      // }

      // console.log(checkIndices, 'checkIndices')
      return checkIndices
    },
    /************************************************************************************************
     * Function명  : fn_CountSelectItem
     * 설명        : 상품설명서(제안용) 과 청약서 에 체크된 리스트의 갯수를 반환
     ************************************************************************************************/
    fn_CountSelectItem () {
      let count = 0
        // count += this.lv_ChkCnt2
        // count += this.lv_Chk1.includes('1') ? 1 : 0
      if (this.currTab === '2') {
        count += this.lv_ChkCnt2
      } else if (this.currTab === '1'){
        count += this.lv_Chk1.includes('1') ? 1 : 0
      }
      return count
    },
    /************************************************************************************************
     * Function명  : fn_ForeignerLangChk
     * 설명        : 외국인 상품설명서 언어 체크 필수 여부 체크
     ************************************************************************************************/
    fn_ForeignerLangChk () {
      // 외국인인 경우에, [외국인 상품설명서 언어선택] CheckBox가 선택되지 않은 경우
      let langChkManYn = false
      let checkIndices = this.fn_CheckindexArray()

      for (let i = 0; i < checkIndices.length; i++) {
        if (checkIndices[i].zaSno === '140') {
          langChkManYn = true
          break
        }
      }
      return langChkManYn
    },
    /************************************************************************************************
     * Function명  : fn_ChangeAddressType, fn_CheckAddressData
     * 설명        : 주소 관련 함수
     ************************************************************************************************/
    fn_ChangeAddressType () {
      console.log('주소 체인지 동작', this.lv_AddressType)
      // console.log('주소 체인지 동작', this.lv_AddressTypes.key)

      this.lv_Address = ''
      this. $nextTick(() => {

        if ( this.lv_AddressType === '1' && this.lv_AddressData[0] !== undefined ) {
          if ( this.lv_AddressData[0].zaPstcd !== '' ) {
            this.lv_Address = this.lv_AddressData[0].zaPstcd + ', '
          }
          if ( this.lv_AddressData[0].zaAddr !== '' ) {
            this.lv_Address = this.lv_Address + this.lv_AddressData[0].zaAddr
          }
        } else if ( this.lv_AddressType === '2' && this.lv_AddressData[1] !== undefined ) {
          if ( this.lv_AddressData[1].zaPstcd !== '' ) {
            this.lv_Address = this.lv_AddressData[1].zaPstcd + ', '
          }
          if ( this.lv_AddressData[1].zaAddr !== '' ) {
            this.lv_Address = this.lv_Address + this.lv_AddressData[1].zaAddr
          }
        }
        // if ( this.lv_AddressType[0].key === '1' && this.lv_AddressData[0] !== undefined ) {
        //   if ( this.lv_AddressData[0].zaPstcd !== '' ) {
        //     this.lv_Address = this.lv_AddressData[0].zaPstcd + ', '
        //   }
        //   if ( this.lv_AddressData[0].zaAddr !== '' ) {
        //     this.lv_Address = this.lv_Address + this.lv_AddressData[0].zaAddr
        //   }
        // } else if ( this.lv_AddressType[0].key === '2' && this.lv_AddressData[1] !== undefined ) {
        //   if ( this.lv_AddressData[1].zaPstcd !== '' ) {
        //     this.lv_Address = this.lv_AddressData[1].zaPstcd + ', '
        //   }
        //   if ( this.lv_AddressData[1].zaAddr !== '' ) {
        //     this.lv_Address = this.lv_Address + this.lv_AddressData[1].zaAddr
        //   }
        // }

        if ( this.lv_Address === undefined || this.lv_Address === '' ) {
          if ( this.lv_AddressType === '1' ) {
          // if ( this.lv_AddressType[0].key === '1' ) {
            this.lv_AlertMsg = '해당고객의 자택 주소가 없습니다'
          } else {
            this.lv_AlertMsg = '해당고객의 직장 주소가 없습니다'
          }
          this.fn_ShowErrorMsg()
          // this.lv_ShowErrorPopup = true
        }
      })
    },
    fn_CheckAddressData (type) {
      if ( this.lv_AddressData.length === 0 ) {
        this.lv_AlertMsg = '해당고객의 자택과 직장 주소가 없습니다'
        this.fn_ShowErrorMsg()
        // this.lv_ShowErrorPopup = true
        this.lv_Chk2 = false
      } else {
        if (type !== 1) {
          this.lv_Chk2 = !this.lv_Chk2
        }
      }
    },
    /************************************************************************************************
    * Function명  : fn_AlertPrcsClickHandler
    * 설명        : 알림 팝업(미리보기 권장) 미리보기 버튼 클릭시 이벤트
    ************************************************************************************************/
    fn_AlertPrcsClickHandler (prcs) {
      this.$refs.refShowRetryPrintFlag.close()
      this.fn_ConfirmPrcsPreSancNextPrc(prcs)
    },
    /************************************************************************************************
    * Function명  : fn_BtnNsEusPrcsClickHandler
    * 설명        : 기능 버튼 클릭 핸들러 통합
    ************************************************************************************************/
    fn_BtnNsEusPrcsClickHandler (targetBtn) {
      console.log('fn_BtnNsEusPrcsClickHandler: targetBtn: ' + targetBtn + ', key: ' + targetBtn.key + ', 1 chk: ' + this.lv_Chk1.includes('1'))
      if (targetBtn === 'btnNsEusPrcs' ) { // 신규전자서명 버튼
        // ASR240700780_갤럭시Z폴드6 전자서명 발행시 안내메시지 추가
        // 예시: 갤럭시 폴드6 모델명: SM-F956N
        let lv_Vm = this
        if (piCommonUtil.isObj(lv_Vm.lv_eltrnSignBtnDisableModelList)) {
          let modelList = lv_Vm.lv_eltrnSignBtnDisableModelList.filter((item) => item.envCntnt === lv_Vm.lv_rsltMNo)
          if(modelList.length > 0) {
            console.log(modelList[0].envCntnt)
            // lv_Vm.fn_BottomAlert('갤럭시 S24 바로열기 기능은 5월에 오픈 예정입니다.<br> 알림톡으로 발송해 주시기 바랍니다.')
            lv_Vm.lv_AlertMsg = modelList[0].smoUnitl + '에서 전자서명 사용이 불가합니다. <br>모바일청약을 활용해 주시기 바랍니다.'
            lv_Vm.fn_ShowErrorMsg()
            return
          }
        }
        this.isNsEusPrcs = true
      } else {
        this.isNsEusPrcs = false
      }

      if (targetBtn === 'btnNsEusPrcs' || targetBtn === 'btnP6') {
        this.fn_EusPrcsQtrHandler('P6') // 전자청약 전자서명
      } else if ( targetBtn === 'btnMobps' ) { // #2020모바일청약, 모바일청약 버튼추가 P10
        this.fn_EusPrcsQtrHandler('P10') 
      } 
      //2025.01.14 모청유도 팝업에서 모바일청약 클릭 삭제요청 (ASR250100111 1안 ->2안으로 변경  최주연프로요청) 
      /*else if ( targetBtn === 'btnMobpsP6' ) { // 2025.01 전자서명-> 모바일청약으로 유도하는 팝업에서 모바일 청약 선택한 CASE
        this.fn_EusPrcsQtrHandler('P10')
        this.lv_MoblOfrP6 = 'Y' // 2025.01 전자서명 -> 모청으로 유도한 Case를 분기하기 위해 변수 추가함 
      }*/
      else if ( targetBtn === 'btnPreview' ) { // 미리보기
        this.fn_EusPrcsQtrHandler('P2')
      } else if (targetBtn === 'btnPrint' ) { // 인쇄
        this.fn_EusPrcsQtrHandler('P1')
      } else if (targetBtn === 'btnP8' ) { // 전자청약(공인인증)
        this.fn_BtnP8clickHandler('P5')
      } else if (targetBtn === 'btnP3' ) { // 상품설계 플러스
        this.fn_PrcsEltrnService('P3')
      } else if (targetBtn === 'btnP4' ) { // E-mail 전송
        this.fn_PrcsEltrnService('P4')
      } else if ( targetBtn === 'nofacCnsl' ) { // [비대면화상상담시스템구축PJT]2021.06.22 추가
        this.fn_PrcsEltrnService('P44')
      } else if (targetBtn === 'cancel') {
        this.$emit('cancelPopup')
      } else if (targetBtn === 'btnMnlLms') { // 상품설명서전송 - LMS 전송
      // } else if (targetBtn.hasOwnProperty('key') && targetBtn.key === 'MnlLMS') { // 상품설명서전송 - LMS 전송
        // 2023.02 서비스안내장 선택 시 인쇄이외 모두 불가
        let chkSeqNo = 0
        let checkArray = this.fn_CheckindexArray()
        for (let i = 0; i < checkArray.length; i++ ) {
          let numRow = checkArray[i]
          let strPblSeqNo = numRow.zaSno
          chkSeqNo = parseInt(strPblSeqNo)

          if (chkSeqNo === 990) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '건강관리서비스 안내장 선택 해제 후 가능합니다.'
            this.fn_ShowErrorMsg()
            return
          }
        }

        if (this.currTab === '2') { // 청약서 탭인 경우
        // if (this.lv_Chk1.includes('1') === false) {
          this.lv_AlertMsg = '문자 전송은 상품설명서(제안용)를 선택해야만 가능합니다.</br></br>※ 문자 전송은 상품설명서(제안용)(전체)외 다른 선택 발행물은 포함되지 않습니다.'
          // this.lv_AlertMsg = 'LMS 전송은 상품설명서(제안용)를 선택해야만 가능합니다.\n\n※ LMS 전송은 상품설명서(제안용)(전체)외 다른 선택 발행물은 포함되지 않습니다.'
          // this.lv_ShowErrorPopup = true
          this.fn_ShowErrorMsg()
          return false
        }
        // NEXT 사랑On 추가
        if (this.currTab === '1' && checkArray.length === 0) {
          this.lv_AlertMsg = '체크된 아이템이 없습니다.'
          this.fn_ShowErrorMsg()
          return
        }
        // 고객이 ‘임시고객’이거나 ‘가상고객’인 경우
        if (!this.fn_CheckCustInfo('lms')) return

        // 변액판매자격 여부에 따른 메시지 처리
        if (this.MB_strSalesVprYN === 'N') {
          this.lv_AlertMsg = 'LMS 전송은 변액판매자격이 있는 경우에만 가능합니다.'
          this.fn_ShowErrorMsg()
          return false
        }
        // End of
        
        // LMS 전송
        let lv_Vm = this
        let parentsViewId = 'MSPPI250P'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
        let _rltnrInfo = this.tmodel.rltnrInfo
        let _rltnrInfo_11 = {}
        let has_rltnrInfo_11Info = false
        let chnlCstgrNm = ''
        let dob = ''
        if (!piCommonUtil.isEmpty(_rltnrInfo)) {
          has_rltnrInfo_11Info = true
          // 계약자 정보 추출
          _rltnrInfo_11 = lv_Vm.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]
          // console.log('fn_BtnNsEusPrcsClickHandler: _rltnrInfo_11 설정완')
          // 계약자 고객구분코드에서 코드명 추출
          switch (_rltnrInfo_11.zaChnlCustScCd) {
            case '01': chnlCstgrNm = '가망고객'
              break
            case '02': chnlCstgrNm = '임시고객'
              break
            case '03': chnlCstgrNm = '본인모집고객'
              break
            case '04': chnlCstgrNm = '타인모집고객'
              break
            case '05': chnlCstgrNm = '관심고객'
              break
          }
          // 계약자 주민번호에서 생년월일 추출
          dob = lv_Vm.$bizUtil.addBirthFrontYear((_rltnrInfo_11.zzcustRrnId).substring(0, 6))
          // console.log('fn_BtnNsEusPrcsClickHandler: 계약자 주민번호에서 생년월일 추출')
        }

        let custList = [{
          celno: lv_Vm.lv_Celno,
          chnlCstgrId: has_rltnrInfo_11Info ? _rltnrInfo_11.zaChnlCustScCd : ' ',
          chnlCstgrNm: chnlCstgrNm,
          chnlCustId: has_rltnrInfo_11Info ? _rltnrInfo_11.zaAgtFileId : ' ',
          custEmailAddr: lv_Vm.MB_EmailAddr,
          custNm: has_rltnrInfo_11Info ? _rltnrInfo_11.zaInsrdNm : ' ',
          custSxdsNm: has_rltnrInfo_11Info ? (_rltnrInfo_11.genderCd === '1' ? '여성' : '남성') : ' ',
          dob: dob.substr(0, 8),
          rntTuchYmd: ' '
        }]
        // console.log('fn_BtnNsEusPrcsClickHandler: custList 설정완')

        lv_Vm.lv_TargetType = 's'
        lv_Vm.custListObj = [
          {targetType: lv_Vm.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
        ]
        // console.log('fn_BtnNsEusPrcsClickHandler: lv_Vm.custListObj 설정완')

        // MnlLMS일 경우 수신고객목록 팝업 실행
        this.$nextTick(() => {
          // console.log('fn_BtnNsEusPrcsClickHandler: lv_Vm.lv_IsShowTSSSA016P = true')
          // lv_Vm.lv_IsShowTSSSA016P = true
          lv_Vm.fn_OpenMSPPI251P()
          let today = new Date()
          lv_Vm.zaMktCnsntInqrYmd = (today.getMonth() + 1) + '월 ' + today.getDate() + '일 ' +
           today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
        })
      } else if (targetBtn === 'btnEmail') { // 상품설명서전송 - 이메일 전송
      // } else if (targetBtn.hasOwnProperty('key') && targetBtn.key === 'EMAIL') { // 상품설명서전송 - 이메일 전송
        // 2023.02 서비스안내장 선택 시 인쇄이외 모두 불가
        let chkSeqNo = 0
        let checkArray = this.fn_CheckindexArray()
        for (let i = 0; i < checkArray.length; i++ ) {
          let numRow = checkArray[i]
          let strPblSeqNo = numRow.zaSno
          chkSeqNo = parseInt(strPblSeqNo)

          if (chkSeqNo === 990) {
            this.lv_AlertMsg = '건강관리서비스 안내장 선택 해제 후 가능합니다.'
            // this.lv_ShowErrorPopup = true
            this.fn_ShowErrorMsg()
            return
          }
        }
        this.fn_PrcsEltrnService('P4')
      }
    },
    /************************************************************************************************
     * Function명  : fn_ChkEltrnSignPsbFofOrgYn
     * 설명        : 전자서명 가능 지점 여부 체크
     ************************************************************************************************/
    fn_ChkEltrnSignPsbFofOrgYn () {
      let eltrnSignPsbFofOrgCd = ''
      let onpstFofOrgNo = (this.headerVO.onpstFofOrgNo === '' ) ? '' : this.headerVO.onpstFofOrgNo
      let userEno = ( this.headerVO.userId === '' ) ? '' : this.headerVO.userId
      let userScCd = ( this.headerVO.userScCd === '' ) ? '' : this.headerVO.userScCd

      // 임직원이면 서명 가능
      if (userEno.charAt(0) === '1') {
        return true
        // 설계사 인 경우
      } else if (userScCd === '11') {
        if (eltrnSignPsbFofOrgCd.indexOf('|') === -1) {
          return true
        } else {
          if (eltrnSignPsbFofOrgCd.indexOf(onpstFofOrgNo) > -1) {
            return true
          } else {
            this.lv_AlertMsg = '전사오픈 이후에 전자서명이 가능합니다.'
            // this.lv_AlertMsg = '8월 16일 전사오픈 이후에 전자서명이 가능합니다.'
            // this.lv_ShowErrorPopup = true
            this.fn_ShowErrorMsg()
            return false
          }
        }
      } else {
        // this.lv_ShowErrorPopup = true
        this.lv_AlertMsg = '전사오픈 이후에 전자서명이 가능합니다.'
        // this.lv_AlertMsg = '8월 16일 전사오픈 이후에 전자서명이 가능합니다.'
        this.fn_ShowErrorMsg()
        return false
      }
    },
    // ====================================== AEUS 관련 함수 start ====================================== //
    /************************************************************************************************
    * Function명  : fn_EusPrcsQtrHandler
    * 설명        : AEUS 분기용 : 현재 기능처리 저장
    ************************************************************************************************/
    fn_EusPrcsQtrHandler ( currentAction ) {
      this.STR_currentAction = currentAction

      console.log('MOSE_PIPE52100P', '------------ AEUS 분기용 : 현재 기능처리 저장 ------------')
      // console.log('MOSE_PIPE52100P', 'currentAction : ' + currentAction)

      // 청약서일 경우에만 AEUS대상여부를 판멸합니다
      let checkIndices = this.fn_CheckindexArray()
      if ( checkIndices !== undefined && checkIndices.length > 0 ) {
        // console.log('checkIndices', checkIndices)
      } else {
        this.lv_AlertMsg = '체크된 아이템이 없습니다.'
        // this.lv_ShowErrorPopup = true
        this.fn_ShowErrorMsg()
        return
      }

      // ASR210101254_청약서 미리보기 버튼 블로킹 요청 _2021.02.19 PJO
      // ASR211000637_청약서 미리보기 버튼 블로킹 해제 _2021.11.09 이영기
      // if ( currentAction === 'P2' && this.lv_Chk1.includes('2')) {
      //   this.lv_ShowErrorPopup = true
      //   this.lv_AlertMsg = '청약서류는 미리보기를 하실 수 없습니다.'
      //   return
      // }

      // pdf가 저장되는 미리보기(P2), 상품설계플러스(P3), 전자서명(P6) 은 제안서와 청약서 동시 선택 불가.
      // 인쇄(P1)/미리보기(P2)/상품설계플러스(P3)/E-mail전송(P4)/전자서명(P6)
      // if ( currentAction === 'P2' || currentAction === 'P3' || currentAction === 'P6') {
      // #2020모바일청약, 모바일청약을 조건에 추가
      // 인쇄(P1)/미리보기(P2)/상품설계플러스(P3)/E-mail전송(P4)/전자서명(P6)/모바일청약(P10)
      if ( currentAction === 'P2' || currentAction === 'P3' || currentAction === 'P6' || currentAction === 'P10') {
        if ( this.fn_CountSelectItem() > 1 && this.lv_Chk1.includes('1')) {
          this.lv_AlertMsg = '상품설명서(제안용)와 청약서를 함께 진행할수 없습니다.'
          // this.lv_ShowErrorPopup = true
          this.fn_ShowErrorMsg()
          return
        }

        // 서비스안내장 선택 시 LMS 이메일전송 이외 모두 가능함으로 변경 (2023.03 이동욱프로)
        let chkSeqNo = 0
        let checkArray = this.fn_CheckindexArray()
        for (let i = 0; i < checkArray.length; i++ ) {
          let numRow = checkArray[i]
          let strPblSeqNo = numRow.zaSno
          chkSeqNo = parseInt(strPblSeqNo)

          if (chkSeqNo === 990 && currentAction === 'P3') {
          // if (chkSeqNo === 990 && currentAction !== 'P2') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '건강관리서비스 안내장 선택 해제 후 가능합니다.'
            this.fn_ShowErrorMsg()
            return
          }
        }
      }

      let numPblSeqNo = 0
      for (let i = 0; i < checkIndices.length; i++ ) {
        let numRow = checkIndices[i]
        let strPblSeqNo = numRow.zaSno
        numPblSeqNo = parseInt(strPblSeqNo)
        // console.log('numPblSeqNo', numPblSeqNo)
        if (numPblSeqNo === 101) {
          break
        }
      }

      if (numPblSeqNo === 101) {
        let inputJson = {
          pmodel: this.pmodel,
          tmodel: this.tmodel
        }

        inputJson = { entplId: this.MB_strPlanlId }

        // console.log('--------------inputJson : ', inputJson)

        // 역슬래시 제거
        let reqVal = JSON.stringify(inputJson)
        reqVal = reqVal.replace(/\\/gi, '')

        let pParams = {
          cmndSrvcNm: 'selButtonVisable', // interfaceInfo.reqMthNm,
          sdrvDtVal: reqVal
        }
        window.vue.getStore('progress').dispatch('SHOW')
        // console.log('[fn_EusPrcsQtrHandler] UF1PQL00316 ====> ', pParams)
        piCommonUtil.invoke('UF1PQL00316', pParams, this.fn_SelEusResultHandler, null, this.fn_SelEusResultHandler, this, this)
      } else {
        this.fn_PrcsEltrnService(currentAction)
      }
    },
    /************************************************************************************
     * 함수 : fn_SelEusResultHandler
     * 설명 : AEUS대상여부 조회 결과 처리
     ************************************************************************************/
    fn_SelEusResultHandler (response) {
      // window.vue.getStore('progress').dispatch('HIDE')
      console.log('// AEUS대상여부 조회 결과', response )

      if (response === null) {
        // this.lv_ShowErrorPopup = true
        this.lv_AlertMsg = 'AEUS 정보조회 중 오류가 발생했습니다.'
        this.fn_ShowErrorMsg()
        return
      }

      let result = JSON.parse(response.sdrvDtVal)
      let eusObjYn = '' // AEUS대상여부
      let zPIEntplEusInfoDto = result.zPIEntplEusInfoDto

      eusObjYn = zPIEntplEusInfoDto.eusObjYn

      console.log('MOSE_PIPE52100P', 'eusObjYn : ' + eusObjYn)

      // AEUS대상이면, AEUS 고지입력정보를 조회한다.
      if ( eusObjYn === 'Y' ) {
        let abdapolicyList = this.abdapolicy // Policy 정보 Array
        let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
        let planId = abdapolicy.zaPlanId

        let inputJson = { ZA_PLAN_ID: planId }

        let reqVal = JSON.stringify(inputJson)
        reqVal = reqVal.replace(/\\/gi, '')

        let pParams = {
          cmndSrvcNm: 'selButtonVisable', // interfaceInfo.reqMthNm,
          sdrvDtVal: reqVal
        }

        piCommonUtil.invoke('UF1PQL00315', pParams, this.fn_SelEPD28ResultHandler, null, this.fn_SelEPD28ResultHandler, this, this)
        // AEUS대상이 아니면, 기존 프로세스를 실행한다.
      } else {
        this.fn_PrcsEltrnService(this.STR_currentAction)
      }
    },
    /************************************************************************************
     * 함수 : fn_SelEusResultHandler
     * 설명 : AEUS 고지입력정보 조회 요청 응답 처리
     ************************************************************************************/
    //
    fn_SelEPD28ResultHandler ( response ) {
      if ( response !== null ) {
        let eusId = '' // EUS시스템에서 생성된 EUS고지및심사결과의 Primary Key
        let jdgRsltDfrncYn01 = '' // (주피)전산심사결과와 AEUS심사결과의 상이 여부
        let jdgRsltDfrncYn02 = '' // (종피)전산심사결과와 AEUS심사결과의 상이 여부

        let result = JSON.parse(response.sdrvDtVal)

        eusId = result.hasOwnProperty('ZA_EUS_ID') ? result.ZA_EUS_ID.trim() : ''
        jdgRsltDfrncYn01 = result.hasOwnProperty('ZA_JDG_RSLT_DFRNC_YN_01') ? result.ZA_JDG_RSLT_DFRNC_YN_01.trim() : ''
        jdgRsltDfrncYn02 = result.hasOwnProperty('ZA_JDG_RSLT_DFRNC_YN_02') ? result.ZA_JDG_RSLT_DFRNC_YN_02.trim() : ''

        // console.log('MOSE_PIPE52100P', 'jdgRsltDfrncYn01 : ' + jdgRsltDfrncYn01)
        // console.log('MOSE_PIPE52100P', 'jdgRsltDfrncYn02 : ' + jdgRsltDfrncYn02)

        if ( eusId !== '' ) {
          if (jdgRsltDfrncYn01 === 'Y' || jdgRsltDfrncYn02 === 'Y') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'EUS 결과가 상이합니다. 재심사 바랍니다.'
            this.fn_ShowErrorMsg()
            // return
          } else {
            this.fn_PrcsEltrnService(this.STR_currentAction)
          }
        } else {
          this.fn_PrcsEltrnService(this.STR_currentAction)
        }
      } else {
        this.fn_PrcsEltrnService(this.STR_currentAction)
      }
    },
    // ====================================== AEUS 관련 함수 end ====================================== //
    /************************************************************************************************
    * Function명  : fn_ChkViewerOpen
    * 설명        : 미리보기와 전자서명 서비스 호출 전 다른 뷰어가 활성화 되어있는지 체크
    ************************************************************************************************/
    fn_ChkViewerOpen (a_sFnCd) {
      let ane = ''
      if (ane) {
        let isViewerOpen = ''

        // AS-IS viewer ,신규 Viewer 둘다 실행여부 체크
        isViewerOpen = ''
        if (isViewerOpen !== 'NOT USE') {
          isViewerOpen = ''
        }

        if (isViewerOpen === 'NOT USE') {
          // this.lv_ShowErrorPopup = true
          this.lv_AlertMsg = 'ErrorID.0013'
          this.fn_ShowErrorMsg()
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    /************************************************************************************************
    * Function명  : fn_ChkValid
    * 설명        : Validation 체크
    ************************************************************************************************/
    fn_ChkValid (opName) {
      // 인쇄 || 미리보기 || 전자청약 || 전자서명
      // if (opName === 'P1' || opName === 'P2' || opName === 'P5' || opName === 'P6' ) {
      // #2020모바일청약, 모바일청약을 조건에 추가
      // 인쇄 || 미리보기 || 전자청약 || 전자서명 || 모바일청약 || 비대면화상상담(P44)
      if (opName === 'P1' || opName === 'P2' || opName === 'P5' || opName === 'P6' || opName === 'P10' || opName === 'P44') {
        if (this.fn_ForeignerLangChk()) { // 발행목록에 상품설명서-외국인이 있을 때
          if (!this.lv_LangScCdArray || this.lv_LangScCdArray.length === 0) { // 체크한 언어값이 없으면
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '외국인 전용 상품설명서의 언어를 선택하시기 바랍니다.'
            this.fn_ShowErrorMsg()
            return false
          }
        }
      }

      // 인쇄여부, 이메일 발송여부를 물어보는 경우이기 때문에 이벤트를 지나가지 않기 위해 여기서 실행
      // let fnConfirm = function (event) {
      //   if (event.detail == AlertMsg.OK) {
      //     if (opName == 'P1') { fn_ProcPrint(opName, false)}
      //   }
      // }
      //* **********************************************************************************************************************************************
      //* **********************************************************************************************************************************************
      // 사용자구분코드 = Header정보에서 가져옴(TBD2)
      let userScCd = '12'
      let checkIndices = this.fn_CheckindexArray()

      switch (opName) {
        case '발행목록 조회':
          break

        case 'P2': // 미리보기
          if (!checkIndices || checkIndices.length === 0) { // grid.RowCnt() > 0인 상태에서 모든 열이 unchecked
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '미리보기 대상이 선택되지 않았습니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          break

        case 'P1': // 인쇄
          if (!checkIndices || checkIndices.length === 0) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '발행대상이 선택되지 않았습니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          // 가입설계 전체선택 & 가설발행여부가 Y일때
          if ((this.lv_Chk1.includes('1') || this.currTab === '1') && this.MB_strHptsPblYn === 'Y') {
          // if (this.lv_Chk1.includes('1') && this.MB_strHptsPblYn === 'Y') {
            // this.lv_ShowRetryPrintFlag = true
            if (this.lv_ChkCnt1 === this.lv_ItemLists_1.length) {
              this.lv_ShowRetryPrintMsg = '<span style="">상품설명서(제안용)를 「<span style="color: #0066CC; font-weight: bold; font-size: 1.2rem;">미리보기</span>」, 혹은 「<span style="color: #0066CC; font-weight: bold; font-size: 1.2rem;">선택출력</span>」하면 종이낭비를 줄이고 소중한 자연환경을 보호할 수 있습니다.</br></br>출력하시겠습니까?</span>'
            } else {
              this.lv_ShowRetryPrintMsg = '<span style="">상품설명서(제안용)를 「<span style="color: #0066CC; font-weight: bold; font-size: 1.2rem;">미리보기</span>」하면 종이낭비를 줄이고 소중한 자연환경을 보호할 수 있습니다.</br></br>출력하시겠습니까?</span>'
            }
            // this.lv_ShowRetryPrintMsg = '본 설계는 이미 상품설명서(제안용)가 발행된 적이 있습니다.</br>다시 출력하시겠습니까?</br></br>(무분별한 발행은 비용낭비를 초래합니다.)'
            // this.lv_ShowRetryPrintMsg = '본 설계는 이미 상품설명서(제안용)가 발행된 적이 있습니다.\n다시 출력하시겠습니까?\n\n(무분별한 발행은 비용낭비를 초래합니다.)'
            this.$refs.refShowRetryPrintFlag.open()
            return false
          }
          break

        case 'P3': // PDF저장 - 상품설계 플러스
          if (this.lv_Item_Lists[0].checkField === 'N') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'PDF 저장은 상품설명서(제안용)를 선택해야만 가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          // 사용자가 임직원이 아니면서, 고객이 ‘가상고객’인 경우
          if (this.MB_vCustYn || (userScCd !== '12' && userScCd !== '14' && userScCd !== '90' && userScCd !== '91')) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '가상고객은 PDF저장기능을 지원하지 않습니다.' // PDF 저장은 가입설계서를 선택해야만 가능합니다.(PQLALT00043)
            this.fn_ShowErrorMsg()
            return false
          }
          if (this.MB_strCnsltImsiYn === 'Y') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'PDF 저장은 등록설계사만 전송 가능합니다.' // PDF 저장은 가입설계서를 선택해야만 가능합니다.(PQLALT00039)
            this.fn_ShowErrorMsg()
            return false
          }
          if (this.MB_strSalesVprYN === 'N') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'PDF 저장은 변액판매자격이 있는 경우에만 가능합니다.' // PDF 저장은 가입설계서를 선택해야만 가능합니다.(PQLALT00044)
            this.fn_ShowErrorMsg()
            return false
          }
          return true

        case 'P4': // 이메일전송
          if (this.currTab === '2') { // 청약서 탭인 경우
          // if (this.lv_Chk1.includes('1') === false) {
            // this.lv_ShowErrorPopup = true
            // this.lv_AlertMsg = 'E-mail 전송은 상품설명서(제안용)를 선택해야만 가능합니다.\n\n※ E-Mail 전송은 상품설명서(제안용)(전체)외 다른 선택 발행물은 포함되지 않습니다'
            this.lv_AlertMsg = 'E-mail 전송은 상품설명서(제안용)를 선택해야만 가능합니다.</br></br>※ E-Mail 전송은 상품설명서(제안용)(전체)외 다른 선택 발행물은 포함되지 않습니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          // NEXT 사랑On : 이메일 전송 시 선택된 리스트 없는 경우 알럿 메시지 노출
          if (this.currTab === '1') {
            let checkIndices = this.fn_CheckindexArray()
            if (checkIndices.length === 0) {
              this.lv_AlertMsg = '체크된 아이템이 없습니다.'
              this.fn_ShowErrorMsg()
              return false
            }
          }
          // 고객이 ‘임시고객’이거나 ‘가상고객’인 경우
          // let tmpZzilsITCLVl = ''
          // let abdapolicy = Gaf.getGafObjByRefId('POLICY')
          // if ( abdapolicy !== undefined && piCommonUtil.isObj(abdapolicy) &&
          // abdapolicy[0].abdapolpr !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr) &&
          // abdapolicy[0].abdapolpr[0].aldapolpr !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr[0].aldapolpr) &&
          // abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl)) {
          //   tmpZzilsITCLVl = abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl
          // }
          // if (this.MB_vCustYn || (this.MB_strTmpCustYn === 'Y' && tmpZzilsITCLVl === '04')) {
          //   // this.lv_ShowErrorPopup = true
          //   // this.lv_AlertMsg = '임시고객, 가상고객은\nE-mail전송기능을 지원하지 않습니다.\n임시고객은 고객카드에서 주민번호 뒷6자리를 000000으로 입력한 고객을 의미합니다.'
          //   this.lv_AlertMsg = '임시고객, 가상고객은 E-mail전송기능을 지원하지 않습니다.</br>임시고객은 고객카드에서 주민번호 뒷6자리를 000000으로 입력한 고객을 의미합니다.'
          //   this.fn_ShowErrorMsg()
          //   return false
          // }
          if (!this.fn_CheckCustInfo('email')) return false
          if (this.MB_strCnsltImsiYn === 'Y') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'E-mail 전송 등록설계사만 전송 가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          if (this.MB_strSalesVprYN === 'N') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'E-mail 전송은 변액판매자격이 있는 경우에만 가능합니다.'
            // this.lv_AlertMsg = 'E- mail 전송 변액판매자격이 있는 경우에만 가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          if (this.MB_EmailAddr === '') {
            // this.lv_ShowErrorPopup = true
            // this.lv_AlertMsg = '고객(계약자)의 이메일 주소가 등록되지 않았습니다.'
            // this.fn_ShowErrorMsg()
            this.lv_RegEmailAddrMsg = '고객(계약자)의 이메일 주소가 등록되지 않았습니다.</br>이메일 주소를 등록하시겠습니까?'
            this.$refs.refRegEmailAddr.open()
            return false
          }
          return true

        case 'P5': // 전자청약
          if (this.lv_ChkCnt2 !== this.lv_ItemLists.length) {
          // if (this.lv_SelectItems.length !== this.lv_ItemLists.length) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '전자청약은 모든 청약서를 선택해야만 가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          break

        case 'P6': // 전자서명
        case 'P10': // #2020모바일청약, 모바일청약을 조건에 추가
          if (this.lv_ChkCnt2 !== this.lv_ItemLists.length) {
          // if (this.lv_SelectItems.length !== this.lv_ItemLists.length) {
            if (this.fn_chkPbl990()) {
              // this.lv_ShowErrorPopup = true
              this.lv_AlertMsg = '청약서 및 기타서류 모두 체크후 전자서명 진행바랍니다.'
              this.fn_ShowErrorMsg()
              return false
            }
          }
          if (this.lv_Chk1.includes('1')) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '전자서명은 상품설명서(제안용) 선택이 불가능합니다.' //  E-mail 전송은 가입설계서를 선택해야만 가능합니다.
            this.fn_ShowErrorMsg()
            return false
          }
          break
        case 'P44': // [비대면화상상담시스템구축PJT]2021.06.22
          if (this.currTab !== '1') {
          // if (this.lv_Chk1.includes('1') === false) {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '화상상담 자료저장은 상품설명서(제안용)를 선택해야만 가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          if (this.currTab === '1') {
            let checkIndices = this.fn_CheckindexArray()
            if (checkIndices.length === 0) {
              this.lv_AlertMsg = '체크된 아이템이 없습니다.'
              this.fn_ShowErrorMsg()
              return false
            }
          }
          // NEXT 사랑On : 임시고객 체크 로직
          if (_.isEmpty(this.MB_contrChnlCustId)) {
            this.lv_AlertMsg = '임시고객은 화상상담 자료저장 불가능합니다.'
            this.fn_ShowErrorMsg()
            return false
          }
          break
        default:
          break
      }

      return true
    },
    /************************************************************************************
     * 함수 : fn_chkPbl990
     * 서비스안내장 포함여부 체크
     * lv_ItemLists에는 서비스 안내장이있고, lv_SelectItems에는 서비스안내장이 없는 경우 전자서명이 가능하여야하는데 무조건 -1을 할 수 없는게 안내장 이외의 것을 빼고도 할 수 있으니까
     * lv_ItemLists에 990인 서비스안내장이있는 경우 lv_ItemLists에서990을 제외한 length와 lv_SelectItems의 length가 다른 경우를 체크하도록 로직 구현
     ************************************************************************************/
    fn_chkPbl990 () {
      let retrunValue = false // 리스트 length가 다른 이유가 서비스안내장때문이 아니라면 TRUE / 서비스안내장때문이라면 FALSE
      let itemListArray = this.lv_ItemLists // 조회된리스트
      let newItemListArray = [] // 990을제외한리스트
      // [2023-05-23] 추가
      let selYn = false // 서비스안내장이 선택한 리스트에 포함되어있는지
      let itemYn = false // 서비스안내장이 발행 리스트에 포함되어있는지
      
      // 조회된 리스트에 서비스안내장 포함여부 체크
      for (let i = 0; i < itemListArray.length; i++ ) {
        let numRow = itemListArray[i]
        let strPblSeqNo = numRow.zaSno
        if (strPblSeqNo !== '990') {
          newItemListArray.push(itemListArray[i])
        }
      }

      // [2023-05-23] 추가
      // 건강관리 안내장 선택 여부 체크(청약서)
      let chkList = this.lv_ItemLists.filter(chkItem => chkItem.checked)
      if (!_.isEmpty(chkList) && chkList.length > 0) {
        for (let i = 0; i < chkList.length; ++i) {
          if (chkList[i].zaPblCntnt === '건강관리서비스 안내장') {
            selYn = true // 안내장이 선택됨
            break
          }
        }
      }
      // 조회된 리스트에 서비스안내장 포함여부 체크
      for (let i = 0; i < itemListArray.length; i++ ) {
        let numRow = itemListArray[i]
        let strPblSeqNo = numRow.zaSno
        if (strPblSeqNo === '990') {
          itemYn = true
          break
        }
      }

      // case1. 건강관리 안내장이 발행목록에 있을때
      if (itemYn) {
        if (selYn) { // 건강관리안내장이 선택됨
          if (itemListArray.length !== chkList.length) { // 발행대상목록과 선택된목록의 수가 다르면
            retrunValue = true
          }
        } else { // 건강관리안내장이 선택 안됨
          if (newItemListArray.length !== chkList.length) { // 건강관리안내장을제외한 목록과 선택한 목록의 수가 다르면
            retrunValue = true
          }
        }
      // case2. 건강관리 안내장이 발행목록에 없을때
      } else {
        if (itemListArray.length !== chkList.length) { // 화면에조회된 목록과 선택된 목록의 수가 다르면
          retrunValue = true
        }
      }
      // if (newItemListArray.length !== this.lv_ChkCnt2) {
      // // if (newItemListArray.length !== this.lv_SelectItems.length) {
      //   retrunValue = true
      // }

      return retrunValue
    },
    /************************************************************************************************
     * Function명  : fn_PrcsEltrnService
     * 설명        : 발행 관련 서비스 호출
     ************************************************************************************************/
    fn_PrcsEltrnService (currentAction) {
      window.vue.getStore('progress').dispatch('HIDE')
      this.STR_currentAction = currentAction

      // 전자서명 버튼 클릭시 temp/pdf 디렉토리 삭제
      // 전자서명 버튼 클릭시 오픈 전 전자서명 가능 지점 인지 여부 체크
      // #2020모바일청약, 모바일청약을 조건에 추가
      // if (currentAction === 'P6' && !this.fn_ChkEltrnSignPsbFofOrgYn()) {
      if ((currentAction === 'P6' || currentAction === 'P10') && !this.fn_ChkEltrnSignPsbFofOrgYn()) {
        return
      }

      // 미리보기 나 전자서명 시에 다른 뷰어가 열려있는지 체크
      // #2020모바일청약, 모바일청약을 조건에 추가
      // if ((currentAction === 'P2' || currentAction === 'P6') && !this.fn_ChkViewerOpen(currentAction)) {
      if ((currentAction === 'P2' || currentAction === 'P6' || currentAction === 'P10') && !this.fn_ChkViewerOpen(currentAction)) {
        return
      }

      /** *************************To-Be****************************/
      let dp = this.fn_CheckindexArray()
      let checkA = false
      let checkB = false
      let i = 0
      for ( i = 0; i < dp.length; i++) {
        if (dp[i].title01 === '상설(제안용)' || dp[i].title01 === '상품설명서(제안용)') {
          checkA = true
        }
        if (dp[i].title01 === '청약서' || dp[i].title01 === '별첨') {
          checkB = true
        }
      }

      // pdf가 저장되는 미리보기(P2), 상품설계플러스(P3), 전자서명(P6) 은 제안서와 청약서 동시 선택 불가.
      // 인쇄(P1)/미리보기(P2)/상품설계플러스(P3)/E-mail전송(P4)/전자서명(P6)
      // #2020모바일청약, 모바일청약을 조건에 추가
      // if (currentAction === 'P2' || currentAction === 'P3' || currentAction === 'P6') {
      // 인쇄(P1)/미리보기(P2)/상품설계플러스(P3)/E-mail전송(P4)/전자서명(P6)/모바일청약(P10)/비대면화상상담(P44)
      if (currentAction === 'P2' || currentAction === 'P3' || currentAction === 'P6' || currentAction === 'P10' || currentAction === 'P44') {
        if ( this.fn_CountSelectItem() > 1 && this.lv_Chk1.includes('1')) {
          // this.lv_ShowErrorPopup = true
          this.lv_AlertMsg = '상품설명서(제안용)와 청약서를 함께 진행할수 없습니다.'
          this.fn_ShowErrorMsg()
          return
        }

        // 2023.02 서비스안내장 선택 시 LMS 이메일전송 이외 모두 가능함으로 변경 (2023.03 이동욱프로)
        let chkSeqNo = 0
        let checkArray = this.fn_CheckindexArray()
        for (let i = 0; i < checkArray.length; i++ ) {
          let numRow = checkArray[i]
          let strPblSeqNo = numRow.zaSno
          chkSeqNo = parseInt(strPblSeqNo)

          // if (chkSeqNo === 990 && currentAction !== 'P2') {
          if (chkSeqNo === 990 && currentAction === 'P3') {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = '건강관리서비스 안내장 선택 해제 후 가능합니다.'
            this.fn_ShowErrorMsg()
            return
          }
        }
      }

      if (currentAction !== 'P1' ) {
        if (checkA) {
          this.sandType = '가설'
          this.pdfPath = 'file.path.entpl'
        }
        if (checkB) {
          this.sandType = '청약'
          this.pdfPath = 'file.path.ofr.bs'
        }
      }

      // confirm 기능으로 인해 재인쇄/이메일 fn_ChkValid 체크하면서 바로 서비스 태움
      if (!this.fn_ChkValid(this.STR_currentAction )) {
        return
      }

      if (this.STR_currentAction === 'P1') { // 인쇄
        this.fn_ProcPrint( this.STR_currentAction )
      // #2020모바일청약, 모바일청약을 조건에 추가
      // } else if (this.STR_currentAction === 'P6') { // 전자서명
      } else if (this.STR_currentAction === 'P6' || this.STR_currentAction === 'P10') { // 전자서명, 모바일청약
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
        /*2025.01.14 모청유도 팝업에서 모바일청약 클릭 삭제요청 (ASR250100111 1안 ->2안으로 변경  최주연프로요청) 
        if(this.lv_MoblOfrP6 ==='Y'){ //전자서명부터 유도해서 온case
          this.fn_ConfirmPrcsEntplEltrnSign() // 메세지팝업없이 모바일청약호출  
        } */
        // LMS 없어짐
        // this.popupLmsClick(this.STR_currentAction) // LMS모바일약관 선택 팝업 호출
      } else if (this.STR_currentAction === 'P2') { // 미리보기
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
      } else if (this.STR_currentAction === 'P3') { // 상품설계 플러스
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
      } else if (this.STR_currentAction === 'P4') { // 이메일 발송
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
      } else if (this.STR_currentAction === 'MnlLMS') { // LMS 발송
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
      } else if (this.STR_currentAction === 'P44') { // [비대면화상상담시스템구축PJT]2021.06.22
        this.fn_PrcsEntplEltrnSign(this.STR_currentAction )
      }
    },
    /*************************************************************************
     * 함수 : fn_PrcsEntplEltrnSign
     * 설명 : 미리보기 / 전자서명 / E-mail 전송 전처리
     * param : opName - current Action
     *************************************************************************/
    fn_PrcsEntplEltrnSign (opName) {
      let strMsg = ''

      // 전역변수에 거래 타입 저장
      this.checkIntervalType = opName

      // 전자서명여부
      let entplSignYN = ''

      if (opName === 'P2') {
        strMsg = '미리보기를 진행하시겠습니까?'
      } else if (opName === 'P6') {
        //2025.01 모바일청약UI변경 모바일청약이 가능한데 전자서명 클릭한 경우 메세지분기처리
        if(this.lv_isMobileBtn===true){
          strMsg = '모바일청약으로 진행 시</br>더 쉽고 빠르게 계약체결이 가능합니다.</br>그래도 전자서명으로 진행하시겠습니까?'
        }else{
          strMsg = '전자서명을 진행하시겠습니까?'
        }
        
        if ( this.fn_AgeCheckInsrd21() ) {
            // strMsg += '\n미성년자 주피보험자인 경우, 계약자는 친권인만 가능'
            strMsg = '계약자가 친권인이 아닌 경우 전자서명이 불가합니다.</br></br>계약자가 주피보험자의 친권인이 맞습니까?</br>※ 아닌경우 "아니오" 선택후 모바일(또는 지류)로</br>계약 진행해주세요'// (ASR210600884_2021.07.20_PJO)
            // strMsg = '계약자가 친권인이 아닌 경우 전자서명이 불가합니다.\n\n계약자가 주피보험자의 친권인이 맞습니까?\n※ 아닌경우 "아니오" 선택후 모바일(또는 지류)로\n계약 진행해주세요'// (ASR210600884_2021.07.20_PJO)
         }
        entplSignYN = 'Y'
        this.lv_ReTryYn = 'N'
      // #2020모바일청약, 모바일청약을 조건에 추가
      } else if (opName === 'P10') {
        strMsg = '모바일청약을 진행하시겠습니까?'
        // 모바일청약은 주피보험자, 계약자도 미성년자가 가능하므로 아래 문구 삭제 : 21.01.06 / 최주연 프로
        // if ( this.fn_AgeCheckInsrd21() ) {
        //   strMsg += '\n미성년자 주피보험자인 경우, 계약자는 친권인만 가능'
        // }
        entplSignYN = 'Y'
      } else if (opName === 'P3') {
        strMsg = '상품설계플러스에 저장하시겠습니까?</br>저장을 선택하시면, </br>일주일간 상품설계플러스에서 빠르게 다시 보실 수 있습니다.'
        // strMsg = '상품설계플러스에 저장하시겠습니까?\n저장을 선택하시면, \n일주일간 상품설계플러스에서 빠르게 다시 보실 수 있습니다.'
      } else if (opName === 'P4') {
        strMsg = 'E-mail을 전송하시겠습니까?'
      } else if (opName === 'P44') { //  [비대면화상상담시스템구축PJT]2021.06.22
        // strMsg = '화상상담자료 목록으로 저장하시겠습니까?'
        this.fn_ConfirmPrcsEntplEltrnSign()
        return
      }

      this.lv_PrcsEntplEltrnSignAlertMsg = strMsg
      this.EntplEltrnSign_opName = opName
      this.EntplEltrnSign_entplSignYN = entplSignYN
      // this.lv_PrcsEntplEltrnSignAlertFlag = true
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        this.fn_ConfirmPrcsEntplEltrnSign() // 메세지팝업없이 발행후 영수증번호 GA table에 저장
      }else{
        this.$refs.refPrcsEntplEltrnSignAlertFlag.open()
      }

      //2025.01 모바일청약UI변경 모바일청약이 가능한데 전자서명 클릭한 경우 
      //2025.01.14 모바일청약 유도화면 2안으로 변경, 예 아니오 그대로 유지. 메세지만 변경 
      /*if(this.lv_isEltrnBtn === true && this.lv_isMobileBtn===true && opName === 'P6'){
          this.$refs.refPrcsEntplEltrnSignAlertFlagNEW.open()
      }else{  
        this.$refs.refPrcsEntplEltrnSignAlertFlag.open()
      }*/

    },
    /*************************************************************************
     * 함수 : fn_ConfirmPrcsEntplEltrnSign
     * 설명 : 팝업을 닫고, 전자 서명 처리 수행
     *************************************************************************/
    fn_ConfirmPrcsEntplEltrnSign () {
      this.lv_PrcsEntplEltrnSignAlertFlag = false
      this.$refs.refPrcsEntplEltrnSignAlertFlag.close()
      // 2025.01 모바일청약으로 추가한 falg닫음
      // 2025.01.14 모청유도 팝업에서 모바일청약 클릭 삭제요청 (ASR250100111 1안 ->2안으로 변경  최주연프로요청) 
      // this.$refs.refPrcsEntplEltrnSignAlertFlagNEW.close() ---> 모청유도alert
      // this.lv_MoblOfrP6 ='N' // 2025.01 모바일청약유도여부 초기화 -> 전자서명버튼후 모청으로 유도되었다는 구분값

      // 2020.1120 : 전자서명, 모바일청약 진행시 [버튼] 비활성화 처리
      // fn_RouterPushSrnId로 다음페이지 이동시 약간의 시간이 소요되어, 사용자가 화면의 버튼을 터치할수 있음
      this.lv_EltrnSignBtnDisable = true
      this.$nextTick(function () {
        // ============ PC 에서 미리보기 작동 방지 코드 ==========
        // let isMobile = window.vue.getStore('deviceState').getters.getIsMobile
        // if (!isMobile && this.EntplEltrnSign_opName === 'P2') {
        //   this.lv_AlertMsg = '해당 기능은 모바일에서만 가능합니다.'
        //   this.lv_ShowErrorPopup = true
        //   return false
        // }
        window.vue.getStore('progress').dispatch('SHOW')
        this.$nextTick(() => {
          this.fn_PrcsEntplEltrnSignNext()
        })
      })
    },
    /***************************************************************************
     * 함수 : fn_PrcsEntplEltrnSignNext
     * 설명 : 가입설계 전자서명 처리
     ***************************************************************************/
    fn_PrcsEntplEltrnSignNext () {
      let opName = this.EntplEltrnSign_opName
      let entplSignYN = this.EntplEltrnSign_entplSignYN
      console.log('fn_PrcsEntplEltrnSignNext 화상상담 opName : ', opName, ' / entplSignYN : ', entplSignYN)
      // 이변수는 초기화 시켜버리자
      this.EntplEltrnSign_opName = 'P2'
      this.EntplEltrnSign_entplSignYN = 'N'

      // 보험거래문서디지털화 - URL 변경
      this.fn_PblCommFunc(opName, 'N', 'UF1PQL0388', '', entplSignYN)
    },
    /***************************************************************************
     * 함수 : URLPQL00068_LocalCallBack
     * 설명 : 발행 콜백처리
     ***************************************************************************/
    URLPQL00068_LocalCallBack (result) {
      // console.log('URLPQL00068_LocalCallBack - result : ', result)

      let systmUndwRslt = result.tmodel.systmUndwRslt // 전산심사결과
      let zPIHptsPblHstrDto = null
      let i = 0

      // 발행전산심사 결과처리
      if ( (systmUndwRslt !== null && systmUndwRslt !== undefined) && ( systmUndwRslt.zaLstNrmYn === 'N' ) ) {
        let systmUndwRsltDtl = piCommonUtil.getArrayData(systmUndwRslt.systmUndwRsltDtl)
        let undwRsltCntnt = ''
        for ( i = 0; i < systmUndwRsltDtl.length; i++) {
          if ( systmUndwRsltDtl[i].zaNrmYn === 'N') {
            if (undwRsltCntnt !== '') undwRsltCntnt += '\n'
            undwRsltCntnt += systmUndwRsltDtl[i].zaUndwRsltMsgCntnt
          }
        }
        return
      }

      let zPIHptsPblHstrListDto = result.tmodel.zPIHptsPblHstrListDto // 발행이력정보변경Dto
      let zPIHptsPblHstrDtoList = zPIHptsPblHstrListDto.zPIHptsPblHstrDto
      let msgArr = [] // 발행 메세지관리
      let dsclsFlg = false // 고지사항 메세지 유무

      // 자금세탁방지(AML)관련 메세지처리
      if (!piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfCd) && !piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt)) {
        let inputData = {message: zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt, type: 'alert'}
        msgArr.push(inputData)

        if (zPIHptsPblHstrListDto.zaMldrPrevCnfCd === 'N') {
          return
        }
      }
      // 고지사항 미출력의 경우,안내메세지 출력
      if (this.MB_NoDSCLSFlg) {
        this.lv_noDsclsMessage.push('주보험만 가입할 경우,</br>계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
        // this.lv_noDsclsMessage.push('주보험만 가입할 경우,\n계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
      }

      for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
        zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]

        // 고지사항관련 메세지처리
        if (zPIHptsPblHstrDto.zaDsclsTypNm === '1' && !dsclsFlg) {
          msgArr.push({message: '연금형 알릴의무사항 불가, 일반형 사용 바랍니다.', type: 'alert'})// 연금형 알릴의무사항 불가, 일반형 사용 바랍니다.
          dsclsFlg = true
        }

        // 특별조건부 출력내용 없을 경우 메세지출력
        if (zPIHptsPblHstrDto.zaPblObjScCd === '118' || zPIHptsPblHstrDto.zaPblObjScCd === '119' || zPIHptsPblHstrDto.zaPblObjScCd === '120' || zPIHptsPblHstrDto.zaPblObjScCd === '121' || zPIHptsPblHstrDto.zaPblObjScCd === '122') {
          if (zPIHptsPblHstrDto.zaTrnsRsltCd === 'N' && !piCommonUtil.isEmpty(zPIHptsPblHstrDto.zaTrnsRsltCntnt)) {
            msgArr.push({message: zPIHptsPblHstrDto.zaTrnsRsltCntnt, type: 'alert'})
          }
        }
      }

      // 인쇄 콜백처리
      if (zPIHptsPblHstrListDto.zaPblYn === 'Y') {
        if (zPIHptsPblHstrListDto.zaTrnsRsltCntnt != null && zPIHptsPblHstrListDto.zaTrnsRsltCntnt !== '') {
          msgArr.push({message: zPIHptsPblHstrListDto.zaTrnsRsltCntnt, type: 'alert'})
        }

        // 상설(제안용)인쇄여부 설정
        if (zPIHptsPblHstrListDto.zaTrnsRsltCd === 'Y') {
          for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
            zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]
            if (zPIHptsPblHstrDto.zaPblObjScCd === '0') {
              this.MB_strHptsPblYn = 'Y'
              break
            }
          }
        }

        // 전자청약 콜백처리
      } else if (zPIHptsPblHstrListDto.zaPblYn === 'N' && zPIHptsPblHstrListDto.zaEltrnOfrYn === 'Y') {
        if (zPIHptsPblHstrListDto.zaTrnsRsltCd === 'N') {
          msgArr.push({message: zPIHptsPblHstrListDto.zaTrnsRsltCntnt, type: 'alert'})
        } else {
          msgArr.push({message: '전자청약이 발행되었습니다.</br>[영수증번호 : ' + zPIHptsPblHstrListDto.zaPlanId + ']', type: 'alert'})
          // msgArr.push({message: '전자청약이 발행되었습니다.\n[영수증번호 : ' + zPIHptsPblHstrListDto.zaPlanId + ']', type: 'alert'})
        }
        // 미리보기 콜백처리
      } else {
        // 미리보기용 리스트 초기화
        let MB_bsIdList = [] // 미리보기용 양식ID 리스트
        let MB_bsParamList = [] // 미리보기용 파라미터 리스트
        let MB_bsMsgList = [] // 미리보기용 XMLData(발행전문) 리스트

        // 외부망접속이거나 DI 설계사(조직종류코드가 '17, 21')의 경우,미리보기 화면의 인쇄버튼을 활성화한다.(Default:비활성화)
        let trtOrgKndCd = Gaf.getGafObjByRefId('POLICY')[0].zzorgKndCd // (처리조직종류코드)
        var disablePrintIcon = '&DISABLE_PRINT_ICON=Y' // 인쇄버튼 비활성화
        // if (this.MB_strCnsltImsiYn !== 'Y' && (this.MB_connectScCd === '2' || '21' === '<!--[R,USER_ORG_KND_CD]-->' || '17' === '<!--[R,USER_ORG_KND_CD]-->')) {
        if (this.MB_strCnsltImsiYn !== 'Y' && (this.MB_connectScCd === '2' || trtOrgKndCd === '21' || trtOrgKndCd === '17' )) {
          disablePrintIcon = '' // 인쇄버튼 활성화
        }

        var idx = 0
        var bntNm = ''
        console.log(zPIHptsPblHstrDtoList, 'zPIHptsPblHstrDtoList')
        for (let i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
          let zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]

          // 특별조건부 출력내용 없을경우, 출력안함
          if (zPIHptsPblHstrDto.zaPblObjScCd === undefined ) continue
          if (zPIHptsPblHstrDto.zaPblObjScCd === '118' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '119' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '120' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '121' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '122') {
            if (zPIHptsPblHstrDto.zaTrnsRsltCd === 'N') {
              continue
            }
          }

          var waterMark = ''
          if (zPIHptsPblHstrDto.zaPblObjScCd === '0') {
            // 워터마크 출력조건
            // 1.임시설계사 혹은 변액판매미자격의 경우, 워터마크1
            // 2.적합성진단대상상품(변액상품)이고 임시고객의 경우, 워터마크2
            // 3.가상,임시고객의 경우, 워터마크3 출력
            if (this.MB_strCnsltImsiYn === 'Y' || this.MB_strSalesVprYN === 'N') {
              waterMark = '&WTMK_NO=1'
            } else if (this.MB_strTmpCustYn === 'Y' && Gaf.getGafObjByRefId('POLICY')[0].abdapolpr[0].aldapolpr.zzilsITCLVl === '04') {
              waterMark = '&WTMK_NO=2'
            } else if (this.MB_vCustYn || this.MB_strTmpCustYn === 'Y') {
              waterMark = '&WTMK_NO=3'
            }
          }

          MB_bsIdList[idx] = zPIHptsPblHstrDto.zaSrcFileNm
          MB_bsParamList[idx] = 'MD_CD=PQ&TITLE=' + zPIHptsPblHstrDto.zaPblScNm + disablePrintIcon + waterMark
          MB_bsMsgList[idx] = "<?xml version='1.0' encoding='euc-kr'?><Message><BODY>" + zPIHptsPblHstrDto.zEntplTlgmCntntDto.entplTlgmCntnt + '</BODY></Message>'
          bntNm += zPIHptsPblHstrDto.zaPblScNm
          if (i < zPIHptsPblHstrDtoList.length - 1) bntNm += ','
          idx++
        }
        var bsObj = {}
        bsObj.bsBtnName = bntNm // 버튼 표시명 목록 (구분자:콤마)

        // 미리보기 화면을 통한 로컬인쇄 바로 출력
        if (this.MB_GAPrintYn === 'Y') bsObj.bsPrintSet = 'P'
        // console.log(bsObj, 'bsObj')
        // console.log(MB_bsIdList, 'MB_bsIdList')
        // console.log(MB_bsParamList, 'MB_bsParamList')
        // console.log(MB_bsMsgList, 'MB_bsMsgList')
      }

      // 미리보기, 발행 결과메세지 순차적으로 발생
      if (msgArr.length > 0) {
        this.lv_msgArr = msgArr
      }
      this.fn_showNoDsclsAlert(false)
      // if (msgArr.length > 0) {
      //   Gaf.multiAlert(msgArr, this.fn_ChkDone, this, null)
      // }
    },
    /***************************************************************************
     * 함수 : URLPQL00399_LocalCallBack
     * 설명 : 발행 콜백처리
     *        보험거래문서디지털화
     ***************************************************************************/
    URLPQL00399_LocalCallBack (result) {
      // console.log('URLPQL00399_LocalCallBack - result : ', result)

      let systmUndwRslt = result.tmodel.systmUndwRslt // 전산심사결과
      let zPIHptsPblHstrDto = null
      let i = 0

      // 발행전산심사 결과처리
      if ( (systmUndwRslt !== null && systmUndwRslt !== undefined) && ( systmUndwRslt.zaLstNrmYn === 'N' ) ) {
    	let systmUndwRsltDtl = systmUndwRslt.systmUndwRsltDtl
        let undwRsltCntnt = ''
        for ( i = 0; i < systmUndwRsltDtl.length; i++) {
          if ( systmUndwRsltDtl[i].zaNrmYn === 'N') {
            if (undwRsltCntnt !== '') undwRsltCntnt += '\n'
            undwRsltCntnt += systmUndwRsltDtl[i].zaUndwRsltMsgCntnt
          }
        }
    	this.lv_AlertMsg = undwRsltCntnt
        this.fn_ShowErrorMsg()
        return
      }

      let zPIHptsPblHstrListDto = result.tmodel.zPIHptsPblHstrListDto // 발행이력정보변경Dto
      let zPIHptsPblHstrDtoList = zPIHptsPblHstrListDto.zPIHptsPblHstrDto
      let msgArr = [] // 발행 메세지관리
      let dsclsFlg = false // 고지사항 메세지 유무

      // 자금세탁방지(AML)관련 메세지처리
      if (!piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfCd) && !piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt)) {
        let inputData = {message: zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt, type: 'alert'}
        msgArr.push(inputData)

        if (zPIHptsPblHstrListDto.zaMldrPrevCnfCd === 'N') {
          return
        }
      }
      // 고지사항 미출력의 경우,안내메세지 출력
      if (this.MB_NoDSCLSFlg) {
        this.lv_noDsclsMessage.push('주보험만 가입할 경우,</br>계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
        // this.lv_noDsclsMessage.push('주보험만 가입할 경우,\n계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
      }

      for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
        zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]

        // 고지사항관련 메세지처리
        if (zPIHptsPblHstrDto.zaDsclsTypNm === '1' && !dsclsFlg) {
          msgArr.push({message: '연금형 알릴의무사항 불가, 일반형 사용 바랍니다.', type: 'alert'})// 연금형 알릴의무사항 불가, 일반형 사용 바랍니다.
          dsclsFlg = true
        }

        // 특별조건부 출력내용 없을 경우 메세지출력
        if (zPIHptsPblHstrDto.zaPblObjScCd === '118' || zPIHptsPblHstrDto.zaPblObjScCd === '119' || zPIHptsPblHstrDto.zaPblObjScCd === '120' || zPIHptsPblHstrDto.zaPblObjScCd === '121' || zPIHptsPblHstrDto.zaPblObjScCd === '122') {
          if (zPIHptsPblHstrDto.zaTrnsRsltCd === 'N' && !piCommonUtil.isEmpty(zPIHptsPblHstrDto.zaTrnsRsltCntnt)) {
            msgArr.push({message: zPIHptsPblHstrDto.zaTrnsRsltCntnt, type: 'alert'})
          }
        }
      }

      // 인쇄 콜백처리
      if (zPIHptsPblHstrListDto.zaPblYn === 'Y') {
        if (zPIHptsPblHstrListDto.zaTrnsRsltCntnt != null && zPIHptsPblHstrListDto.zaTrnsRsltCntnt !== '') {
          msgArr.push({message: zPIHptsPblHstrListDto.zaTrnsRsltCntnt, type: 'alert'})
        }

        // 상설(제안용)인쇄여부 설정
        if (zPIHptsPblHstrListDto.zaTrnsRsltCd === 'Y') {
          for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
            zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]
            if (zPIHptsPblHstrDto.zaPblObjScCd === '0') {
              this.MB_strHptsPblYn = 'Y'
              break
            }
          }
        }

        // 전자청약 콜백처리
      } else if (zPIHptsPblHstrListDto.zaPblYn === 'N' && zPIHptsPblHstrListDto.zaEltrnOfrYn === 'Y') {
        if (zPIHptsPblHstrListDto.zaTrnsRsltCd === 'N') {
          msgArr.push({message: zPIHptsPblHstrListDto.zaTrnsRsltCntnt, type: 'alert'})
        } else {
          msgArr.push({message: '전자청약이 발행되었습니다.</br>[영수증번호 : ' + zPIHptsPblHstrListDto.zaPlanId + ']', type: 'alert'})
          // msgArr.push({message: '전자청약이 발행되었습니다.\n[영수증번호 : ' + zPIHptsPblHstrListDto.zaPlanId + ']', type: 'alert'})
        }
        // 미리보기 콜백처리
      } else {
        // 미리보기용 리스트 초기화
        let MB_bsIdList = [] // 미리보기용 양식ID 리스트
        let MB_bsParamList = [] // 미리보기용 파라미터 리스트
        let MB_bsMsgList = [] // 미리보기용 XMLData(발행전문) 리스트

        // 외부망접속이거나 DI 설계사(조직종류코드가 '17, 21')의 경우,미리보기 화면의 인쇄버튼을 활성화한다.(Default:비활성화)
        let trtOrgKndCd = Gaf.getGafObjByRefId('POLICY')[0].zzorgKndCd // (처리조직종류코드)
        var disablePrintIcon = '&DISABLE_PRINT_ICON=Y' // 인쇄버튼 비활성화
        // if (this.MB_strCnsltImsiYn !== 'Y' && (this.MB_connectScCd === '2' || '21' === '<!--[R,USER_ORG_KND_CD]-->' || '17' === '<!--[R,USER_ORG_KND_CD]-->')) {
        if (this.MB_strCnsltImsiYn !== 'Y' && (this.MB_connectScCd === '2' || trtOrgKndCd === '21' || trtOrgKndCd === '17' )) {
          disablePrintIcon = '' // 인쇄버튼 활성화
        }

        var idx = 0
        var bntNm = ''
        console.log(zPIHptsPblHstrDtoList, 'zPIHptsPblHstrDtoList')
        for (let i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
          let zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]

          // 특별조건부 출력내용 없을경우, 출력안함
          if (zPIHptsPblHstrDto.zaPblObjScCd === undefined ) continue
          if (zPIHptsPblHstrDto.zaPblObjScCd === '118' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '119' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '120' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '121' ||
                                zPIHptsPblHstrDto.zaPblObjScCd === '122') {
            if (zPIHptsPblHstrDto.zaTrnsRsltCd === 'N') {
              continue
            }
          }

          var waterMark = ''
          if (zPIHptsPblHstrDto.zaPblObjScCd === '0') {
            // 워터마크 출력조건
            // 1.임시설계사 혹은 변액판매미자격의 경우, 워터마크1
            // 2.적합성진단대상상품(변액상품)이고 임시고객의 경우, 워터마크2
            // 3.가상,임시고객의 경우, 워터마크3 출력
            if (this.MB_strCnsltImsiYn === 'Y' || this.MB_strSalesVprYN === 'N') {
              waterMark = '&WTMK_NO=1'
            } else if (this.MB_strTmpCustYn === 'Y' && Gaf.getGafObjByRefId('POLICY')[0].abdapolpr[0].aldapolpr.zzilsITCLVl === '04') {
              waterMark = '&WTMK_NO=2'
            } else if (this.MB_vCustYn || this.MB_strTmpCustYn === 'Y') {
              waterMark = '&WTMK_NO=3'
            }
          }

          MB_bsIdList[idx] = zPIHptsPblHstrDto.zaSrcFileNm
          MB_bsParamList[idx] = 'MD_CD=PQ&TITLE=' + zPIHptsPblHstrDto.zaPblScNm + disablePrintIcon + waterMark
          MB_bsMsgList[idx] = "<?xml version='1.0' encoding='euc-kr'?><Message><BODY>" + zPIHptsPblHstrDto.zEntplTlgmCntntDto.entplTlgmCntnt + '</BODY></Message>'
          bntNm += zPIHptsPblHstrDto.zaPblScNm
          if (i < zPIHptsPblHstrDtoList.length - 1) bntNm += ','
          idx++
        }
        var bsObj = {}
        bsObj.bsBtnName = bntNm // 버튼 표시명 목록 (구분자:콤마)

        // 미리보기 화면을 통한 로컬인쇄 바로 출력
        if (this.MB_GAPrintYn === 'Y') bsObj.bsPrintSet = 'P'
        // console.log(bsObj, 'bsObj')
        // console.log(MB_bsIdList, 'MB_bsIdList')
        // console.log(MB_bsParamList, 'MB_bsParamList')
        // console.log(MB_bsMsgList, 'MB_bsMsgList')
      }

      // 미리보기, 발행 결과메세지 순차적으로 발생
      if (msgArr.length > 0) {
        this.lv_msgArr = msgArr
      }
      this.fn_showNoDsclsAlert(false)
      // if (msgArr.length > 0) {
      //   Gaf.multiAlert(msgArr, this.fn_ChkDone, this, null)
      // }
    },
    /*************************************************************************
     * 함수 : UF1PQL0388_LocalCallBack
     * 설명 : 전자서명 발행전문 조회 콜백처리
     *************************************************************************/
    UF1PQL0388_LocalCallBack (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      console.log('UF1PQL0388_LocalCallBack : result', result)
      // debugger
      if (result == null || !result.hasOwnProperty('tmodel') || !result.tmodel.hasOwnProperty('zPIHptsPblHstrListDto')) {
        this.lv_AlertMsg = '전자문서 생성중 오류가 발생하였습니다.'
        this.lv_ShowAlertMsgFlag = true
        return
      }

      let systmUndwRslt = result.tmodel.systmUndwRslt // 전산심사결과
      // 발행전산심사 결과처리
      if ( systmUndwRslt.zaLstNrmYn === 'N') {
        let systmUndwRsltDtl = systmUndwRslt.systmUndwRsltDtl
        let undwRsltCntnt = ''
        for ( i = 0; i < systmUndwRsltDtl.length; i++ ) {
          if ( systmUndwRsltDtl[i].zaNrmYn === 'N' ) {
            if (undwRsltCntnt !== '') undwRsltCntnt += '\n'
            undwRsltCntnt += systmUndwRsltDtl[i].zaUndwRsltMsgCntnt
          }
        }
        this.lv_AlertMsg = undwRsltCntnt
        this.fn_ShowErrorMsg()
        this.lv_EltrnSignBtnDisable = false
        return
      }
      
      // ASR240300741 / [상품판매준비] 모바일청약 블러킹 안내문구 반영_사랑온 / 김민지 프로님
      // ASR241100802 / 모청 발행팝업 개선 및 오류메시지 팝업 정비 / 최주연 프로님
      this.refBottomSheetError_errorCode = '' // 오류코드
      if (result.tmodel.zPIHptsPblHstrListDto.zaEltrnSignMthCd === 'M' && result.tmodel.zPIHptsPblHstrListDto.zaEltrnSignYn === 'Y' && result.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCd === 'BLK') {
        console.log('MSPPI250P.fn_EusPrcsQtrHandler: ', '------------ 모청 블로킹 ------------')
        // this.lv_AlertMsg = '<p style="text-align: justify; margin-bottom: 0em;">"모바일청약"은 상품설명서(체결용)을 활용하여 고객에게 상품 설명을 하신 후 진행가능합니다.<br><br>'
        //                     + '계약자기준 5일이내 해당 상품의 상품설명서(체결용)을 활용한 정보가 없습니다. 상품설명서를 "미리보기(PC 제외) 또는 인쇄" 후 청약 진행하시기 바랍니다.</p>'
        //                     // + '<br><br><p style="text-align: justify; margin-bottom: 0em;"><blockquote style="text-align: justify; text-indent: -1.2em; margin-left: 1em; margin-top: 0em; margin-right: 0em;">※ 단, 상품설명서 활용정보는 "발행"화면 조회시 확인됨에 따라 상품설명서를 최초로 "미리보기(PC 제외) 또는 인쇄"를 하신 경우에는 "발행"화면을 다시 조회하여 진행하시기 바랍니다.</blockquote></p>'
        // this.fn_ShowErrorMsg()
        this.refBottomSheetError_errorMessge = '미리보기(PC제외) 또는 인쇄 후, 진행가능' // 타이틀
        this.refBottomSheetError_errorMessgeDetail = '고객에게 상품설명 후, 진행 가능합니다.</br>(계약자 기준 5일 이내 해당 상품의 상품설명서(체결용)을 활용한 정보 필요)' // 본문
        this.refBottomSheetError_errorCode = 'X' // 오류코드
        this.refBottomSheetError_errorCodeExpand = false
        this.$refs.refBottomSheetError.open()
        this.lv_EltrnSignBtnDisable = false
        return
      }

      if ((result.tmodel.zPIHptsPblHstrListDto.zaEltrnSignMthCd === 'M' || result.tmodel.zPIHptsPblHstrListDto.zaEltrnSignMthCd === 'T') && result.tmodel.zPIHptsPblHstrListDto.zaEltrnSignYn === 'Y' && result.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCd === 'NBC') {
        console.log('MSPPI250P.fn_EusPrcsQtrHandler: ', '선심사 블로킹')
        this.refBottomSheetError_errorMessge = '알릴의무 내역을 찾을 수 없습니다.</br>지류로 진행 혹은 재설계해주세요.' // 타이틀
        this.refBottomSheetError_errorMessgeDetail = '' // 본문
        this.refBottomSheetError_errorCode = 'NBC'
        this.refBottomSheetError_errorCodeExpand = false
        this.$refs.refBottomSheetError.open()
        this.lv_EltrnSignBtnDisable = false
        return
      }
      // younghee 임시코드 : 5502에서 발행번호가 안나옴.
      // debugger
      // result.tmodel.zPIHptsPblHstrListDto.zaTbtPblNo = result.tmodel.zPIHptsPblHstrListDto.zaPlanId

      let arrHptsList = result.tmodel.zPIHptsPblHstrListDto.zPIHptsPblHstrDto

      this.resultHptsPblHstrListVO = {}
      this.resultHptsPblHstrListVO.zaTrnsRsltCntnt = result.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCntnt // 발행전문 JSON

      this.resultHptsPblHstrListVO.tbtPblKndNm = result.tmodel.zPIHptsPblHstrListDto.zaTbtPblKndNm
      this.resultHptsPblHstrListVO.tbtPblNo = result.tmodel.zPIHptsPblHstrListDto.zaTbtPblNo

      this.resultHptsPblHstrListVO.PIHptsPblHstrVO = arrHptsList

      let i = 0
      let dsclsFlg = false // 고지사항 메세지 유무
      // debugger
      // #2020모바일청약, 모바일청약을 조건에 추가
      // if ( this.STR_currentAction === 'P2' || this.STR_currentAction === 'P6' ) {
      if ( this.STR_currentAction === 'P2' || this.STR_currentAction === 'P6' || this.STR_currentAction === 'P10') {

        let zPIHptsPblHstrListDto = result.tmodel.zPIHptsPblHstrListDto // 발행이력정보변경Dto
        let zPIHptsPblHstrDtoList = zPIHptsPblHstrListDto.zPIHptsPblHstrDto

        // 자금세탁방지(AML)관련 메세지처리
        if (!piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfCd) && !piCommonUtil.isEmpty(zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt)) {
          this.lv_AlertMsg = zPIHptsPblHstrListDto.zaMldrPrevCnfRsltCntnt
          this.lv_ShowAlertMsgFlag = true

          if (zPIHptsPblHstrListDto.zaMldrPrevCnfCd === 'N') {
            return
          }
        }

        // 고지사항 미출력의 경우,안내메세지 출력
        if (this.MB_NoDSCLSFlg) {
          this.rsltMsgArray.push('주보험만 가입할 경우,</br>계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
          // this.rsltMsgArray.push('주보험만 가입할 경우,\n계약전 알릴의무 사항(고지서) 작성 대상이 아닙니다.')
        }

        let zPIHptsPblHstrDto = ''
        // 고지사항관련 메세지처리
        for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
          zPIHptsPblHstrDto = zPIHptsPblHstrDtoList[i]
          if (zPIHptsPblHstrDto.zaDsclsTypNm === '1' && !dsclsFlg) {
            this.rsltMsgArray.push('연금형 알릴의무사항 불가, 일반형 사용 바랍니다.')
            dsclsFlg = true
          }
        }

        // 특별조건부 출력내용 없을 경우 메세지출력
        if ( zPIHptsPblHstrDto.zaPblObjScCd === '118' || zPIHptsPblHstrDto.zaPblObjScCd === '119' || zPIHptsPblHstrDto.zaPblObjScCd === '120' || zPIHptsPblHstrDto.zaPblObjScCd === '121' || zPIHptsPblHstrDto.zaPblObjScCd === '122') {
          if (zPIHptsPblHstrDto.zaTrnsRsltCd === 'N' && !piCommonUtil.isEmpty(zPIHptsPblHstrDto.zaTrnsRsltCntnt)) {
            this.rsltMsgArray.push(zPIHptsPblHstrDto.zaTrnsRsltCntnt)
          }
        }

        for (let k = 0; k < zPIHptsPblHstrDtoList.length; k++) {
          if (zPIHptsPblHstrDtoList[k].zaPblObjScCd === '105') {
            this.rsltMsgArray.push("'헬스케어 서비스 신청서'는 서면으로 작성이 필요합니다.")
          }
        }

        // 신계약 폼 리스트 설정 처리
        // 신규전자서명 Fom 목록 적제
        this.nsFormFileList = []
        let nsformObj = ''
        let zaSrcFileNm = ''

        for (i = 0; i < zPIHptsPblHstrDtoList.length; i++) {
          nsformObj = zPIHptsPblHstrDtoList[i]
          if (nsformObj.hasOwnProperty('zaSrcFileNm')) {
            zaSrcFileNm = nsformObj.zaSrcFileNm
            if ( zaSrcFileNm !== '' ) {
              this.nsFormFileList.push(zaSrcFileNm)
            }
          }
        }
        // 보험거래문서디지털화 - 미리보기 일때만 여기서 메세지 처리
        if (this.STR_currentAction === 'P2') {
          this.fn_ShowAlertMsg(this.STR_currentAction, 'Y')
        }

        /* 보험거래문서디지털화
           1. 전자서명일때는 상설 "전체" 전문 제거
           2. 전자서명일때는 고지사항조회
        */
        // #2020모바일청약, 모바일청약을 조건에 추가
        if ( this.STR_currentAction === 'P6' || this.STR_currentAction === 'P10') {
          // "전체" 전문은 제거
          let _zaTrnsRsltCntnt = JSON.parse(result.tmodel.zPIHptsPblHstrListDto.zaTrnsRsltCntnt)
          let idx = _zaTrnsRsltCntnt['FORM_ARY'].findIndex((item, idx) => {
            return item['zaPblObjScCd'] && item['zaPblObjScCd'] === '0'
          })
          if (idx > -1) {
            _zaTrnsRsltCntnt['DATA_ARY'].splice(idx, 1)
            _zaTrnsRsltCntnt['FORM_ARY'].splice(idx, 1)
            _zaTrnsRsltCntnt['META_ARY'].splice(idx, 1)
            this.resultHptsPblHstrListVO.zaTrnsRsltCntnt = JSON.stringify(_zaTrnsRsltCntnt) // "전체"가 제거된 발행전문 JSON
          }
          // debugger
          // 고지사항 조회
          let functionName = 'UF1PQL0389'
          let lv_Vm = this
          let inputJson = {
            'planId': Gaf.getObj('pmodel').abdapolicy[0].zaPlanId,
            'zaEltrnDsclsCntnt': this.STR_currentAction === 'P6' ? 'ZA_MH_TLGM_TRT_YN:Y' : 'ZA_MH_TLGM_TRT_YN:N' // 유의지급율전문처리여부[ZA_MH_TLGM_TRT_YN]
          }
          console.log(inputJson, ':inputJson')
          window.vue.getStore('progress').dispatch('SHOW')
          piCommonUtil.invoke(functionName, inputJson, lv_Vm.UF1PQL0389_LocalCallBack, null, lv_Vm.UF1PQL0389_LocalCallBack, lv_Vm, lv_Vm)
        }

        // #2020모바일청약
        // if ( this.STR_currentAction === 'P10' ) {
        //   this.fn_ShowAlertMsg(this.STR_currentAction, 'Y')
        // }
      } else {
        if (this.STR_currentAction === 'MnlLMS') {
          this.lv_SelectedSingleItem = {'fileNm': result.tmodel.zPIHptsPblHstrListDto.zaTbtPblNo}
          this.fn_OpenMSPPI252P()
          // this.lv_IsShowTSSPI391P = true
        } else {
          window.vue.getStore('progress').dispatch('SHOW')
          this.fn_Command_P8(this.checkIntervalType, true)
        }
        // window.vue.getStore('progress').dispatch('UPDATE', true)
        // this.fn_Command_P8(this.checkIntervalType, true)
      }
    },
    /*************************************************************************
     * 함수 : refBottomSheetError_showErrorCode
     * 설명 : 사랑On 개선 - 오류메시지 수정: 오류코드보기 클릭시 처리
     *************************************************************************/
    refBottomSheetError_showErrorCode() {
      if(this.refBottomSheetError_errorCodeExpand === true) this.refBottomSheetError_errorCodeExpand = false
      else if(this.refBottomSheetError_errorCodeExpand === false) this.refBottomSheetError_errorCodeExpand = true
    },
    /*************************************************************************
     * 함수 : refBottomSheetError_close
     * 설명 : 사랑On 개선 - 오류메시지 수정: 알림 메시지 닫기 처리
     *************************************************************************/
    refBottomSheetError_close() {
      this.$refs.refBottomSheetError.close()
    },
    /*************************************************************************
     * 함수 : refBottomSheetError_confirm
     * 설명 : 사랑On 개선 - 오류메시지 수정: 알림 메시지 닫기 처리
     *************************************************************************/
    refBottomSheetError_confirm(targetBtn) {
      this.$refs.refBottomSheetError.close()
      switch (targetBtn) {
        case 'btnPrint': // 인쇄
            this.fn_ConfirmPrcsPreSancNextPrc('btnPrint')
          break;
          case 'btnPreview': // 미리보기
            this.fn_ConfirmPrcsPreSancNextPrc('btnPreview')
          break;
          default:
          break;
      }
    },
    /******************************************************************************
    * Function명 : fn_chatBotContent
    * 설명       : 챗봇 링크 브라우저 호출
    *              (accessId : 접속시스템구분값(미니:Mini)/ userId : 로그인한 FC 사번)
    ******************************************************************************/
    fn_chatBotContent () {
      let lv_Url = ''
      if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
        lv_Url = 'https://ap1.t.slime2.samsunglife.kr:8449/messenger/fcBot?accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      } else {
        lv_Url = 'https://slimsg.samsunglife.com/messenger/redirect?botId=BOT00000435&reload=N&isWeb=true&selFlag=PC&accessId=Mini&userId=' + this.getStore('userInfo').getters.getUserInfo.userId
      }

      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
    },
    /*************************************************************************
     * 함수 : UF1PQL0389_LocalCallBack
     * 설명 : 전자서명 발행전문 조회 콜백처리
     *************************************************************************/
    UF1PQL0389_LocalCallBack (result) {
      console.log('UF1PQL0389_LocalCallBack : result', result)
      window.vue.getStore('progress').dispatch('HIDE')
      // debugger
      if (result == null || !result.hasOwnProperty('zaEltrnDsclsCntnt')) {
        this.lv_AlertMsg = '전자서명 고지사항정보 생성중 오류가 발생하였습니다.'
        this.lv_ShowAlertMsgFlag = true
        return
      }

      this.pvmnotice = result.zaEltrnDsclsCntnt
      this.fn_ShowAlertMsg(this.STR_currentAction, 'Y')
    },
    fn_ChkDone () {
      console.log('인쇄 or 미리보기 done')
      // NEXT 사랑On : 버튼 disabled 해제
      this.lv_EltrnSignBtnDisable = false
    },
    /************************************************************************************************
    * Function명  : fn_DeleteTmpDirectory
    * 설명        : P6 동작 시 temp 디렉토리 삭제!!!
    *               미사용
    ************************************************************************************************/
    fn_DeleteTmpDirectory () {
      let docs = '' // 파일 객체를 받아
      let dirs = []
      let mesg = ''

      for (let d = 0; d < 2; ++d ) {
        if (d === 0) {
          // docs = new File(File.documentsDirectory.nativePath +"/sli/temp/pdf/"); //AS-IS 다운로드 및 전자서명 완료 임시
          docs = '' // 파일 객체?? new File(File.documentsDirectory.nativePath +"/sli/temp/old_pdf_final/"); //AS-IS 다운로드 및 전자서명 완료 임시
        } else if (d === 1) {
          docs = '' // 파일 객체?? new File(File.documentsDirectory.nativePath +"/sli/temp/pdf_finish/");//신규 전자서명 완료 경로
        } else {
          return
        }

        try {
          dirs = docs.getDirectoryListing()
        } catch ( error ) {
          docs.createDirectory()
          dirs = docs.getDirectoryListing()
        }

        try {
          for (let i = 0; i < dirs.length; i++) {
            let fVo = {}

            if (fVo.isDirectory) {
              fVo.deleteDirectory(true)
            } else {
              fVo.deleteFile()
            }
          }
        } catch ( error ) {
          mesg = '저장폴더를 초기화 하던 중 에러가 발생했습니다. 다시 시도하여 주시기 바랍니다.'
        }
      } // for d

      if ( mesg !== '' ) {
        // AlertMsg.show(mesg,"알림")
      }
    },
    // ====================================== 인쇄 관련 함수 start ====================================== //
    /************************************************************************************
     * 함수 : fn_ProcPrint
     * 인쇄 버튼 선택 시 알림 팝업
     * - 예 선택 시 fn_requestPrint를 호출하여 프린트 요청 처리 수행
     * - 아니오. 는 뭐 그냥 닫음 됨
     ************************************************************************************/
    fn_ProcPrint (opName, isFirst = true ) {
      this.EntplEltrnSign_opName = opName
      if (this.lv_ShowEltrnSignBtn && this.lv_Chk1.includes('2')) {
        this.lv_ProcPrintMsg = '전자서명/모바일청약 가능건입니다.</br>지류발행이 반드시 필요하십니까?'
        // this.lv_ProcPrintMsg = '전자서명 대상건입니다.</br>지류발행이 반드시 필요하십니까?</br></br>※ 단, 계약자 친권자 상이건은 전자서명이 불가합니다.'
        // this.lv_ProcPrintMsg = '전자서명 대상건입니다.\n지류발행이 반드시 필요하십니까?\n\n※ 단, 계약자 친권자 상이건은 전자서명이 불가합니다.'
      } else {
        this.lv_ProcPrintMsg = '인쇄를 하시겠습니까?'
      }
      // this.lv_ProcPrintFlag = true
      this.$refs.refProcPrintFlag.open()
    },
    /************************************************************************************
     * 함수 : fn_RequestPrint
     * 설명 : 인쇄 버튼 선택 후 '예' 선택 시 인쇄 요청 함수
     ************************************************************************************/
    fn_RequestPrint () {
      // this.lv_ProcPrintFlag = false
      this.$refs.refProcPrintFlag.close()
      this.$nextTick(function () {
        window.vue.getStore('progress').dispatch('SHOW')
        this.$nextTick(() => {
          this.fn_RequestPrintNext()
        })
      })
    },
    fn_RequestPrintNext () {
      let opName = this.EntplEltrnSign_opName
      this.EntplEltrnSign_opName = ''

      let pblYn = 'Y' // 인쇄여부(기본값:Y)
      // 접속구분코드가 외부망이고 GA설계인 경우,로컬프린터로 인쇄하기 위해 미리보기의 바로출력 기능을 이용한다.
      if (this.MB_connectScCd === '2' && this.headerVO.trtOrgKndCd === '17') {
        pblYn = 'N' // 인쇄여부
        this.MB_GAPrintYn = 'Y' // GA설계사 로컬인쇄여부
      }

      // this.fn_PblCommFunc(opName, pblYn, 'URLPQL00068')
      // 보험거래문서디지털화 - URL변경
      this.fn_PblCommFunc(opName, pblYn, 'URLPQL00399')
    },
    fn_RetryPrint () {
      // this.lv_ShowRetryPrintFlag = false
      this.$refs.refShowRetryPrintFlag.close()
      this.EntplEltrnSign_opName = this.STR_currentAction
      this.fn_RequestPrint()
    },
    fn_CancelPrint () {
      this.lv_ProcPrintFlag = false
      if (this.$refs.refProcPrintFlag.status === 'open') {
        this.$refs.refProcPrintFlag.close()
      }
      if (this.$refs.refShowRetryPrintFlag.status === 'open') {
        this.$refs.refShowRetryPrintFlag.close()
      }
      // NEXT 사랑On : 버튼 disabled 해제
      this.lv_EltrnSignBtnDisable = false
    },
    // ====================================== 인쇄 관련 함수 end ====================================== //
    fn_EmptySpaceReplace (value) {
      if ( value === null || value === 'NaN' || value === 'null' || value === 'undefined' || value === ' ' ) {
        value = ''
      }
      return value
    },
    // --------------------------------------------------------------------------------------------
    // 이메일 발송 관련 --------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------
    /************************************************************************************************
     * Function명  : fn_MoveCustmerCard
     * 설명        : 고객카드 이동
     ************************************************************************************************/
    fn_MoveCustmerCard () {
      let objmsgCust = {}
      objmsgCust.cnsltNo = this.headerVO.userEno // 컨설턴트 번호
      objmsgCust.chnlCustId = this.MB_contrChnlCustId // 채널고객ID
      // import MSPCM300P from '@/ui/cm/MSPCM300P'
      this.popupMSPCM300P = this.$bottomModal.open(MSPCM300P, {
        properties: {
          callerId : this.$options.screenId, 
          // viewId : 'MSPCM301D',
          pChnlCustId : this.MB_contrChnlCustId,
          pCnsltNo : this.headerVO.userEno,
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명
          // refRegEmailAddr
          // refPopEmailConfirmFlag
          onPopupConfirm: (rtnData) => {
            // console.log('[MSPCM300P] onPopupConfirm ===> ', rtnData)
            this.fn_CloseBottomSheet('refRegEmailAddr')
            if (!_.isEmpty(rtnData) && rtnData === 'U') {
              // 고객카드 수정되어 재조회 필요
              this.lv_ChgCustInfo = true
              let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]
              window.vue.getStore('progress').dispatch('SHOW')
              this.fn_GetCustInfo(_rltnrInfo_11)
            }
            this.$bottomModal.close(this.popupMSPCM300P)
          },
          onPopupClose : (rtnData) => {
            this.fn_CloseBottomSheet('refRegEmailAddr')
            // this.fn_CloseBottomSheet('refPopEmailConfirmFlag')
            this.$bottomModal.close(this.popupMSPCM300P)
            // console.log('[MSPCM300P] onPopupClose ===> ', rtnData)
          },
          closePopup : (rtnData) => {
            // console.log('[MSPCM300P] closePopup ===> ', rtnData)
            this.fn_CloseBottomSheet('refRegEmailAddr')
            // this.fn_CloseBottomSheet('refPopEmailConfirmFlag')
            this.$bottomModal.close(this.popupMSPCM300P)
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
      // this.$router.push({name: 'TSSCM207M', params: {objmsgCust: this.MB_contrChnlCustId, cnsltNo: this.headerVO.userEno, uuid: new Date().getTime()}})
    },
    /************************************************************************************************
     * Function명  : fn_PopEmailConfirm
     * 설명        : 이메일 발송 팝업 (TSSPI073P)띄우기 전 확인 팝업
     ************************************************************************************************/
    fn_PopEmailConfirm () {
      let tempEmailAddr = ''
      let _rltnrInfo = this.tmodel.rltnrInfo
      if (piCommonUtil.isEmpty(_rltnrInfo)) {
        return
      }
      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]
      tempEmailAddr = this.MB_EmailAddr
      this.lv_PopEmailConfirmMsg = {zaInsrdNm: _rltnrInfo_11.zaInsrdNm, emailAddr: tempEmailAddr}
      // this.lv_isPopEmailConfirmFlag = true // contetns
      this.$refs.refPopEmailConfirmFlag.open()
      // this.lv_PopEmailConfirmFlag = true
      
      // this.lv_PopEmailConfirmMsg = '계약자 : ' + _rltnrInfo_11.zaInsrdNm + '\n\n이메일 : ' + tempEmailAddr + '\n\n' +
      //   '이메일 주소가 틀리거나  없는 경우에는\n상품설명서(제안용)를 이메일로 받으실 수 없습니다.\n그런 경우에는 반드시 [등록/수정]에서 이메일 주소를 등록해 주시기 바랍니다.\n\n'
      console.log('Message : ' + this.lv_PopEmailConfirmFlag )
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI251P
     * 설명        : 수신고객목록 팝업 호출
     ************************************************************************************************/
    fn_OpenMSPPI251P () {
      this.popupMSPPI251P = this.$bottomModal.open(MSPPI251P, {
        // lv_TargetType : fn_BtnNsEusPrcsClickHandler 에서 세팅
        // lv_IsMadeMultiItem : 다건 자료 선택 default true
        // lv_SelectedSingleItem : UF1PQL0388_LocalCallBack 에서 MnlLMS에서 세팅 후 문자발송 팝업 오픈!
        properties: {
          pChnlCustIds: this.custListObj,
          // pSelectedUntySlmtItemList: this.lv_SelectedItemList, // 값 세팅하는 곳 X
          pSelectedUntySlmtItemList: this.lv_SelectedItemList, // 값 세팅하는 곳 X
          pTargetType: this.lv_TargetType,
          pIsSelectedMultiItem: this.lv_IsMadeMultiItem,
          pSelectedSingleItem: this.lv_SelectedSingleItem,
        },
        listeners: {
          confirmPopup: (param) => {
            this.$bottomModal.close(this.popupMSPPI251P)
            if (param) {
              this.lv_SelectedCustList = []
            }
          },
          evtMSPPI251PNextStep: (targetType, targetCustList) => {
            let lv_Vm = this
            // pdf 생성
            lv_Vm.lv_pdfApndFilePathNm = piCommonUtil.getCurrentDate('YYYY-MM-dd').substring(0, 10).replace(/-/gi, '')

            // 고객 정보 설정
            lv_Vm.lv_SmsTargetCustList = targetCustList
            // 문서 목록 설정
            // let tmpParam = lv_Vm.fn_SetCommInputParam('MnlLMS', 'N')
            // lv_Vm.lv_SelectedItemList = []
            // lv_Vm.lv_SelectedItemList.push(tmpParam.zPIHptsPblHstrDto)
            // 서비스 진행 조건 설정
            lv_Vm.STR_currentAction = 'MnlLMS'
            lv_Vm.EntplEltrnSign_opName = 'MnlLMS'
            lv_Vm.checkIntervalType = 'MnlLMS'
            lv_Vm.EntplEltrnSign_entplSignYN = ''
            this.$nextTick(function () {
              window.vue.getStore('progress').dispatch('SHOW')
              this.$nextTick(() => {
                this.fn_PrcsEntplEltrnSignNext()
              })
            })
            // this.$bottomModal.close(this.popupMSPPI251P)
          }
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI252P
     * 설명        : LMS 문자 발송 팝업 호출
     ************************************************************************************************/
    fn_OpenMSPPI252P () {
      let lv_vm = this
      this.popupMSPPI252P = this.$bottomModal.open(MSPPI252P, {
        properties: {
          pIsShowTSSPI391P: this.lv_IsShowTSSPI391P,
          pSelectedUntySlmtItemList: this.lv_SelectedItemList,
          pIsSelectedMultiItem: this.lv_IsMadeMultiItem,
          pSelectedSingleItem: this.lv_SelectedSingleItem,
          pSmsTargetCustList: this.lv_SmsTargetCustList,
        },
        listeners: {
          evtCloseMSPPI252P: () => {
            this.$bottomModal.close(this.popupMSPPI252P)
          },
          evtConfirmMSPPI252P: (result) => {
            this.lv_preface = result
            window.vue.getStore('progress').dispatch('SHOW')
            this.fn_Command_P8(this.checkIntervalType, true)
          }
        }
      })
    },
    /************************************************************************************************
     * Function명  : fn_SendMnlLMS
     * 설명        : 상품설명서 LMS 발송처리
     ************************************************************************************************/
    fn_SendMnlLMS () {
      window.console.log('■■■■■ MSPPI250P ■■■■■ fn_SendMnlLMS START ☞ ' + new Date().getTime())

      // 상설LMS 본문입력 팝업 종료
      this.$bottomModal.close(this.popupMSPPI252P)
      // this.lv_IsShowTSSPI391P = false

      // 수신고객목록 팝업 종료
      this.$bottomModal.close(this.popupMSPPI251P)
      // this.lv_IsShowTSSSA016P = false
      // 상설LMS 대상고객 목록 취소
      this.lv_SmsTargetCustList = []

      let lv_Vm = this
      const trnstId = 'txTSSSA12L1'
      const auth = 'S'

      let ZPIHptsPblHstrDto = lv_Vm.resultHptsPblHstrListVO.PIHptsPblHstrVO[0]
      let _SACntntBasInfoSVO = {
        untySlmtId: ZPIHptsPblHstrDto.zaUntySlmtId,
        pblUcst: ZPIHptsPblHstrDto.zaPblUcst,
        bnwUcst: ZPIHptsPblHstrDto.zaBnwUcst,
        colorUcst: ZPIHptsPblHstrDto.zaColorUcst,
        pblNos: ZPIHptsPblHstrDto.zaPblNos,
        hptsPrdtReprCd: ZPIHptsPblHstrDto.zaPrcd,
        untySlmtNm: ZPIHptsPblHstrDto.zaPrdtNm,
        pdfApndFileStoreNm: ZPIHptsPblHstrDto.zaPblNo,
        pdfApndFilePathNm: lv_Vm.lv_pdfApndFilePathNm // piCommonUtil.getCurrentDate('YYYY-MM-dd').substring(0, 10).replace(/-/gi, '')
      }

      let _rltnrInfo = this.tmodel.rltnrInfo
      let _rltnrInfo_11 = '' // 계약자
      let _rltnrInfo_21 = '' // 주피보험자
      if (!piCommonUtil.isEmpty(_rltnrInfo)) {
        _rltnrInfo_11 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]
        _rltnrInfo_21 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '21' })[0]
      }

      let _CMCustBasSVO = {
        chnlCustId: _rltnrInfo_11.zaAgtFileId,
        custNm: _rltnrInfo_11.zaInsrdNm,
        mnCustNm: _rltnrInfo_21.zaInsrdNm,
        mnCustRrn: _rltnrInfo_21.zzcustRrnId
      }

      let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
      let pParams = {
        cnsltNo: userInfo.userId,
        cnsltNm: userInfo.userNm,
        preface: lv_Vm.lv_preface,
        sACntntBasInfoSVO: [], // _SACntntBasInfoSVO,
        iCEtchCntntCustVO: [], // _CMCustBasSVO
        zaMktCnsntInqrYmd: ''
      }
      pParams.sACntntBasInfoSVO.push(_SACntntBasInfoSVO)
      pParams.iCEtchCntntCustVO.push(_CMCustBasSVO)
      pParams.zaMktCnsntInqrYmd = this.zaMktCnsntInqrYmd

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            if (response.body.sAEtchLinkCmpxSVO != null && response.body.sAEtchLinkCmpxSVO.sAEtchLinkCmpxVO != null && response.body.sAEtchLinkCmpxSVO.sAEtchLinkCmpxVO.length > 0) {
              let sucessCount = 0
              let errMsg = ''
              response.body.sAEtchLinkCmpxSVO.sAEtchLinkCmpxVO.forEach(item => {
                if (item.cMEtchLinkRsltVO != null) {
                  if (item.cMEtchLinkRsltVO.code === 'S') {
                    sucessCount++
                  } else {
                    if (errMsg === '') {
                      errMsg = item.cMEtchLinkRsltVO.message
                    } else {
                      if (errMsg.indexOf(item.cMEtchLinkRsltVO.message) < 0) {
                        errMsg += '\n' + item.cMEtchLinkRsltVO.message
                      }
                    }
                  }
                }
              })
              // S : 성공
              // F : Exception 오류
              // M1 : 이캐시 정합성 체크 오류
              // M2 : 차감할 수 있는 이캐시 이력이 없음
              // C1 : 수신동의여부 'N'으로 문자발송불가
              // C2 : 고객전화번호 공란으로 문자발송불가
              // C0 : 고객 정보가 없습니다
              if (response.body.sAEtchLinkCmpxSVO.sAEtchLinkCmpxVO.length === sucessCount) {
                lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
                // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                // lv_Vm.getStore('confirm').dispatch('ADD', '발송이 완료되었습니다.')
                // lv_Vm.$emit('evtConfirmTSSPI391P')
              } else if (errMsg !== '') {
                let tempMsg = errMsg + '\n 관리자에게 문의하세요.'
                // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', tempMsg)
              }
            } else {
              if (response.msgComm.msgDesc !== null && response.msgComm.msgDesc !== undefined) {
                // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
              }
            }
            // lv_Vm.isLoading = false
          } else {
            if (response.msgComm.msgDesc !== null && response.msgComm.msgDesc !== undefined) {
              // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    // ====================================== 이메일 관련 함수 start ====================================== //
    /************************************************************************************************
    * Function명  : fn_Command_P7
    * 설명        : 이메일 전송
    ************************************************************************************************/
    fn_Command_P7 () {
      let abdapolicyList = this.abdapolicy // Policy 정보 Array
      let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
      let abdapolpr = abdapolicy.abdapolpr[0]
      let ztpqlCboextPrcsAbdapolpr = abdapolpr.ztpqlCboextPrcsAbdapolpr // 추가정보

      let tempEmailAddr = ''
      let tempPrdtNm = (ztpqlCboextPrcsAbdapolpr.zaPrdtNm) ? ztpqlCboextPrcsAbdapolpr.zaPrdtNm : ''

      tempEmailAddr = this.MB_EmailAddr

      let _rltnrInfo = this.tmodel.rltnrInfo
      if (piCommonUtil.isEmpty(_rltnrInfo)) {
        return
      }

      let _rltnrInfo_11 = this.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]

      let numArrCnt1 = this.resultHptsPblHstrListVO.PIHptsPblHstrVO.length
      let ArrDat = []

      for (let i = 0; i < numArrCnt1; i++ ) {
        let tmpPIHptsPblHstrVO = this.resultHptsPblHstrListVO.PIHptsPblHstrVO[i]

        ArrDat[0] = this.headerVO.userEno // 컨설턴트사번
        ArrDat[1] = tempEmailAddr // 이메일주소
        ArrDat[2] = tmpPIHptsPblHstrVO.zaPrdtNm // 상품명
        ArrDat[3] = tmpPIHptsPblHstrVO.zaSrcFileNm // 원천파일명
        ArrDat[4] = _rltnrInfo_11.zaInsrdNm // 고객명
        ArrDat[5] = _rltnrInfo_11.zaAgtFileId // 채널고객id
      }

      if (_.isEmpty(ArrDat[1])) {
      // if ( ArrDat[1] ArrDat[1].trim() === '' ) {
        // AlertMsg.show("고객(계약자)의 이메일 주소가 등록되지 않았습니다.", "알림");
        return
      }

      let g_oCustInfoList = []
      let cust = []
      let custTiSubject = '' // 메일 제목
      let custTaBody = '' // 메일 본문

      custTiSubject = _rltnrInfo_11.zaInsrdNm + ' 고객님을 위한 ' + tempPrdtNm + ' 상품설명서(제안용) 입니다.'
      custTaBody += '삼성생명 ' + this.headerVO.userNm + ' 컨설턴트가\n' +
      _rltnrInfo_11.zaInsrdNm + ' 고객님을 위해 보내드리는\n' +
      tempPrdtNm + ' 상품설명서(제안용) 입니다.'

      cust.pdf_file_id = this.resultHptsPblHstrListVO.tbtPblNo + '.pdf' // 실제 pdf 파일명
      cust.pdf_file_path = 'file.path.entpl' // pdf 파일 경로
      cust.pdf_file_name = this.resultHptsPblHstrListVO.tbtPblNo + '.pdf' // 첨부했을때 pdf 파일명
      cust.is_original = false // pdf 파일 경로 밑에 날짜가 있으면 false, 없으면 true
      cust.chnlCustId = ArrDat[5] // 수신자 채널고객id
      cust.custNm = _rltnrInfo_11.zaInsrdNm // 수신자 고객명
      cust.custEmailAddr = ArrDat[1] // 수신자 이메일 주소
      cust.custTiSubject = custTiSubject
      cust.custTaBody = custTaBody

      g_oCustInfoList.push(cust)

      let oDdsInfo = []
      oDdsInfo.ddsName = ArrDat[2] // => 상품명
      oDdsInfo.dds = '-1000' //
      oDdsInfo.ddsID = 'dds_file' // => 상품 ID

      let pParam = {}
      pParam.oGrdMyList = g_oCustInfoList
      pParam.g_kind = 'NGETS001'
      pParam.g_fnCd = 'email'
      pParam.emailType = 'entpl'
      pParam.g_ddsInfo = oDdsInfo

      this.lv_ParamForEmail = pParam
      // console.log('email send param ===> ', pParam)
      this.fn_OpenMSPPI253P() // 이메일발송 팝업 호출 함수
      // this.lv_ShowTSSPI073P = true
    },
    /************************************************************************************************
     * Function명  : fn_OpenMSPPI253P
     * 설명        : 이메일 전송 팝업 오픈
     ************************************************************************************************/
    fn_OpenMSPPI253P () {
      // 이메일 발송 전 안내 바텀시트 닫기
      this.fn_CloseBottomSheet('refPopEmailConfirmFlag')
      this.popupMSPPI253P = this.$bottomModal.open(MSPPI253P, {
        properties: {
          pPage: 'MSPPI253P',
          pIsBackKeyListen: true,
          param: this.lv_ParamForEmail,
        },
        listeners: {
          closePopup: async (result) => {
            await this.$bottomModal.close(this.popupMSPPI253P)
            if (result === true) { // 이메일 발송 성공
              this.fn_Command_P9()
            } else { // 이메일 발송 실패
              console.log('이메일 발송 실패')
            }
          },
          close: async (result) => {
            await this.$bottomModal.close(this.popupMSPPI253P)
            if (result === true) { // 이메일 발송 성공
              this.fn_Command_P9()
            } else { // 이메일 발송 실패
              console.log('이메일 발송 실패')
            }
          },
        }
      })
    },
    // 가설서 발행 이력(이메일) 저장
    fn_Command_P9 () {
      this.MODCEmailTransTotVO = {} // 초기화

      let tempEmailAddr = ''
      tempEmailAddr = this.MB_EmailAddr

      let _rltnrInfo = Gaf.getTObj('rltnrInfo')
      if (piCommonUtil.isEmpty(_rltnrInfo)) {
        return
      }
      let _rltnrInfo_11 = Gaf.getTObj('rltnrInfo').filter((item) => { return item.zzcontvInsrdCd === '11' })[0]

      let numArrCnt1 = this.resultHptsPblHstrListVO.PIHptsPblHstrVO.length
      let ArrDat = []

      for ( let i = 0; i < numArrCnt1; i++ ) {
        let tmpPIHptsPblHstrVO = this.resultHptsPblHstrListVO.PIHptsPblHstrVO[i]

        ArrDat[0] = this.headerVO.userEno // 컨설턴트사번
        ArrDat[1] = tempEmailAddr // 이메일주소
        ArrDat[2] = tmpPIHptsPblHstrVO.zaPrdtNm // 상품명
        ArrDat[3] = tmpPIHptsPblHstrVO.zaSrcFileNm // 원천파일명
        ArrDat[4] = _rltnrInfo_11.zaInsrdNm // 고객명
        ArrDat[5] = _rltnrInfo_11.zaAgtFileId // 채널고객id
      }

      this.MODCEmailTransTotVO.dmsgrEmailAddr = this.headerVO.emailAddr // 발신자이메일주소
      this.MODCEmailTransTotVO.dmsgrNm = this.headerVO.userNm // 발신자명
      this.MODCEmailTransTotVO.rcverEmailAddr = ArrDat[1]
      this.MODCEmailTransTotVO.rcverNm = _rltnrInfo_11.zaInsrdNm
      this.MODCEmailTransTotVO.emailTitl = '삼성생명 상품설명서(제안용)입니다.' // 이메일제목
      this.MODCEmailTransTotVO.emailCntnt = '삼성생명 상품설명서(제안용)입니다.' // 이메일내용
      this.MODCEmailTransTotVO.moblBusnScCd = 'PI' // 모바일업무구분코드
      this.MODCEmailTransTotVO.eltrnDoctScCd = '04' // 전자문서구분코드
      this.MODCEmailTransTotVO.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서 ID
      this.MODCEmailTransTotVO.eltrnDoctPblYmd = piCommonUtil.getCurrentDate() // 전자문서발행일자
      this.MODCEmailTransTotVO.trtrEno = this.headerVO.trtrEno // 컨설턴트 번호
      this.MODCEmailTransTotVO.reqSrvcNm = 'PIPE52100P' // 요청서비스명
      this.MODCEmailTransTotVO.eltrnDoctClsfCd = '01' // 전자문서분류코드
      this.MODCEmailTransTotVO.isuMthScCd = '01' // 교부방법구분코드
      this.MODCEmailTransTotVO.entplId = this.MB_strPlanlId // 가입설계ID

      let dCEmailTrnsFileVO = []
      let mODCEmailTrnsFileVO = {}
      mODCEmailTrnsFileVO.apndFilePathNm = this.pdfPath // 파일경로
      mODCEmailTrnsFileVO.apndFileNm = this.resultHptsPblHstrListVO.tbtPblNo + '.pdf' // 파일명
      dCEmailTrnsFileVO.push(mODCEmailTrnsFileVO)

      this.MODCEmailTransTotVO.dCEmailTrnsFileVO = dCEmailTrnsFileVO

      let commHeader = {
        eaiId: '',
        requestSystemCode: '',
        reqSrvcNm: 'DCEmailTransCtlImpl',
        reqMthdNm: 'eMailTransInsert',
        // targetSystemCode: 'sli.dc',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.eltrnsign.DCEmailTransTotVO',
        resVONm: 'sli.mo.vo.dc.eltrnsign.DCEmailTransTotVO'
      }

      piCommonUtil.invokeDirect(null, this.MODCEmailTransTotVO, this.fn_GetServiceDataResultHandler, 'P9', null, this, commHeader)
    },
    // 완료후 파일업로드
    // ====================================== 이메일 관련 함수 end ====================================== //
    /************************************************************************************************
    * Function명  : fn_Command_P8
    * 설명        : NAS 영역에 PDF 파일이 생성되어 있는 지 체크
    ************************************************************************************************/
    fn_Command_P8 (trnstType, firstYn) {
      // 전역변수에 거래 타입 저장
      this.checkIntervalType = trnstType

      // 최초라면 카운트를 0으로 초기화
      if (firstYn === true) {
        this.pollingCnt = 1
      } else {
        this.pollingCnt++
      }
      console.log('pollingCnt : ' + this.pollingCnt)

      let mODCDoctTrfoBasVO = {}
      if ( this.resultHptsPblHstrListVO.tbtPblKndNm === 'OFR' || this.resultHptsPblHstrListVO.tbtPblKndNm === 'ELTRNSIGN' ) {
        mODCDoctTrfoBasVO.moblBusnScCd = 'SE' // 청약서
        mODCDoctTrfoBasVO.eltrnDoctScCd = '01'
      } else if ( this.resultHptsPblHstrListVO.tbtPblKndNm === 'ENTPL' ) {
        mODCDoctTrfoBasVO.moblBusnScCd = 'PI' // 가설서
        mODCDoctTrfoBasVO.eltrnDoctScCd = '04'
      } else {
        mODCDoctTrfoBasVO.moblBusnScCd = 'SE'
        mODCDoctTrfoBasVO.eltrnDoctScCd = '01'
      }
      mODCDoctTrfoBasVO.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo

      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'DCDoctTrfoBasCtlImpl',
        reqMthdNm: 'chkDocsTrfo',
        // targetSystemCode: 'sli.dc',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.eltrnsign.DCDoctTrfoBasVO',
        resVONm: 'sli.mo.vo.dc.eltrnsign.DCDoctTrfoBasVO'
      }
      // debugger
      piCommonUtil.invokeDirect(null, mODCDoctTrfoBasVO, this.fn_GetServiceDataResultHandler, 'P8', this.fn_DataFaultHandler, this, commHeader)
    },
    fn_DataFaultHandler (result) {
      console.log('fn_DataFaultHandler Called', result)
    },
    /************************************************************************************************
    * Function명  : fn_Command_C2
    * 설명        : 재전송 기능으로 인해 추가 및 수정
    *               미사용
    ************************************************************************************************/
    fn_Command_C2 () {
      let moDCDocuMngVO = {}
      let moDCLmsSndTransVO = {}
      let suitaObjScCd = ''
      // LMS 관련 값 셋팅
      this.fn_SetCommandC2LmsObjValue()

      if (this.resendMode ) {
        // moDCDocuMngVO = FileCacheIOManager.getInstance().moResendPDFDataListVO.moDCDocuMngVO
      } else if ( this.rewriteMode ) {
        // moDCDocuMngVO = FileCacheIOManager.getInstance().moIncompletePDFDataListVO.moDCDocuMngVO
      } else {
        moDCDocuMngVO = this.fn_Command_C2_Sub()
      }

      // lmsSendComplete() 파라미터 통합 처리
      // 전자약관 LMS 전송 셋팅 03 과 04 일경우만 셋팅 한다.
      if (( this.lmsFstIsuMthScCd === '03' || this.lmsFstIsuMthScCd === '04') && this.lmsObject != null ) {
        moDCLmsSndTransVO = this.lmsSendComplete(false) // false로 하면 파라미터 셋팅값 전달

        suitaObjScCd = moDCDocuMngVO.suitaObjScCd // 변액여부
        moDCLmsSndTransVO.vainsPrdtYn = suitaObjScCd // 변액셋팅
        moDCDocuMngVO.dCLmsSndTransVO = moDCLmsSndTransVO
      }

      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        fnctScCd: 'C2',
        requestSystemCode: '',
        reqSrvcNm: 'DCEltrnSignCmpltTltCtlImpl',
        reqMthdNm: 'eltrnSignCmplt',
        // targetSystemCode: 'sli.dc',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.eltrnsign.DCDocuMngVO',
        resVONm: 'sli.mo.vo.dc.eltrnsign.DCDocuMngVO'
      }
      piCommonUtil.invokeDirect(null, moDCDocuMngVO, this.fn_GetServiceDataResultHandler, 'C2', null, this, commHeader)
    },
    /************************************************************************************************
    * Function명  : fn_SetCommandC2LmsObjValue
    * 설명        : fn_Command_C2() 재전송/재서명 LMS 처리 파라미터 값 셋팅
    *               미사용
    ************************************************************************************************/
    fn_SetCommandC2LmsObjValue () {
      let byteArr = []
      if ( this.lmsObject === null) {
        this.lmsObject = {}
      }

      if ( this.resendMode ) {
        let str = String(this.lmsObject['celNo']).replace(/\-/g, '')
        str = str + 'LMS'
        console.log(str)
        this.lmsObject.celNoHash = SHA256.computeDigest(byteArr) // 휴대폰 번호 Hash
      } else if ( this.rewriteMode ) {
        let str = String(this.lmsObject['celNo']).replace(/\-/g, '')
        str = str + 'LMS'
        console.log(str)
        this.lmsObject.celNoHash = SHA256.computeDigest(byteArr) // 휴대폰 번호 Hash
      }
    },
    /************************************************************************************************
    * Function명  : fn_Command_C2_Sub
    * 설명        :
    * fn_Command_C2_Sub - 재전송 기능으로 인해 fn_Command_C2 Function 분리
    * Cache에 저장될 데이터
    ************************************************************************************************/
    fn_Command_C2_Sub () {
      let mODCDocuMngVO = {}
      let prdtNm = '' // 상품명
      let byteArr = []
      let abdapolicyList = this.abdapolicy// Policy 정보 Array
      let abdapolicy = abdapolicyList[0] // 현재 Policy 정보
      let abdapolpr = abdapolicy.abdapolpr[0]
      let ztpqlCboextPrcsAbdapolpr = abdapolpr.ztpqlCboextPrcsAbdapolpr // 추가정보

      prdtNm = ztpqlCboextPrcsAbdapolpr.zaPrdtNm

      let _rltnrInfo = this.tmodel.rltnrInfo
      if (piCommonUtil.isEmpty(_rltnrInfo)) {
        return null
      }
      let _rltnrInfo_11 = _rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' } )[0]

      mODCDocuMngVO.moblBusnScCd = 'SE' // 모바일업무구분코드
      mODCDocuMngVO.eltrnDoctScCd = '01' // 전자문서구분코드
      mODCDocuMngVO.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서 ID
      mODCDocuMngVO.custNm = _rltnrInfo_11.zaInsrdNm // 고객명
      mODCDocuMngVO.rrno = _rltnrInfo_11.zzcustRrnId // 고객주민번호
      mODCDocuMngVO.prdtNm = prdtNm // 상품명
      mODCDocuMngVO.prcd = this.MB_prcdUniqKey // 약관조회용 Unique Key
      // mODCDocuMngVO.prcd = MB_prcd; // 상품코드
      // mODCDocuMngVO.prdtSaleBgnYmd = MB_prdtSaleBgnYmd; // 상품판매개시일자
      mODCDocuMngVO.regntEno = this.headerVO.userEno // 컨설턴트 번호
      mODCDocuMngVO.orgNo = this.headerVO.onpstFofOrgNo // 소속코드
      mODCDocuMngVO.eltrnDoctClsfCd = '01' // 전자문서분류코드 - 01:진본으로 세팅
      mODCDocuMngVO.eltrnDoctFileNm = this.resultHptsPblHstrListVO.tbtPblNo + '.pdf' // 전자문서파일명
      mODCDocuMngVO.eltrnDoctFilePathNm = 'file.path.ofr.as' // 전자문서파일경로
      mODCDocuMngVO.emailAddr = this.MB_EmailAddr // 이메일 주소
      mODCDocuMngVO.reqSrvcNm = 'PIPE52100P' // 화면명
      mODCDocuMngVO.reqShpScCd = '03' // 요청형태구분코드 : 03- 이메일

      // patch 2017.07.20 - LMS 전자약관 선택값 추가
      mODCDocuMngVO.lmsSlctCd = this.lmsYn

      this.lmsObject.custNm = _rltnrInfo_11.zaInsrdNm // 고객명
      this.lmsObject.prdtNm = // 상품명
      this.lmsObject.prcd = this.MB_prcdUniqKey // 상품코드
      this.lmsObject.chnlCustId = this.MB_contrChnlCustId // 채널고객ID
      // this.lmsObject.celNo = this.paramVO.celno // 고객 핸드폰
      this.lmsObject.celNo = this.lv_Celno // 고객 핸드폰
      this.lmsObject.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서 ID
      this.lmsObject.vuchNo = this.resultHptsPblHstrListVO.tbtPblNo // 영수증 번호

      let str = String(this.lmsObject['celNo']).replace(/\-/g, '')
      str = str + 'LMS'
      console.log(str)
      this.lmsObject.celNoHash = SHA256.computeDigest(byteArr) // 휴대폰 번호 Hash
      // mODCDocuMngVO.suitaObjScCd = _PICommonVO.pICommonVariable.STR_SuitaObjScCd; //적합성 대상구분 코드 - 변액 설명서 가 있는지 여부 판단용

      let suitaReportYN = '' // 적합성 보고서 발행대상
      // TODO : 100M의 Vmodel 을 가져와야함!
      // let _ZPPrdtEntInfoDto = Gaf.getVObj('ZPPrdtEntInfoDto')
      // if (piCommonUtil.isObj(_ZPPrdtEntInfoDto) && _ZPPrdtEntInfoDto.suitaInptYn === 'Y') {
      //   suitaReportYN = 'Y'
      // }

      /* if(["F1","F2","F3"].indexOf(gaf.gafObject.tmodel.baseContDto.zzentplLineCd) > -1){
        suitaReportYN = "Y";
      } */
      mODCDocuMngVO.suitaObjScCd = suitaReportYN // 적합성 대상구분 코드 - 변액 설명서 가 있는지 여부 판단용

      mODCDocuMngVO.clctCnsltNo = this.headerVO.userEno // 모집컨설턴트
      mODCDocuMngVO.trtrEno = this.headerVO.userEno // 처리자사번
      // mODCDocuMngVO.celno = this.paramVO.celno
      mODCDocuMngVO.celno = this.lv_Celno

      // 입력항목 데이터화 시작
      if (this.fstIsuMthScCd !== '' ) {
        let inputListData = JSON.parse(this.fstIsuMthScCd)

        mODCDocuMngVO.fstIsuMthScCd = inputListData.fstIsuMthScCd // 최초교부방법구분코드  :01(이메일), 02(서면) , 03(모바일 + Email) , 04(모바일)
        // fstIsuMthScCd.substring(0,2); //최초교부방법구분코드  :01(이메일), 02(서면)

        // 완료처리시 뷰어에서 SMS로 결과 받길 원하는 경우는 전화번호 세팅.. N 인경우는 전화번호 공백 세팅
        if (inputListData.celno !== '' ) { // as String == "Y") {
          if (this.paramVO.celno.substr(0, 3) === '082') {
            mODCDocuMngVO.celno = this.fn_EmptySpaceReplace(this.paramVO.celno).replaceAll(' ', '').substring(3) // 핸드폰번호
          } else {
            mODCDocuMngVO.celno = this.fn_EmptySpaceReplace(this.paramVO.celno).replaceAll(' ', '') // 핸드폰번호
          }
        } else {
          mODCDocuMngVO.celno = ''
        }
        /* if(fstIsuMthScCd.substring(2,3) == "Y") {
         mODCDocuMngVO.celno = StringUtil.replace(fn_EmptySpaceReplace(jobVO.celno), " ", "").substring(3);//직장전화번호
        } else {
        mODCDocuMngVO.celno = "";
        } */

        mODCDocuMngVO.secusCvancMthCd = inputListData.secusCvancMthCd // 증권전달방법코드
        mODCDocuMngVO.vainsRprtRcvMthCd = inputListData.vainsRprtRcvMthCd // 변액보고서수령방법코드
        mODCDocuMngVO.cmmuSaleRscCnsntYn = inputListData.cmmuSaleRscCnsntYn // 통신판매해지동의여부
        mODCDocuMngVO.cpdClasPblYn = inputListData.cpdClasPblYn // CD약관발행여부
        mODCDocuMngVO.hght = inputListData.hght // 신장
        mODCDocuMngVO.bwght = inputListData.bwght // 체중
        // 입력항목 데이터화 끝
      }

      // 202005 외화보험 통화키 추가
      mODCDocuMngVO.currencyId = this.$bizUtil.isEmpty(abdapolicy.currencyId) ? 'KRW' : abdapolicy.currencyId

      return mODCDocuMngVO
    },
    /************************************************************************************************
    * Function명  : fn_GetServiceDataResultHandler
    * 설명        :
    * 서비스 호출 결과 함수 정의
    *  e.result의 결과 데이터의 헤더정보를 가지고 호출서비스를 구분한다.
    * @param e.result 에 호출결과 데이터가 담겨있다
    * @moreData ServiceManager호출시 넣었떤 moreData가 그대로 넘어온다
    ************************************************************************************************/
    fn_GetServiceDataResultHandler ( result, moreData ) {
      let i = 0
      let lv_Vm = this
      if ( !result ) {
        return
      }

      switch ( moreData ) {
        case 'S1': // 발행목록 조회거래 처리
        case 'S2': // 가설 이메일 발송여부 조회
          this.resultMobileVO = result

          // 이메일 발송여부 조회 시 발송내역이 있으면 전역변수에 세팅
          if ( moreData === 'S2' ) {
            let _MODCEmailTransTotVO = result
            if ( _MODCEmailTransTotVO ) {
              this.entplTransYn = _MODCEmailTransTotVO.entplTransYn
            }
          }

          let resultAC = this.MOSEEntplPblItmListVO.PIEntplPblItmInfoVO
          if ( !resultAC ) {
            resultAC = []
          }

          // _MOSEEntplPblItmListVO.untySlmtId //원천파일명
          // _MOSEEntplPblItmListVO.untySlmtBasInfoNo

          // MB_strApndFileNmList = "";
          // MB_strcnsltEltrnOfrYn = _MOSEEntplPblItmListVO.cnsltEltrnOfrYn;//컨설턴트 전자청약여부
          // MB_strSalesVprYN = _MOSEEntplPblItmListVO.vainsUnQulfYn;//변액판매여부
          this.MB_strCnsltImsiYn = this.MOSEEntplPblItmListVO.tmpYn // 임시설계사여부

          // if (MB_strcnsltEltrnOfrYn == "Y") {
          // AlertMsg.show("컨설턴트 본인 계약으로 \n전자청약을 활용해 주시기 바랍니다.", "알림");
          // }

          let _PIOfrPblItmInfoVO = this.MOSEEntplPblItmListVO.PIOfrPblItmInfoVO // 청약 목록건수
          let numOfrArrCnt = this.MOSEEntplPblItmListVO.PIOfrPblItmInfoVO === null ? 0 : _PIOfrPblItmInfoVO.length // 응답 리스트 데이터 건수
          if ( numOfrArrCnt > 0 ) {
            if ( this.MOSEEntplPblItmListVO.PIOfrInfoVO ) {
              let homeVO = this.MOSEEntplPblItmListVO.PIOfrInfoVO.PIAddrInfoVO
              let jobVO = this.MOSEEntplPblItmListVO.PIOfrInfoVO.PIJobpInfoVO

              this.emailAddr = this.fn_EmptySpaceReplace( jobVO.emailAddr )

              // 집주소
              this.homFrnoPstcd = homeVO.homFrnoPstcd
              this.homBknoPstcd = homeVO.homBknoPstcd
              this.homAddr = homeVO.homAddr + ' ' + homeVO.homDtlad
              // 직장주소
              this.jobpFrnoPstcd = jobVO.jobpFrnoPstcd
              this.jobpBknoPstcd = jobVO.jobpBknoPstcd
              this.jobpAddr = jobVO.jobpAddr + ' ' + jobVO.dtlad

              if ( jobVO.celno.substr(0, 3) === '082') {
                this.paramVO.celno = this.fn_EmptySpaceReplace(this.paramVO.celno).replaceAll(' ', '').substring(3) // 핸드폰번호
              } else {
                this.paramVO.celno = this.fn_EmptySpaceReplace(this.paramVO.celno).replaceAll(' ', '') // 핸드폰번호
              }
              this.paramVO.emailAddr = this.emailAddr = this.fn_EmptySpaceReplace(jobVO.emailAddr) // 이메일
            }

            // 청약서 목록이 있고 발행이력있는 경우 주소영역 보여줌
            if ( this.MB_strHptsPblYn === 'Y' || this.entplTransYn === 'Y' ) {
              this.lv_Chk2 = true // 주소 화면 보이도록 처리
            }
          }
          break
        case 'P3':
          this.resultHptsPblHstrListVO = result
          this.fn_Command_P8( 'P3', true )
          break
        case 'P4':
          this.resultHptsPblHstrListVO = result
          this.fn_Command_P8( 'P4', true )
          break
        case 'MnlLMS':
          this.resultHptsPblHstrListVO = result
          if (process.env.NODE_ENV === 'local') {
            this.fn_SendMnlLMS()
          } else {
            this.fn_Command_P8( 'MnlLMS', true )
          }
          break
        case 'P5':
          // 결과 메세지 뿌려줄 배열 초기화
          this.rsltMsgArray = []
          this.resultMobileVO = result
          let _resultAC = this.resultMobileVO
          let _strTrnsRsltMsg = ''
          let _strMldrPrevCnfRsltMsg = ''
          let _strTrnsRsltCd = _resultAC.trnsRsltCd
          let _strMldrPrevCnfCd = _resultAC.mldrPrevCnfCd

          if ( _strTrnsRsltCd.trim() !== '' ) {
            if ( _strTrnsRsltCd !== 'Y' ) {
              _strTrnsRsltMsg = _resultAC.trnsRsltCntnt
              if ( _strTrnsRsltMsg.trim() !== '' ) {
                this.rsltMsgArray.push( _strTrnsRsltMsg )
              }
              this.fn_ShowAlertMsg( moreData, 'N' )
              break
            }
          }

          if ( _strMldrPrevCnfCd.trim() !== '' ) {
            if ( _strMldrPrevCnfCd !== 'Y' ) {
              _strMldrPrevCnfRsltMsg = _resultAC.mldrPrevCnfRsltCntnt
              if ( _strMldrPrevCnfRsltMsg.trim() !== '' ) {
                this.rsltMsgArray.push( _strMldrPrevCnfRsltMsg )
              }
              if ( _strMldrPrevCnfCd === 'N' ) {
                this.fn_ShowAlertMsg( moreData, 'N' )
                break
              }
            }
          }

          // 전자청약 관련 팝업
          let _numArrCnt1 = _resultAC.PIHptsPblHstrVO.length // 가입된 관계자 정보 응답 리스트 데이터 건수
          let _trnsRsltCntnt = ''

          for ( i = 0; i < _numArrCnt1; i++ ) {
            // 전송결과코드가 비정상의 경우, 전송결과내용을 설정한다.
            if ( ( _resultAC.PIHptsPblHstrVO[i].trnsRsltCd ) === 'N' ) {
              if ( !piCommonUtil.isEmpty(_trnsRsltCntnt) ) {
                _trnsRsltCntnt += '\n'
              }
              _trnsRsltCntnt += _resultAC.PIHptsPblHstrVO[i].trnsRsltCntnt
            }
          }

          // 전송결과내용이 있을 경우, 경고창을 표시한다.
          if ( !piCommonUtil.isEmpty( _trnsRsltCntnt ) ) {
            this.rsltMsgArray.push( _trnsRsltCntnt )
          }

          let strVuchNo = _resultAC.PIHptsPblHstrVO[0].planId

          if ( strVuchNo !== '' ) {
            this.rsltMsgArray.push('전자청약이 발행되었습니다.\n[영수증번호 : ' + strVuchNo + ']' )
          }

          this.fn_ShowAlertMsg( moreData, 'Y' )
          break
        case 'P2': // 미리보기는 전자서명과 동일하게 처리됨
        case 'P6':
        case 'P10': // #2020모바일청약, 모바일청약을 조건에 추가
          // 결과 메세지 뿌려줄 배열 초기화
          this.rsltMsgArray = []
          this.resultMobileVO = result
          if ( this.resultMobileVO ) {
            // 폴링용 전역변수에 실행결과 삽입
            this.resultHptsPblHstrListVO = this.resultMobileVO
            // console.log('this.resultHptsPblHstrListVO.tbtPblNo :::: ' , this.resultHptsPblHstrListVO.tbtPblNo)
            // console.log('this.resultHptsPblHstrListVO.planId :::: ' , this.resultHptsPblHstrListVO.planId)

            let numArrCnt2 = this.resultHptsPblHstrListVO.PIHptsPblHstrVO.length

            let objYN6 = this.resultHptsPblHstrListVO.objYn
            let rskPrpnsExcdYn6 = this.resultHptsPblHstrListVO.rskPrpnsExcdYn
            let dlExprShtHighRskYn6 = this.resultHptsPblHstrListVO.dlExprShtHighRskYn

            if ( objYN6 === 'Y' ) {
              if (rskPrpnsExcdYn6 === 'Y' || dlExprShtHighRskYn6 === 'Y') {
                this.rsltMsgArray.push('지점장 적합성 확인리스트 대상건으로</br>계약입력시 지점장의 승인처리가 필요합니다. </br>(지점장 사전 면담 필요) </br> </br> 부적합 보험계약 체결확인서 작성이 필요한 고객입니다.')
                // this.rsltMsgArray.push('지점장 적합성 확인리스트 대상건으로\n계약입력시 지점장의 승인처리가 필요합니다. \n(지점장 사전 면담 필요) \n \n 부적합 보험계약 체결확인서 작성이 필요한 고객입니다.')
              } else {
                this.rsltMsgArray.push('지점장 적합성 확인리스트 대상건으로</br>계약입력시 지점장의 승인처리가 필요합니다. </br>(지점장 사전 면담 필요)')
                // this.rsltMsgArray.push('지점장 적합성 확인리스트 대상건으로\n계약입력시 지점장의 승인처리가 필요합니다. \n(지점장 사전 면담 필요)')
              }
            }

            let trnsRsltCntnt6 = ''
            let dsclsTypChk6 = false

            for (let t = 0; t < numArrCnt2; t++ ) {
              // 전송결과코드가 비정상의 경우, 해당처리를 종료하고 전송결과내용을 설정한다.
              if ( ( this.resultHptsPblHstrListVO.PIHptsPblHstrVO[t].trnsRsltCd ) === 'N' ) {
                if ( !trnsRsltCntnt6.trim() === '' ) {
                  trnsRsltCntnt6 += '</br>'
                  // trnsRsltCntnt6 += '\n'
                }
                trnsRsltCntnt6 += this.resultHptsPblHstrListVO.PIHptsPblHstrVO[t].trnsRsltCntnt
                continue
              }

              this.MB_arrPblScNm[t] = this.resultHptsPblHstrListVO.PIHptsPblHstrVO[t].pblScNm // 발행구분명
              this.MB_arrSrcFileNm[t] = this.resultHptsPblHstrListVO.PIHptsPblHstrVO[t].srcFileNm // 원천파일명
              this.MB_strDsclsTyNm[t] = this.resultHptsPblHstrListVO.PIHptsPblHstrVO[t].dsclsTypNm // 고지유형명

              if ( this.MB_strDsclsTyNm[t] != null && this.MB_strDsclsTyNm[t] === '1' ) {
                dsclsTypChk6 = true
              }
            }

            if ( dsclsTypChk6 ) {
              this.rsltMsgArray.push( '연금형 알릴의무사항 불가, 일반형 사용 바랍니다' )
            }

            // 전송결과내용이 있을 경우, 경고창을 표시한다.
            if ( !piCommonUtil.isEmpty(trnsRsltCntnt6 )) {
              this.rsltMsgArray.push(trnsRsltCntnt6)
            }

            let strTrnsRsltMsg6 = ''
            let strMldrPrevCnfRsltMsg6 = ''

            let strTrnsRsltCd6 = this.resultHptsPblHstrListVO.trnsRsltCd.trim()
            let strMldrPrevCnfCd6 = this.resultHptsPblHstrListVO.mldrPrevCnfCd.trim()

            if (strTrnsRsltCd6 !== '' && strTrnsRsltCd6 !== 'Y') {
              strTrnsRsltMsg6 = this.resultHptsPblHstrListVO.trnsRsltCntnt

              if ( strTrnsRsltMsg6.trim() !== '' ) {
                this.rsltMsgArray.push(strTrnsRsltMsg6)
              }
              this.fn_ShowAlertMsg(moreData, 'N')
              break
            }

            if (strMldrPrevCnfCd6 !== '' && strMldrPrevCnfCd6 !== 'Y') {
              strMldrPrevCnfRsltMsg6 = this.resultHptsPblHstrListVO.mldrPrevCnfRsltCntnt

              if ( strMldrPrevCnfRsltMsg6.trim() !== '' ) {
                this.rsltMsgArray.push( strMldrPrevCnfRsltMsg6 )
              }
            }

            if ( this.sandType === '가설' ) {
              this.lv_Pdf_code = result.tbtPblNo
            } else if ( this.sandType === '청약' ) {
              this.lv_Pdf_code = result.tbtPblNo
            }

            // 전자서명시 발행 목록에 헬스케어서비스 신청서가 포함된 경우 메세지 추가
            if ( moreData === 'P6' ) {
              for (let k = 0; k < numArrCnt2; k++ ) {
                if ( this.resultHptsPblHstrListVO.PIHptsPblHstrVO[k].pblObjScCd === '105') {
                  this.rsltMsgArray.push('\'헬스케어 서비스 신청서\'는 서면으로 작성이 필요합니다.')
                  // this.rsltMsgArray.push('/\'헬스케어 서비스 신청서/\'는 서면으로 작성이 필요합니다.')
                }
              }
            }
            this.fn_ShowAlertMsg(moreData, 'Y') // 핵심로직이 들어있음
            break
          }
          break
        case 'P7':
          this.resultMobileVO = result
          if ( this.resultMobileVO ) {
            let _resultACP7 = this.resultMobileVO
            if ( _resultACP7 !== null && _resultACP7.rsltCd === '00') {
              // this.lv_ShowErrorPopup = true
              this.lv_AlertMsg = 'E-mail이 정상적으로 발송되었습니다.'
              this.fn_ShowErrorMsg()
            } else {
              // this.lv_ShowErrorPopup = true
              this.lv_AlertMsg = 'E-mail 발송 중 에러가 발생했습니다.'
              this.fn_ShowErrorMsg()
            }
          } else {
            // this.lv_ShowErrorPopup = true
            this.lv_AlertMsg = 'E-mail 발송 중 에러가 발생했습니다.'
            this.fn_ShowErrorMsg()
          }
          break
        case 'P8': // lms
          this.resultMobileVO = result
          // 결과 처리하며 clearInterval 하기전에 날린 결과가 또 왔을떄 같은결과를 두번 처리하는 일이 없게 방지
          if ( this.resultMobileVO ) {
            let _resultACP8 = this.resultMobileVO
            if ( _resultACP8 !== null ) {
              if ( _resultACP8.trfoCmpltYn === 'Y' || (process.env.NODE_ENV === 'local' && this.checkIntervalType === 'MnlLMS')) {
                if ( _resultACP8.trfoErrcdCntnt === '0000' || (process.env.NODE_ENV === 'local' && this.checkIntervalType === 'MnlLMS')) {
                  window.vue.getStore('progress').dispatch('HIDE')
                  // PDF 변환 성공 시  case 별 처리
                  switch ( this.checkIntervalType ) {
                    case 'P4':
                    {
                      this.fn_PopEmailConfirm()
                      break
                    }
                    case 'MnlLMS':
                    {
                      this.fn_SendMnlLMS()
                      break
                    }
                    // P2
                    case 'P2':
                    {
                      this.fn_PdfDownLoad()
                      break
                    }
                    case 'P6': // 전자서명용 발행전문 조회
                    {
                      break
                    }
                    case 'P3':
                    {
                      this.fn_PdfDownLoad()
                      break
                    }
                    case 'P44': // [비대면화상상담시스템구축PJT]2021.06.22
                    {
                      this.fn_InsNofacCnslData(result)
                      break
                    }
                    default:
                    {
                      console.log('잘못된 수행 입니다')
                      break
                    }
                  }
                } else {
                  // this.lv_ShowErrorPopup = true
                  this.lv_AlertMsg = _resultACP8.trfoErrcdCntnt
                  this.fn_ShowErrorMsg()
                }
              } else {
                if ( this.pollingCnt >= 20 ) {
                  if ( // this.checkIntervalType === 'P6' || -- 보험거래문서디지털화 : 전자서명은 PDF 변환 체크 제외
                    this.checkIntervalType === 'P2' ||
                        this.checkIntervalType === 'P4' ||
                        this.checkIntervalType === 'MnlLMS' || this.checkIntervalType === 'P44') { // 비대면화상상담(P44)
                    this.lv_ShowRePreviewMsg = 'PDF 변환작업이 아직 완료되지 않았습니다. 다시 요청하시겠습니까?'
                    this.$refs.refShowRePreview.open()
                    // this.lv_ShowRePreview = true
                    window.vue.getStore('progress').dispatch('HIDE')
                  } else {
                  }
                } else {
                  window.vue.getStore('progress').dispatch('SHOW')
                  setTimeout( function () {
                    lv_Vm.fn_Command_P8(lv_Vm.checkIntervalType, false)
                  }, 2000)
                }
              }
            }
          }
          break
        case 'C2':
          this.resultMobileVO = result
          if ( this.resultMobileVO ) {
            let mODCDocuMngVOC2 = this.resultMobileVO
            let rsltStr = '' // 알림 메세지 내용
            if ( mODCDocuMngVOC2 !== null && mODCDocuMngVOC2.wkRslt === 0) {
              if ( mODCDocuMngVOC2.wkRslt2 === 97 ) {
                rsltStr = '약관이 첨부되지 않았습니다.'
              } else if ( mODCDocuMngVOC2.wkRslt2 === 98 ) {
                rsltStr = '변액 운용 설명서가 첨부되지 않았습니다.'
              } else if ( mODCDocuMngVOC2.wkRslt2 === 99 ) {
                rsltStr = '약관과 변액 운용 설명서가  첨부되지 않았습니다.'
              } else {
                rsltStr = '정상적으로 처리되었습니다. \n /\'확인/\' 버튼을 눌러 주시기 바랍니다.'
              }
              console.log('rsltStr', rsltStr)
              // 전자서명 정상 완료시 완료 이미지 디스플레이
              // AlertMsg.show(rsltStr, "알림", AlertMsg.OK, this,
              // function(event:CloseEvent):void{
              // this.showSignCompleteLayout();

              // if ( this.lmsFstIsuMthScCd === '03' || this.lmsFstIsuMthScCd === '04' ) {
              // this.fn_AlertLmsComplete( mODCDocuMngVOC2.DCLmsSndTransVO )
              // } else if ( _isMoblPftSale === false && lmsFstIsuMthScCd === '01' ) { //완전판매가 아니고 01 일때(휴대전화번호 있을수도 있고 없을수도 있음)
              // //LMS를 보낼수 있는지 휴대전화번호 체크
              // let tempCelNo = StringUtil.replace( this.fn_EmptySpaceReplace( mODCDocuMngVOC2.celno ), ' ', '')
              // if ( tempCelNo !== '' ) {
              // this.lmsSendComplete(true)
              // }
              // }
              // }
              // );
            } else {
              // AlertMsg.show( '파일 처리 중 에러가 발생했습니다. 다시 시도하시겠습니까?', '알림', AlertMsg.YES|AlertMsg.NO,null,//...다시 시도하시겠습니까?
              // function(event:CloseEvent):void{
              // if(event.detail == AlertMsg.YES){
              // // reTryYn = "Y";
              // this.command_C1();
              // } else {
              // // reTryYn = "N";
              // }
              // /* if(!resendMode){//중간저장시는 무조건 reTryYn = "Y"
              // retryData.retryYN = "Y";
              // FileCacheIOManager.getInstance().retryDataFunction(saveCachePath,retryData);
              // }else{
              // FileCacheIOManager.getInstance().moResendPDFDataListVO.retryYN = "Y";
              // FileCacheIOManager.getInstance().retryDataFunction(saveCachePath,FileCacheIOManager.getInstance().moResendPDFDataListVO);
              // } */
              // }
              // )
            }
          }
          break
        case 'P9': // 이메일 발송 팝업에서 발송 성공 후 이력 저장
          this.resultMobileVO = result
          // NEXT 사랑On : 이메일 발송 후 버튼 disabled 해제
          this.lv_EltrnSignBtnDisable = false
          if ( this.resultMobileVO ) {
            let _resultACP9 = this.resultMobileVO
            if ( _resultACP9 !== null && _resultACP9.rsltCd === '00' ) {
              // this.creationCompleteHandler() // dummyEvent) - 사랑온에서 화면 복귀를 위한 코드로 모바일 영업에서는 필요 없음
              console.log('fn_Command_P9 처리 결과 : ', _resultACP9)
            }
          }
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_PdfDownLoad
     * 설명        : PDF 를 다운 받기 위해 값 설정
     ************************************************************************************************/
    async fn_PdfDownLoad () {
      let dateFolder = piCommonUtil.getCurrentDate()
      let path = this.resultMobileVO.eltrnDoctFilePathNm //  : "file.path.ofr.bs"
      let filename = dateFolder + '/' + this.resultMobileVO.eltrnDoctFileNm + '.pdf' // 저장파일명

      let localpath = '/' + this.LOCAL_PATH_PDF_DOWNLOAD // 로칼 파일 경로
      let localfilename = this.resultMobileVO.eltrnDoctFileNm + '.pdf' // 로칼 파일명
      this.lv_PreView_PDF_FileNm = localfilename

      let obj = {
        dnpath: path,
        dnfileNm: filename,
        localpath: localpath,
        localfileNm: localfilename
      }
      // IOS일 경우 청약서만
      // filename(암호화된 값 넘기기)
      if (this.$commonUtil.isMobile() && this.$commonUtil.getIsIOS() && this.currTab === '2') { // IOS일때는 암호화
        let serverTime = ''
        let encData = ''
        await this.$commonUtil.getServerTime().then((response)=> {
          serverTime = response
        }).finally (async()=>{
          encData = await this.$commonUtil.getEncryptCall(this, [filename, serverTime])
          obj.dnfileNm = encData[0]
          obj.encType = encData[1]
          // console.log('암호화된 정보 ===>', obj.dnfileNm, ' / ', obj.encType)
        })
        // encData = await this.$commonUtil.getEncryptCall(this, [filename, serverTime])
      }
      // console.log('fn_PdfDownLoad ====> ', obj)
      this.fn_LoadAndSaveFile(obj)
    },
    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    fn_LoadAndSaveFile (obj) {
      window.vue.getStore('progress').dispatch('SHOW')
      this.fn_FdpSaveFile(obj).then(resolve => {
        console.log('파일 다운로드 성공 : ' + resolve)
        obj.resolveFileNm = resolve
        this.fn_FdpOpenFile(obj).then(resolve => {
          console.log('파일 오픈 성공 : ' + resolve)
        }, reject => {
          console.log('파일 오픈 실패 : ' + reject)
        }).catch(error => {
          console.log(new Error(error))
        }).finally(() => {
          window.vue.getStore('progress').dispatch('HIDE')
          // NEXT 사랑On : 버튼 disabled 해제
          this.lv_EltrnSignBtnDisable = false
        })
      }, reject => {
        console.log('파일 다운로드 실패 : ' + reject)
        window.vue.getStore('progress').dispatch('HIDE')
      }).catch(error => {
        console.log(new Error(error))
      })
    },
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      // IOS일 경우
      // filename(암호화된 값 넘기기)
      // type: 날짜정보 utils.getServerTime(txTSSBC13S9) => 암호화
      let fileType = this.$commonUtil.isMobile() && this.$commonUtil.getIsIOS() && this.currTab === '2' ? obj.encType : 'pdf'
      console.log('fn_FdpSaveFile ===> ', fileType)
      return new Promise((resolve, reject) => {
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.dnpath,
          filename: obj.dnfileNm, // src Directory + fileName
          type: fileType, // obj.apndFileCntnt.substring(obj.apndFileCntnt.lastIndexOf('.') + 1, obj.apndFileCntnt.length),
          // type: 'pdf', // obj.apndFileCntnt.substring(obj.apndFileCntnt.lastIndexOf('.') + 1, obj.apndFileCntnt.length),
          localpath: obj.localpath, // '/sli/tss/comm/notice', // local Directory
          localfilename: obj.localfileNm, // local fileName
          rename: 'Y'
        }, (result) => {
          console.log('파일 다운로드 성공 콜백 : ' + result.data)
          resolve(result.data)
        }, (result) => {
          console.log('파일 다운로드 실패 콜백 : ' + result.data)
          reject(result.data)
        })
      })
    },
    /******************************************************************************
    * Function명 : fn_FdpOpenFile
    * 설명       : 다운로드 파일을 오픈한다.
    *              menu(1.문자발송, 2.이메일발송, 3.인쇄, 4.스크랩)
    ******************************************************************************/
    fn_FdpOpenFile (obj) {
      console.log('this.pdtmannualproposal::' + this.pdtmannualproposal)
      // console.log(`fn_FdpOpenFile Info - path : ${this.LOCAL_PATH_PDF_DOWNLOAD} / file : ${this.lv_PreView_PDF_FileNm}tssB${this.lv_PreView_PDF_FileNm}`)
      return new Promise((resolve, reject) => {
        window.fdpbridge.exec('callPdfViewerPlugin', {
          path: this.LOCAL_PATH_PDF_DOWNLOAD, // '/sli/tss/pdf',
          file: this.lv_PreView_PDF_FileNm + 'tssB' + this.lv_PreView_PDF_FileNm,
          menu: '0000', // Next 사랑On : 기존 5개 메뉴 -> 4개 메뉴로 변경
          // menu: '00000',
          enckey: this.lv_EncrKey,
          encyn: this.pdtmannualproposal ? 'N' : 'Y'
        }, function (result) {
        // 성공콜백
          console.log('파일 미리보기 열기 성공 : ' + result.data)
          resolve(result.data)
        }, function (result) {
        // 실패콜백
          console.log('파일 미리보기 열기 실패 : ' + result.data)
          reject(result.data)
        })
      })
    },
    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담시스템구축PJT]2021.06.22 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData (result) {
      window.vue.getStore('progress').dispatch('SHOW')
      // window.vue.getStore('progress').dispatch('UPDATE', true)
      let lv_Vm = this
      let pParams = {}
      let ctNofacCnslListVO = []
      let pParam = {}
      pParam.nofacChnlCustId = lv_Vm.MB_contrChnlCustId // 비대면채널고객ID
      pParam.nofacCnslDoctId = result.eltrnDoctId // 비대면상담문서ID
      pParam.nofacCnslBusnScCd = 'PI' // 비대면업무구분코드(가입설계:PI)
      pParam.nofacCnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userId // 비대면컨설턴트ID
      pParam.doctDeltYn = ' ' // 문서삭제여부
      // let ZPIHptsPblHstrDto = lv_Vm.resultHptsPblHstrListVO.PIHptsPblHstrVO[0]
      // pParam.nofacCnslDoctNm = ZPIHptsPblHstrDto.zaPrdtNm // 비대면상담문서명(상품명)
      pParam.nofacCnslDoctNm = lv_Vm.pmodel.abdapolicy[0].abdapolpr[0].ztpqlCboextPrcsAbdapolpr.zaPlanNm // 비대면상담문서명(설계명)
      pParam.nofacCnslDoctPathNm = result.eltrnDoctFilePathNm // 비대면상담문서경로명
      ctNofacCnslListVO.push(pParam)
      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      let trnstId = 'txTSSCT80S1'
      let auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_Vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                // lv_Vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                let custInfo = {}
                custInfo = lv_Vm.tmodel.rltnrInfo.filter((item) => { return item.zzcontvInsrdCd === '11' })[0]
                // let dob = lv_Vm.$bizUtil.addBirthFrontYear((custInfo.zzcustRrnId).substring(0, 6)) // 생년월일
                lv_Vm.lv_NofacCnslCust = {}
                lv_Vm.lv_NofacCnslCust.custNm = custInfo.zaInsrdNm // 검색고객명
                lv_Vm.lv_NofacCnslCust.chnlCustId = lv_Vm.MB_contrChnlCustId // 채널고객ID
                // let age = lv_Vm.$bizUtil.addBirthFrontYear((custInfo.zzcustRrnId).substring(0, 6)) // 생년월일
                // lv_Vm.lv_NofacCnslCust.age = lv_Vm.$bizUtil.getAge(age.substring(2)) + '세' // 고객 나이
                lv_Vm.lv_NofacCnslCust.age = DateUtil.getInsuranceAge(custInfo.zzcustRrnId, moment(new Date().toISOString()).format('YYYYMMDD')) + '세' // 보험나이
                lv_Vm.fn_OpenMSPSA063P()
                // lv_Vm.isTSSCT601P = true
              }
            } else {
              // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('UPDATE', false)
    },
    /************************************************************************************************
    * Function명  : fn_OpenMSPSA063P
    * 설명        : 화상상담 자료 팝업
    ************************************************************************************************/
    fn_OpenMSPSA063P () {
      
      // 화상상담 팝업에서 AOS 물리 백버튼 시 인지할 수 없기 때문에 팝업 오픈 전 버튼 disalbed 해제 처리
      // NEXT 사랑On : 버튼 disabled 해제
      this.lv_EltrnSignBtnDisable = false

      this.popupMSPSA063P = this.$bottomModal.open(MSPSA063P, {
        properties: {
          pNofacCnslCust: this.lv_NofacCnslCust
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.popupMSPSA063P)
            // NEXT 사랑On : 버튼 disabled 해제
            this.lv_EltrnSignBtnDisable = false
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.popupMSPSA063P)
            // NEXT 사랑On : 버튼 disabled 해제
            this.lv_EltrnSignBtnDisable = false
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    // ====================================== LMS 관련 함수 start ====================================== //
    /************************************************************************************************
    * Function명  : lmsSendComplete
    * 설명        :
    * fn_Command_C2() 에서 lmsFstIsuMthScCd 03,04일때 호출  MODCLmsSndTransVO 리턴값 사용
    * 완전판매 대상이 아니고 01 일경우 별도 호출 서비스
    * 전자교부 개선으로 파라미터 셋팅 리턴  기능 함수로 변경됨
    * 미사용
    ************************************************************************************************/
    lmsSendComplete ( isServiceCall ) {
      // 전자약관 LMS 전송 여부(추가 2017.05.15 patch)
      let moDCLmsSndTransVO = {}

      if ( this.lmsYn === '1') {
        // DB저장후 홈페이지 거시기 작업
        if ( this.lmsObject !== null ) {
          moDCLmsSndTransVO.chnlCustId = this.lmsChlCustId // 채널고객ID
          moDCLmsSndTransVO.eltrnDoctId = this.lmsObject.eltrnDoctId // 전자문서ID
          moDCLmsSndTransVO.vuchNo = this.lmsObject.vuchNo // 영수증번호
          moDCLmsSndTransVO.smsCelno = this.lmsObject.celNo // 휴대폰번호
          moDCLmsSndTransVO.smsCelnoHash = this.lmsObject.celNoHash // 휴대폰번호 Hash

          // 출금동의서 존재여부(후입금 선택여부): 0 미선택, 1 선택
          if ( this.zzaftRctmFg === '1' ) {
            moDCLmsSndTransVO.zzaftRctmFg = 'Y'
          } else {
            moDCLmsSndTransVO.zzaftRctmFg = 'N'
          }
        }

        if ( isServiceCall ) {
          let commHeader = {
            eaiId: '',
            fahrTrnId: '',
            fnctScCd: 'S1',
            requestSystemCode: '',
            reqSrvcNm: 'DCLmsSndCtlImpl',
            reqMthdNm: 'insLms',
            // targetSystemCode: 'sli.dc',
            targetSystemCode: 'sli.fom',
            reqVONm: 'sli.mo.vo.dc.eltrnsign.DCLmsSndTransVO',
            resVONm: 'sli.mo.vo.dc.eltrnsign.DCLmsSndTransVO'
          }
          piCommonUtil.invokeDirect(null, moDCLmsSndTransVO, this.fn_GetServiceLmsDataResultHandler, 'S1', null, this, commHeader)
        }
      }
      return moDCLmsSndTransVO
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPPI252P
    * 설명       : 상품설명서(제안서) LMS 전송 내용입력 팝업 [확인] 후 처리
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_ConfirmMSPPI252P (result) {
      // Next 사랑On 미사용 : fn_OpenMSPPI252P > evtConfirmMSPPI252P 대체
      // this.fn_PrcsEltrnService('MnlLMS')
      this.lv_preface = result
      window.vue.getStore('progress').dispatch('SHOW')
      // this.fn_PrcsEntplEltrnSignNext()
      this.fn_Command_P8(this.checkIntervalType, true)
    },
    fn_RetryCheckNas () {
      // this.lv_ShowRePreview = false
      this.$refs.refShowRePreview.close()
      this.fn_Command_P8(this.checkIntervalType, true)
    },
    fn_CancelCheckNas () {
      // this.lv_ShowRePreview = false
      // NEXT 사랑On : 버튼 disabled 해제
      this.lv_EltrnSignBtnDisable = false
      this.$refs.refShowRePreview.close()
    },
    fn_CancelprcsEntplEltrnSign () {
      // this.lv_PrcsEntplEltrnSignAlertFlag = false
      this.$refs.refPrcsEntplEltrnSignAlertFlag.close()
    },
    
    // fn_CloseTSSPI073P (result) {
    //   // console.log('fn_CloseTSSPI073P result : ', result)
    //   this.lv_ShowTSSPI073P = false
    //   if (result === true) { // 이메일 발송 성공
    //     this.fn_Command_P9()
    //   } else { // 이메일 발송 실패
    //     console.log('이메일 발송 실패')
    //   }
    // },
    // fn_ConfirmAlertPopup () {
    //   this.lv_ShowErrorPopup = false
    // },
    // 이메일 전송 완료 후 팝업 처리 - 이메일 전송 결과 저장 요청 처리 수행
    // fn_ConfirmSendEmailPopup () {
    //   this.lv_SuccessSendEmailFlag = false
    //   this.fn_Command_P9()
    // },
    // --------------------------------------------------------------------------------------------
    // 지급정보 안내 관련 --------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------
    /************************************************************************************************
     * Function명  : fn_PymInfoIapConfirm
     * 설명        : 고객 지급정보 안내톡 발송 팝업 띄우기
     *               2022.07.29_ASR220600568_[사랑on 가설] 지급정보 관련 알림톡 발송
     *               발행목록 조회 서비스 (F1PQL0395) 에서 지급정보 관련 데이터를 같이 받아옴
     *                   - ZPIEntplPblItmListDto.pymInfoObjYn // 모바일지급조회가능여부(Y/N)
     *                   - ZPIEntplPblItmListDto.zMobspSendPymDto //모바일지급조회 발송데이터 Array
     * 작성자      : youngki78.lee
     ************************************************************************************************/
    fn_PymInfoIapConfirm () {
      if (piCommonUtil.isEmpty2(this.MB_zMobspSendPymDto) ||
       piCommonUtil.isEmpty2(this.MB_zMobspSendPymDto.contPtcpTelno) ||
       piCommonUtil.isEmpty2(this.MB_zMobspSendPymDto.contPtcpTelnoEncr)) {
        this.lv_AlertMsg = '전송 가능한 휴대전화번호가 없습니다.\n고객카드 등록 후 이용 바랍니다.'
        this.fn_ShowErrorMsg()
        // this.lv_ShowErrorPopup = true
        return
      }
      let custNm = this.MB_zMobspSendPymDto.mobslChnlCustNm
      let celno = this.MB_zMobspSendPymDto.contPtcpTelno

      // this.lv_ShowPymInfoIapMsg = '당타사 지급정보를 고객이 직접 확인 할 수 있도록\n' +
      // '고객 핸드폰으로 알림톡을 전송합니다.\n\n' +
      // '필수컨설팅 동의가 확보된 고객 본인만 조회할 수 있으며\n' +
      // '컨설턴트 등 제3자는 조회 불가합니다.\n\n' +
      // '컨설턴트는 고객 상담과정에서 취득한 정보를\n' +
      // '제공받은 목적 외로 이용하거나 제3자에게 누설할 수 없습니다.\n\n' +
      // '아래 휴대폰 번호 확인 후 전송바랍니다.\n' +
      // // '　고객명　<span style="color: #0066CC; font-weight: bold;">' + custNm +
      // // '</span>\n　휴대폰번호　<span style="color: #0066CC; font-weight: bold;">' + celno + '\n　</span>'
      // '<li style="display: flex;"><p>　고객명\n　휴대폰번호</p><p style="color: #0066CC; font-weight: bold; padding-left: 20px;">' + custNm +
      // '\n' + celno + '</p></li>'
      this.lv_ShowPymInfoIapMsg = {custNm: custNm, celno: celno}
      // this.lv_ShowPymInfoIapFlag = true
      // this.lv_isPymInfoIapFlag = true // contents
      this.$refs.refPymInfoIapFlag.open()
      console.log('고객 지급정보 안내톡 발송 Message : ' + this.lv_ShowPymInfoIapMsg )
    },
    /************************************************************************************************
     * Function명  : fn_SendPymInfoIap
     * 설명        : 고객 지급정보 안내톡 발송
     *               2022.07.29_ASR220600568_[사랑on 가설] 지급정보 관련 알림톡 발송
     * 작성자      : youngki78.lee
     ************************************************************************************************/
    fn_SendPymInfoIap () {
      window.vue.getStore('progress').dispatch('SHOW')
      // window.vue.getStore('progress').dispatch('ADD')
      piCommonUtil.invoke('URLPQL00426', this.MB_zMobspSendPymDto, this.fn_SendPymInfoIapResult, null, this.fn_SendPymInfoIapResultFault, this, this)
      console.log('F13650004 param: ' + this.MB_zMobspSendPymDto )
    },
    /************************************************************************************************
     * Function명  : fn_SendPymInfoIapResult
     * 설명        : 고객 지급정보 안내톡 발송 응답
     *               2022.07.29_ASR220600568_[사랑on 가설] 지급정보 관련 알림톡 발송
     * 작성자      : youngki78.lee
     ************************************************************************************************/
    fn_SendPymInfoIapResult (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('SUB')
      if (piCommonUtil.isObj(result)) {
        if (result.successYn === 'Y') {
          this.lv_AlertMsg = '고객지급정보 전송 되었습니다.'
          // this.lv_ShowErrorPopup = true
          this.fn_ShowErrorMsg()
          // u5.alert( {message : "고객지급정보 전송 되었습니다."} );
        } else {
          this.lv_AlertMsg = '고객지급정보 전송 실패하였습니다.'
          // this.lv_ShowErrorPopup = true
          this.fn_ShowErrorMsg()
          // u5.alert( {message : "고객지급정보 전송 실패하였습니다. "} );
        }
      } else {
        this.lv_AlertMsg = '알림 톡 발송 서버의 응답이 없습니다.</br>result: ' + result
        // this.lv_AlertMsg = '알림 톡 발송 서버의 응답이 없습니다.\nresult: ' + result
        // this.lv_ShowErrorPopup = true
        this.fn_ShowErrorMsg()
      }
      console.log('F13650004 result : ' + result )
    },
    fn_SendPymInfoIapResultFault (result) {
      window.vue.getStore('progress').dispatch('HIDE')
      // window.vue.getStore('progress').dispatch('SUB')
      this.lv_AlertMsg = '알림 톡 발송 서버의 응답이 없습니다.</br>result: ' + result
      // this.lv_AlertMsg = '알림 톡 발송 서버의 응답이 없습니다.\nresult: ' + result
      // this.lv_ShowErrorPopup = true
      this.fn_ShowErrorMsg()
      console.log('F13650004 ## Fault ## result : ' + result )
    },
    /******************************************************************************
    * Function명 : fn_CheckAndStopPropa
    * 설명       : FDP infinite 안에서 row 클릭시 disable 된 것을 체크하고 propagation 막기
    ******************************************************************************/
    fn_CheckAndStopPropa (item, event) {
      if (this.fn_DisableCheck(item) && event) {
        event.stopPropagation()
      }
    },
    /******************************************************************************
    * Function명 : fn_CheckAndStopPropa_1
    * 설명       : FDP infinite 안에서 row 클릭시 disable 된 것을 체크하고 propagation 막기
    ******************************************************************************/
    fn_CheckAndStopPropa_1 (item, event) {
      if (this.fn_DisableCheck_1(item) && event) {
        event.stopPropagation()
      }
    },
    /************************************************************************************************
     * Function명  : fn_DisableCheck
     * 설명        : check box 선택한 값이 개별적으로 선택할 수 있는 지 판단
     ************************************************************************************************/
    fn_DisableCheck ( item ) {
      // 185 간편치아(pvm_noticeKDT) / [ASR220200903]
      // 312 외화적합성 위험성향진단결과 필수 서류 반영 (ASR220400623_외화 적합성 관련 사랑온)
      // 200~205 AML 신규양식 / (ASR230800828_231103)
      // 문서번호  양식명              문서명
      // 200    pvm_custkycctr   개인 고객정보확인서_보험청약 계약자용
      // 201    pvm_custkycprx   개인 고객정보확인서_보험청약 대리인용 
      // 202    pvm_custkycben   개인 고객정보확인서_보험청약 수익자용
      // 203    pvm_entkyc       법인단체 고객정보확인서_보험청약용
      // 204    pvm_PEPcont      정치적 주요인물(PEP)추가 확인서(계약자용)
      // 205    pvm_PEPbene      정치적 주요인물(PEP)추가 확인서(수익자용)
      let sno = item.zaSno
      if (sno === '102' || sno === '103' || sno === '109' || sno === '136' || sno === '140' || sno === '142' || sno === '143' || sno === '145' || sno === '146' || sno === '147' || sno === '148' || sno === '151' || sno === '152' || sno === '153' || sno === '160' || sno === '172' || sno === '173' || sno === '180' || sno === '190' || sno === '185' || sno === '312' ||
          sno === '200' || sno === '201' || sno === '202' || sno === '203' || sno === '204' || sno === '205' ) {
        return true
      } else {
        return false
      }
    },
    /************************************************************************************************
     * Function명  : fn_ClickRowItem
     * 설명        : 청약서 row선택 시 함수
     ************************************************************************************************/
    fn_ClickRowItem (item) {
      if (!this.fn_DisableCheck(item)) {
        item.checked = !item.checked
      }
    },
    /************************************************************************************************
     * Function명  : fn_DisableCheck_1
     * 설명        : 선택발행 목록 check box 선택한 값이 개별적으로 선택할 수 있는 지 판단
     ************************************************************************************************/
    fn_DisableCheck_1 ( item ) {
      // 선택발행 필수출력건은 체크해제 불가
      if (item.zaPblCntnt && item.zaPblCntnt.indexOf('필수출력') > -1 ) {
        return true
      } else {
        return false
      }
    },
    /************************************************************************************************
     * Function명  : fn_ClickRowItem1
     * 설명        : 상품설명서 row선택 시 함수
     ************************************************************************************************/
    fn_ClickRowItem1 (item) {
      if (!this.fn_DisableCheck_1(item)) {
        item.checked = !item.checked
      }
    },
    /************************************************************************************************
     * Function명  : fn_CheckCustInfo
     * 설명        : 임시고객 or 가상고객 체크하여 리턴해준다
     ************************************************************************************************/
    fn_CheckCustInfo (type) {
      let tmpZzilsITCLVl = ''
      let abdapolicy = Gaf.getGafObjByRefId('POLICY')
      if ( abdapolicy !== undefined && piCommonUtil.isObj(abdapolicy) &&
      abdapolicy[0].abdapolpr !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr) &&
      abdapolicy[0].abdapolpr[0].aldapolpr !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr[0].aldapolpr) &&
      abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl !== undefined && piCommonUtil.isObj(abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl)) {
        tmpZzilsITCLVl = abdapolicy[0].abdapolpr[0].aldapolpr.zzilsITCLVl
      }
      let typeMsg = ''
      typeMsg = type === 'email' ? 'E-mail' : type === 'lms' ? 'LMS' : ''
      if (this.MB_vCustYn || (this.MB_strTmpCustYn === 'Y' && tmpZzilsITCLVl === '04')) {
        this.lv_AlertMsg = `임시고객, 가상고객은 ${typeMsg}전송기능을 지원하지 않습니다.</br>임시고객은 고객카드에서 주민번호 뒷6자리를 000000으로 입력한 고객을 의미합니다.`
        this.fn_ShowErrorMsg()
        return false
      }
      return true
    },
    /************************************************************************************************
    * Function명  : fn_ShowAlertMsg
    * 설명        : 메세지 처리 후 후처리
    ************************************************************************************************/
    fn_CheckRestAlert () {
      this.rsltMsgArray = this.rsltMsgArray.splice(1)
      if (this.rsltMsgArray.length === 0) {
        this.lv_ShowCheck = false
        this.$refs.refShowCheck.close()
        this.fn_ShowAlertMsg(this.STR_currentAction, 'Y')
      } else {
        this.lv_ShowCheck = true
        this.$refs.refShowCheck.open()
      }
    },
    fn_showNoDsclsAlert (flg) {
      if (flg) {
        this.lv_noDsclsMessage = this.lv_noDsclsMessage.splice(1)
        this.$refs.refShowNoDsclsConfirm.close()
        // NEXT 사랑On : 버튼 disabled 해제
        this.lv_EltrnSignBtnDisable = false
      }
      if (this.lv_noDsclsMessage.length > 0) {
        // this.lv_showNoDsclsConfirm = true
        this.$refs.refShowNoDsclsConfirm.open()
      } else {
        if (this.lv_msgArr.length > 0) {
          Gaf.multiAlert(this.lv_msgArr, this.fn_ChkDone, this, null)
        }
        this.lv_msgArr = []
      }
    },
    fn_ShowAlertMsg (fncd, proceedYn, moIncomVO = null) {
      if ( this.rsltMsgArray.length > 0 ) {
        this.lv_ShowCheck = true
        this.$refs.refShowCheck.open()
      } else {
        window.vue.getStore('progress').dispatch('SHOW')
        let dummyEvent = '' // 이라인은 변수 dummyEvent 이거 파악후 삭제 해야됨 - 에러 방지 코드
        if (proceedYn === 'Y') {
          switch (fncd) {
            case 'P1':
              this.creationCompleteHandler(dummyEvent)
              break
            case 'P5':
              this.creationCompleteHandler(dummyEvent)
              break
            case 'P2':
              // 신전자서명 앱에서 pdf 를 띄우기 위한 암호화키 받아오기
              if ( this.lv_EncrKey == null || this.lv_EncrKey === '' ) {
                this.fn_GetEncrKey()
              }
              this.fn_Command_P8('P2', true)
              break
            case 'P6':
              this.nsAddInputValue = null

              // let popNs = {}
              // let stepSignDataManager = {}
              let authDataVO = {}

              if (this.isNsEusPrcs) { // 신규 전자 청약일 경우
                // stepSignDataManager.chnlCustId = this.MB_contrChnlCustId
                // stepSignDataManager.planId = this.MB_strPlanlId

                // popNs.isPopUp = true
                // popNs.opener = this

                if (this.moIncomVO === undefined || this.moIncomVO === '' ) { // 신규작성
                  // stepSignDataManager.isNewSignDoc = true
                  // stepSignDataManager.se4AuthVO = ''

                  // stepSignDataManager.nsFormFileList = this.nsFormFileList // PDF 구성 폼파일 리스트 디버그용도
                  // stepSignDataManager.zzaftRctmFg = this.zzaftRctmFg // 출금동의서 여부 로 4단계 Skip 여부 판별값
                  // 임시저장용 데이터 셋팅
                  this.objmsgAuth = {}
                  this.objmsgAuth.moblBusnScCd = 'SE' // 모바일업무구분코드
                  this.objmsgAuth.eltrnDoctScCd = '01' // 전자문서구분코드
                  this.objmsgAuth.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                  this.objmsgAuth.predMoblBusnScCd = 'SE' // 모바일업무구분코드
                  this.objmsgAuth.predEltrnDoctScCd = '01' // 전자문서구분코드
                  this.objmsgAuth.predEltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                  this.objmsgAuth.rqstrEno = this.headerVO.userEno // 요청자사번

                  // 본인인증에 필요한 VO세팅
                  authDataVO = {}
                  authDataVO.moblBusnScCd = this.objmsgAuth.moblBusnScCd
                  authDataVO.eltrnDoctScCd = this.objmsgAuth.eltrnDoctScCd
                  authDataVO.eltrnDoctId = this.objmsgAuth.eltrnDoctId
                  authDataVO.predMoblBusnScCd = this.objmsgAuth.predMoblBusnScCd
                  authDataVO.predEltrnDoctScCd = this.objmsgAuth.predEltrnDoctScCd
                  authDataVO.predEltrnDoctId = this.objmsgAuth.predEltrnDoctId
                  authDataVO.rqstrEno = this.objmsgAuth.rqstrEno

                  /* 보험거래문서디지털화PJT */
                  authDataVO.jsonInputData = JSON.parse(this.resultHptsPblHstrListVO.zaTrnsRsltCntnt) // 발행전문 json
                  authDataVO.pvmnotice = JSON.parse(this.pvmnotice) // 고지정보

                  // stepSignDataManager.authDataVO = authDataVO
                } else { // 중간 저장 리스트 선택 했을 때 시작 부분
                  // stepSignDataManager.isNewSignDoc = false
                  // stepSignDataManager.se4AuthVO = moIncomVO.se4AuthVO
                  // stepSignDataManager.nsFormFileList = null // PDF 구성 폼파일 리스트
                  // stepSignDataManager.zzaftRctmFg = '' // 재서명 상태일때에는 인증후 완료되어 값 참고 하는 단계까지 진행되지 않음

                  this.rewriteMode = true
                  this.objmsgAuth = moIncomVO.objmsgAuth
                  authDataVO = {}

                  authDataVO.moblBusnScCd = (this.objmsgAuth.hasOwnProperty('moblBusnScCd')) ? this.objmsgAuth.moblBusnScCd : '' // 모바일업무구분코드
                  authDataVO.eltrnDoctScCd = (this.objmsgAuth.hasOwnProperty('eltrnDoctScCd')) ? this.objmsgAuth.eltrnDoctScCd : '' // 전자문서구분코드
                  authDataVO.eltrnDoctId = (this.objmsgAuth.hasOwnProperty('eltrnDoctId')) ? this.objmsgAuth.eltrnDoctId : '' // 전자문서ID
                  authDataVO.predMoblBusnScCd = (this.objmsgAuth.hasOwnProperty('predMoblBusnScCd')) ? this.objmsgAuth.predMoblBusnScCd : '' // 모바일업무구분코드
                  authDataVO.predEltrnDoctScCd = (this.objmsgAuth.hasOwnProperty('predEltrnDoctScCd')) ? this.objmsgAuth.predEltrnDoctScCd : '' // 전자문서구분코드
                  authDataVO.predEltrnDoctId = (this.objmsgAuth.hasOwnProperty('predEltrnDoctId')) ? this.objmsgAuth.predEltrnDoctId : '' // 전자문서ID
                  authDataVO.rqstrEno = (this.objmsgAuth.hasOwnProperty('rqstrEno')) ? this.objmsgAuth.rqstrEno : '' // 요청자사번

                  // stepSignDataManager.authDataVO = authDataVO
                  this.lv_TimestampId = moIncomVO.moDCEncrKeyVO.timestampId
                  this.lv_EncrKey = moIncomVO.moDCEncrKeyVO.encrKey
                  this.saveCachePath = moIncomVO.tbtPbFilePath
                  this.checkIntervalType = 'P6'
                  this.tbtPblFileName = moIncomVO.tbtPblFileName.replace('.pdf', '')
                }

                // if ( this.lv_EncrKey == null || this.lv_EncrKey === '' ) {
                //   this.fn_GetEncrKey()
                // } else {
                //   // this.loadMobilePDF()
                // }
                // debugger
                console.log('this.lv_EncrKey : ', this.lv_EncrKey)
                this.fn_MoveTSSPS110M(authDataVO)
              } else { // 구버전 전자서명 진행
                // TEMP 김대중 PC버전에서만 동작
                // if ( plugin.getPlatform().toLowerCase().substring(0, 7) === 'windows' ) {
                //   this.fn_Command_P8('P6', true)
                //   return
                // }
                // let pop = {}
                // pop.isPopUp = true
                // pop.setOpener(this)

                // if ( moIncomVO === null ) {
                //   pop.se4AuthVO = {}
                //   // 본인인증 내역저장 VO세팅
                //   this.objmsgAuth = {}
                //   this.objmsgAuth.moblBusnScCd = 'SE' // 모바일업무구분코드
                //   this.objmsgAuth.eltrnDoctScCd = '01' // 전자문서구분코드
                //   this.objmsgAuth.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                //   this.objmsgAuth.predMoblBusnScCd = 'SE' // 모바일업무구분코드
                //   this.objmsgAuth.predEltrnDoctScCd = '01' // 전자문서구분코드
                //   this.objmsgAuth.predEltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                //   this.objmsgAuth.rqstrEno = (this.headerVO.userEno === null) ? '' : this.headerVO.userEno // 요청자사번
                // } else { // 중간 저장 리스트에서 선택했을 때 시작하는부분
                //   this.rewriteMode = true
                //   pop.se4AuthVO = moIncomVO.se4AuthVO
                //   this.objmsgAuth = moIncomVO.objmsgAuth
                //   this.lv_TimestampId = moIncomVO.moDCEncrKeyVO.timestampId
                //   this.lv_EncrKey = moIncomVO.moDCEncrKeyVO.encrKey
                //   this.saveCachePath = moIncomVO.tbtPbFilePath
                //   this.checkIntervalType = 'P6'
                //   this.tbtPblFileName = moIncomVO.tbtPblFileName.replace('.pdf', '')
                // }
              }
              break
            // #2020모바일청약
            case 'P10':
              this.nsAddInputValue = null

              // let popNs = {}
              // let stepSignDataManager = {}
              // let authDataVO = {}

              if (this.moIncomVO === undefined || this.moIncomVO === '' ) { // 신규작성
                // stepSignDataManager.isNewSignDoc = true
                // stepSignDataManager.se4AuthVO = ''

                // stepSignDataManager.nsFormFileList = this.nsFormFileList // PDF 구성 폼파일 리스트 디버그용도
                // stepSignDataManager.zzaftRctmFg = this.zzaftRctmFg // 출금동의서 여부 로 4단계 Skip 여부 판별값
                // 임시저장용 데이터 셋팅
                this.objmsgAuth = {}
                this.objmsgAuth.moblBusnScCd = 'SE' // 모바일업무구분코드
                this.objmsgAuth.eltrnDoctScCd = '01' // 전자문서구분코드
                this.objmsgAuth.eltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                this.objmsgAuth.predMoblBusnScCd = 'SE' // 모바일업무구분코드
                this.objmsgAuth.predEltrnDoctScCd = '01' // 전자문서구분코드
                this.objmsgAuth.predEltrnDoctId = this.resultHptsPblHstrListVO.tbtPblNo // 전자문서ID
                this.objmsgAuth.rqstrEno = this.headerVO.userEno // 요청자사번

                // 본인인증에 필요한 VO세팅
                authDataVO = {}
                authDataVO.moblBusnScCd = this.objmsgAuth.moblBusnScCd
                authDataVO.eltrnDoctScCd = this.objmsgAuth.eltrnDoctScCd
                authDataVO.eltrnDoctId = this.objmsgAuth.eltrnDoctId
                authDataVO.predMoblBusnScCd = this.objmsgAuth.predMoblBusnScCd
                authDataVO.predEltrnDoctScCd = this.objmsgAuth.predEltrnDoctScCd
                authDataVO.predEltrnDoctId = this.objmsgAuth.predEltrnDoctId
                authDataVO.rqstrEno = this.objmsgAuth.rqstrEno

                /* 보험거래문서디지털화PJT */
                authDataVO.jsonInputData = JSON.parse(this.resultHptsPblHstrListVO.zaTrnsRsltCntnt) // 발행전문 json
                // 모바일청약은 고지정보를 사용하지 않음
                // authDataVO.pvmnotice = JSON.parse(this.pvmnotice) // 고지정보-보완필요

                // stepSignDataManager.authDataVO = authDataVO
              } else { // 중간 저장 리스트 선택 했을 때 시작 부분
                // stepSignDataManager.isNewSignDoc = false
                // stepSignDataManager.se4AuthVO = moIncomVO.se4AuthVO
                // stepSignDataManager.nsFormFileList = null // PDF 구성 폼파일 리스트
                // stepSignDataManager.zzaftRctmFg = '' // 재서명 상태일때에는 인증후 완료되어 값 참고 하는 단계까지 진행되지 않음

                this.rewriteMode = true
                this.objmsgAuth = moIncomVO.objmsgAuth
                authDataVO = {}

                authDataVO.moblBusnScCd = (this.objmsgAuth.hasOwnProperty('moblBusnScCd')) ? this.objmsgAuth.moblBusnScCd : '' // 모바일업무구분코드
                authDataVO.eltrnDoctScCd = (this.objmsgAuth.hasOwnProperty('eltrnDoctScCd')) ? this.objmsgAuth.eltrnDoctScCd : '' // 전자문서구분코드
                authDataVO.eltrnDoctId = (this.objmsgAuth.hasOwnProperty('eltrnDoctId')) ? this.objmsgAuth.eltrnDoctId : '' // 전자문서ID
                authDataVO.predMoblBusnScCd = (this.objmsgAuth.hasOwnProperty('predMoblBusnScCd')) ? this.objmsgAuth.predMoblBusnScCd : '' // 모바일업무구분코드
                authDataVO.predEltrnDoctScCd = (this.objmsgAuth.hasOwnProperty('predEltrnDoctScCd')) ? this.objmsgAuth.predEltrnDoctScCd : '' // 전자문서구분코드
                authDataVO.predEltrnDoctId = (this.objmsgAuth.hasOwnProperty('predEltrnDoctId')) ? this.objmsgAuth.predEltrnDoctId : '' // 전자문서ID
                authDataVO.rqstrEno = (this.objmsgAuth.hasOwnProperty('rqstrEno')) ? this.objmsgAuth.rqstrEno : '' // 요청자사번

                // stepSignDataManager.authDataVO = authDataVO
                this.lv_TimestampId = moIncomVO.moDCEncrKeyVO.timestampId
                this.lv_EncrKey = moIncomVO.moDCEncrKeyVO.encrKey
                this.saveCachePath = moIncomVO.tbtPbFilePath
                this.checkIntervalType = 'P10'
                this.tbtPblFileName = moIncomVO.tbtPblFileName.replace('.pdf', '')
              }

              this.fn_MoveTSSPS112P(authDataVO)
              break
            case 'P44': // [비대면화상상담시스템구축PJT]2021.06.22
              this.fn_Command_P8('P44', true)
              break
            default:
              break
          }
        }
      }
    },
    /************************************************************************************************
    * Function명  : fn_MoveTSSPS110M
    * 설명        : 전자서명 페이지로 이동
    ************************************************************************************************/
    fn_MoveTSSPS110M (data) {
      let inputDataPage = {}
      inputDataPage.entplId = this.MB_strPlanlId // 가입설계 ID
      inputDataPage.zzaftRctmFg = this.zzaftRctmFg // 후입금여부
      inputDataPage.isMoblPftSale = this.isMoblPftSale // 모바일 완전판매 여부

      inputDataPage.moDCDocuMngVO = {} // 전자문서관리 VO
      inputDataPage.moDCDocuMngVO = this.fn_Command_C2_Sub()

      // 추가전달
      // 주피보험자특약수
      inputDataPage.insrdTrtyFgr = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0] === undefined ? null : this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov.length
      // 종피보험자특약수
      inputDataPage.aisdTrtyFgr = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[1] === undefined ? null : this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[1].abdacov.length
      // 공동모집여부
      inputDataPage.coprClctYn = this.lv_JointRecuit // 공동모집 정보
      inputDataPage.zzSecusFrmId = GafMainFunc.getIfbcValue('ZZSECUS_FRM_ID') // 증권양식코드
      inputDataPage.zzUntyCoITId = GafMainFunc.getIfbcValue('ZZUNTY_CO_I_T_ID') // 통합보장보험유형코드
      inputDataPage.zzPtclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드
      inputDataPage.zzKliaPrdtId = GafMainFunc.getIfbcValue('ZZKLIA_PRDT_ID') // 보협회보험구분코드
      inputDataPage.zaElectAppTargetProductFgRD = GafMainFunc.getIfbcValue('ZZELT_O_O_P_Y_ID') // 전자청약대상상품여부
      // inputDataPage.zzInsrTypMgId = GafMainFunc.getIfbcValue('ZZINSR_TYP_MG_ID') // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      inputDataPage.zzPrdtTypMgId = GafMainFunc.getIfbcValue('ZZPRDT_TYP_MG_ID') // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      // console.log('전자서명전_발행팝업.보험유형관리코드RD_ZZINSR_TYP_MG_ID [' + inputDataPage.zzInsrTypMgId + ']')
      console.log('전자서명전_발행팝업.상품유형관리코드RD_ZZPRDT_TYP_MG_ID [' + inputDataPage.zzPrdtTypMgId + ']')
      console.log('전자서명전_발행팝업.전자청약대상상품여부_ZZELT_O_O_P_Y_ID[' + inputDataPage.zaElectAppTargetProductFgRD + ']')

      // 상품보험관계구성코드
      inputDataPage.zzPdinsRCCId = ''
      let tmpAbdacov = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov
      for (let k in tmpAbdacov) {
        let el = tmpAbdacov[k]
        if (el.aldacov !== undefined && el.aldacov.zzinscdScCd !== undefined && el.aldacov.zzinscdScCd === '60') {
          inputDataPage.zzPdinsRCCId = el.aldacov.zzinscdScCd // GafMainFunc.getIfbcValue('ZZPDINS_R_C_C_ID', el.templateId)
          break
        }
      }

      // [ASR230600511] 보험유형관리코드RD 체크조건 수정_start
      // 변경전: 해당 상품의 가입가능한 전체 주보험 + 특약의 보험유형관리코드RD 조회
      // 변경후: 실제 가입설계한 주보험+특약의 보험유형관리코드RD 조회
      inputDataPage.zzInsrTypMgId = ''
      let tmpAbdacov2 = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov
      for (let k in tmpAbdacov2) {
        let el = tmpAbdacov2[k]
        let zzInsrTypMgId = ''
        if ( el !== undefined && el.productBaseId ) {
          zzInsrTypMgId = GafMainFunc.getIfbcValue('ZZINSR_TYP_MG_ID', el.productBaseId)
          if (!this.$bizUtil.isEmpty(zzInsrTypMgId)){
            let tmpList = zzInsrTypMgId.split(',')
            for(let idx = 0; idx < tmpList.length; idx++){
              if(tmpList[idx] === 'N01'){
                inputDataPage.zzInsrTypMgId = tmpList[idx]
                break
              }
            }
          }
          // if (zzInsrTypMgId === 'N01') {
          //   inputDataPage.zzInsrTypMgId = zzInsrTypMgId
          //   break
          // }
        }
      }
      console.log('전자서명전_발행팝업.보험유형관리코드RD_ZZINSR_TYP_MG_ID_수정 [' + inputDataPage.zzInsrTypMgId + ']')
      // 보험유형관리코드RD 체크조건 수정_end
      inputDataPage.zzPrmPadTVId = GafMainFunc.getIfbcValue('ZZPRM_PAD_T_V_ID') // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)
      console.log('전자서명전_발행팝업.보험료납입유형값RD.zzPrmPadTVId [' + inputDataPage.zzPrmPadTVId + ']')

      /* 보험거래문서디지털화PJT */
      inputDataPage.jsonInputData = data.jsonInputData // 발행전문 json
      inputDataPage.pvmnotice = data.pvmnotice // 고지정보

      inputDataPage.isAsgnProxyClamPsb = this.isAsgnProxyClamPsb // 지정대리청구인가능여부
      // debugger
      console.log('MSPPS410M inputData', inputDataPage)
      // debugger
      // 191204, 예금주상이 추가
      //         화면이동 변경 TSSPS110M
      // this.$MenuManager.fn_RouterPushSrnId('TSSPS110M', inputDataPage)
      this.$MenuManager.fn_RouterPushSrnId('MSPPS410M', inputDataPage)
      // this.$MenuManager.fn_RouterPushSrnId('TSSPS111P', inputDataPage)
      // this.$emit('cancelPopup')
    },
    /************************************************************************************************
    * Function명  : fn_MoveTSSPS112P
    * 설명        : 모바일청약 페이지로 이동
    ************************************************************************************************/
    fn_MoveTSSPS112P (data) {
      let inputDataPage = {}
      inputDataPage.entplId = this.MB_strPlanlId // 가입설계 ID
      // console.log('inputDataPage.entplId ::::: ', inputDataPage.entplId)
      inputDataPage.zzaftRctmFg = this.zzaftRctmFg // 후입금여부
      inputDataPage.isMoblPftSale = this.isMoblPftSale // 모바일 완전판매 여부

      inputDataPage.moDCDocuMngVO = {} // 전자문서관리 VO
      inputDataPage.moDCDocuMngVO = this.fn_Command_C2_Sub()

      // 추가전달
      // 주피보험자특약수
      inputDataPage.insrdTrtyFgr = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0] === undefined ? null : this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov.length
      // 종피보험자특약수
      inputDataPage.aisdTrtyFgr = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[1] === undefined ? null : this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[1].abdacov.length
      // 공동모집여부
      inputDataPage.coprClctYn = this.lv_JointRecuit // 공동모집 정보

      // IFBC
      inputDataPage.zzSecusFrmId = GafMainFunc.getIfbcValue('ZZSECUS_FRM_ID') // 증권양식코드
      inputDataPage.zzUntyCoITId = GafMainFunc.getIfbcValue('ZZUNTY_CO_I_T_ID') // 통합보장보험유형코드
      inputDataPage.zzPtclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드
      inputDataPage.zzKliaPrdtId = GafMainFunc.getIfbcValue('ZZKLIA_PRDT_ID') // 보협회보험구분코드
      inputDataPage.zaElectAppTargetProductFgRD = GafMainFunc.getIfbcValue('ZZELT_O_O_P_Y_ID') // 전자청약대상상품여부
      // inputDataPage.zzInsrTypMgId = GafMainFunc.getIfbcValue('ZZINSR_TYP_MG_ID') // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      inputDataPage.zzPrdtTypMgId = GafMainFunc.getIfbcValue('ZZPRDT_TYP_MG_ID') // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      inputDataPage.zzPrmPadTVId = GafMainFunc.getIfbcValue('ZZPRM_PAD_T_V_ID') // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)
      // console.log('모청전_발행팝업.보험유형관리코드RD_ZZINSR_TYP_MG_ID [' + inputDataPage.zzInsrTypMgId + ']')
      console.log('모청전_발행팝업.상품유형관리코드RD_ZZPRDT_TYP_MG_ID [' + inputDataPage.zzPrdtTypMgId + ']')
      console.log('모청전_발행팝업.전자청약대상상품여부_ZZELT_O_O_P_Y_ID[' + inputDataPage.zaElectAppTargetProductFgRD + ']')

      // 상품보험관계구성코드
      inputDataPage.zzPdinsRCCId = ''
      let tmpAbdacov = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov
      for (let k in tmpAbdacov) {
        let el = tmpAbdacov[k]
        if (el.aldacov !== undefined && el.aldacov.zzinscdScCd !== undefined && el.aldacov.zzinscdScCd === '60') {
          inputDataPage.zzPdinsRCCId = el.aldacov.zzinscdScCd // GafMainFunc.getIfbcValue('ZZPDINS_R_C_C_ID', el.templateId)
          break
        }
      }

      // [ASR230600511] 보험유형관리코드RD 체크조건 수정_start
      // 변경전: 해당 상품의 가입가능한 전체 주보험 + 특약의 보험유형관리코드RD 조회
      // 변경후: 실제 가입설계한 주보험+특약의 보험유형관리코드RD 조회
      inputDataPage.zzInsrTypMgId = ''
      let tmpAbdacov2 = this.pmodel.abdapolicy[0].abdapolpr[0].abdacovpac[0].abdacov
      for (let k in tmpAbdacov2) {
        let el = tmpAbdacov2[k]
        let zzInsrTypMgId = ''
        if ( el !== undefined && el.productBaseId ) {
          zzInsrTypMgId = GafMainFunc.getIfbcValue('ZZINSR_TYP_MG_ID', el.productBaseId)
          if (!this.$bizUtil.isEmpty(zzInsrTypMgId)){
            let tmpList = zzInsrTypMgId.split(',')
            for(let idx = 0; idx < tmpList.length; idx++){
              if(tmpList[idx] === 'N01'){
                inputDataPage.zzInsrTypMgId = tmpList[idx]
                break
              }
            }
          }
          // if (zzInsrTypMgId === 'N01') {
          //   inputDataPage.zzInsrTypMgId = zzInsrTypMgId
          //   break
          // }
        }
      }
      console.log('모청전_발행팝업.보험유형관리코드RD_ZZINSR_TYP_MG_ID_수정 [' + inputDataPage.zzInsrTypMgId + ']')
      // 보험유형관리코드RD 체크조건 수정_end

      /* 모바일청약 PJT */
      // inputDataPage.jsonInputData = data.jsonInputData // 발행전문 json
      // 발행전문 json: pmodel + oz발행전문 + 외부약관키 조합으로 구성.
      // 화면에서는 pmodel + oz발행전문까지만 구성.
      // PSMobpsContMngBIZ.prcsMobspPbl에서 외부약관키(externalnumId)까지 조회후 TTSD02003테이블에 저장
      let lv_InputData = {}
      lv_InputData.zaTrnsRsltCntnt = data.jsonInputData // ozJson
      lv_InputData.pmodel = this.pmodel
      lv_InputData.bnfrInfo = this.tmodel.bnfrInfo // 수익자 목록 저장
      if (!this.$bizUtil.isEmpty(this.tmodel.zCurrConvDto)) { // 통화 정보(외화보험 상품) - 환율정보: 모바일청약 달러보험 완료처리 시 사용 (2021.02.22 추가)
        lv_InputData.zCurrConvDto = this.tmodel.zCurrConvDto
      }

      inputDataPage.jsonInputData = lv_InputData

      inputDataPage.isAsgnProxyClamPsb = this.isAsgnProxyClamPsb // 지정대리청구인 지정가능여부
      inputDataPage.bnfrInfoList = this.tmodel.bnfrInfo // 수익자목록

      // 계약자가 법인사업자인 경우 법인명의 개인보험 화면으로 이동
      let mobpsSrnId = 'MSPPS510M' // 개인보험
      // let mobpsSrnId = 'TSSPS112P' // 개인보험

      let rltnrInfoList = this.tmodel.rltnrInfo
      let cnt = (rltnrInfoList) ? rltnrInfoList.length : 0
      if (cnt > 0 ) {
        for ( let i = 0; i < cnt; ++i) {
          // 계약관점피보험자유형코드, 11:계약자
          // 고객상세유형코드, 21:법인사업자
          if ( rltnrInfoList[i].zzcontvInsrdCd === '11' && rltnrInfoList[i].zzcusDtlTypCd === '21') {
            mobpsSrnId = 'MSPPS520M' // 법인명의 개인보험
            // mobpsSrnId = 'TSSPS113P' // 법인명의 개인보험
            break
          }
        }// for i
      }
      //GA매니저 가입설계 대행시
      if(this.$cmUtil.fn_GachangeTF()){
        console.log('영수증번호저장 MSPPI250P ', this.resultHptsPblHstrListVO.tbtPblNo)
        console.log('GA TGAM00010 영수증번호저장')
        this.gaMobpsSrnId = mobpsSrnId
        this.gaMnputDataPage = inputDataPage
        this.fn_insGaVuchNoReq(inputDataPage.entplId);
      }else{
        this.$MenuManager.fn_RouterPushSrnId(mobpsSrnId, inputDataPage)
        // this.$emit('cancelPopup')
      }
    },
    /******************************************************************************
     * Function명 : fn_insGaVuchNoReq
     * 설명       : 
     ******************************************************************************/
    fn_insGaVuchNoReq(entplId){
      let lv_vm = this
      let userId = this.getStore('userInfo').getters.getUserInfo.userId
      let fpEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      let reqYmd = this.$bizUtil.getDateToString(new Date(), '') // 오늘
      let pParams = {fnScCd:'05', dma_GAM00010DVO: {}}
      pParams.dma_GAM00010DVO = {mngerId: userId, fpEno: fpEno, reqYmd: reqYmd, aenam: userId, vuchNo: this.resultHptsPblHstrListVO.tbtPblNo, entplNo: entplId, mobpsYn: ''}
      
      let trnstId = 'txTSSPI08I2'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          console.log("영수증번호 저장 완료 :: " + response.body.dma_GAM00010DVO.vuchNo)
          lv_vm.lv_gaSaveDisable = true
          lv_vm.getStore('toast').dispatch('ADD', '저장되었습니다.')
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /************************************************************************************************
    * Function명  : fn_GAMoveTSSPS112P
    * 설명        : 모바일청약 페이지로 이동
    ************************************************************************************************/
    fn_GAMoveTSSPS112P () {
      this.$MenuManager.fn_RouterPushSrnId(this.gaMobpsSrnId, this.gaMnputDataPage)
    },
    // ====================================== 암호화키 관련 함수 start ====================================== //
    /**********************************************************************
     * 함수 : fn_GetEncrKey
     * 설명 : PDF 문서를 수신, 처리위해 enckey 요청 처리
     **********************************************************************/
    fn_GetEncrKey () {
      // 수신자를 고객등록 후 콤보박스로 보여주고 선택해서 보낼경우,
      //  선택된 고객의 이메일수신동의여부(emailReciCnsntYn)를 체크해야 한다.
      //  수신자의 이름을 가져올 수 있다.
      var inputData = {}

      // let commHeader = {
      //   eaiId: '',
      //   fahrTrnId: '',
      //   requestSystemCode: '',
      //   reqSrvcNm: 'DCEncrKeyUtil',
      //   reqMthdNm: 'getEncrKey',
      //   targetSystemCode: 'sli.dc',
      //   reqVONm: 'sli.mo.util.DCEncrKeyVO',
      //   resVONm: 'sli.mo.util.DCEncrKeyVO'
      // }
      /*
        20200508 보험거래문서디지털화PJT : 제공시스템코드 변경 및 유틸명 변경
      */
      let commHeader = {
        eaiId: '',
        fahrTrnId: '',
        requestSystemCode: '',
        reqSrvcNm: 'DCEncrKeyCtlImpl',
        reqMthdNm: 'getEncrKey',
        targetSystemCode: 'sli.fom',
        reqVONm: 'sli.mo.vo.dc.comm.DCEncrKeyVO',
        resVONm: 'sli.mo.vo.dc.comm.DCEncrKeyVO'
      }
      piCommonUtil.invokeDirect(null, inputData, this.fn_GetEncrKeyHandler, null, this.fn_GetEncrKeyFailHandler, this, commHeader)
    },
    fn_GetEncrKeyHandler (result, moreData) {
      // debugger
      let dCEncrKeyVO = result

      this.lv_EncrKey = dCEncrKeyVO.encrKey
      if (dCEncrKeyVO.timestampId === null) {
        this.lv_TimestampId = ' '
      } else {
        this.lv_TimestampId = dCEncrKeyVO.timestampId
      }

      if ( this.lv_EncrKey === undefined || this.lv_EncrKey === '' ) {
        // this.lv_ShowErrorPopup = true
        this.lv_AlertMsg = '암호화키를 가져오는 중 에러가 발생했습니다.'
        this.fn_ShowErrorMsg()
      } else {
        let encVO = {
          timestampId: this.lv_TimestampId,
          encrKey: this.lv_EncrKey,
          stndKeyId: '',
          pageRowCnt: ''
        }
        this.lv_MoIncompletePDFDataListVO.moDCEncrKeyVO = encVO
        // this.loadMobilePDF()
      }
    },
    fn_GetEncrKeyFailHandler (result, moreData) {
      // debugger
      console.log('getEncrKeyFail')
    },
    // ====================================== 암호화키 관련 함수 end ====================================== //
    /******************************************************************************
    * Function명 : fn_GetSelected
    * 설명        : 언어선택 바텀시트 선택 시 해당 언어 Array로 리턴
    * Params     : N/A
    * Return     : Array
    ******************************************************************************/
    fn_GetSelected () {
      let resultArr = []
      if (this.lv_SelectedLag === '1') resultArr.push('EN')
      if (this.lv_SelectedLag === '2') resultArr.push('JA')
      if (this.lv_SelectedLag === '3') resultArr.push('VI')
      if (this.lv_SelectedLag === '4') resultArr.push('CH')
      if (this.lv_SelectedLag === '5') resultArr.push('TH')
      if (this.lv_SelectedLag === '6') resultArr.push('RU')
      if (this.lv_SelectedLag === '7') resultArr.push('MO')
      return resultArr
    },
    /******************************************************************************
    * Function명 : fn_ClickBtnLag
    * 설명        : 언어선택 바텀시트에서 선택 시 처리 함수
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_ClickBtnLag (value) {
      // console.log(value)
      this.lv_SelectedLag = value
      this.lv_LangScCdArray = this.fn_GetSelected()
      // console.log('언어 선택 결과 ===> ', this.lv_LangScCdArray)
      this.$refs.refSelectLanguage.close()
      // this.lv_isSelectLanguage = false
    },
    fn_isLoadingMsg(bool) {
      this.isLoadingMsg = bool
    },

    //============================================================================
    // [선심사 및 영업시스템 고도화PJT] 추가 함수
    //============================================================================
    /************************************************************************************************
     * Function명  : fn_ChkNundwInfo
     * 설명        : 선심사 상태 체크하여 안내 메시지 노출 처리
     ************************************************************************************************/
    fn_ChkNundwInfo () {
      // this.lv_NundwMndtYn = zPIEntplPblItmListDto.nundwMndtYn // 선심사 필수구분
      // this.lv_ZaDsclsJdgPrgStatCd = zPIEntplPblItmListDto.zaDsclsJdgPrgStatCd // 선심사 진행구분코드
      // this.lv_ZaDsclsExpJdgPrgStatCd = zPIEntplPblItmListDto.zaDsclsExpJdgPrgStatCd // 선심사 진행예상구분코드
      // 1. 선심사필수 & 선심사 미진행 시 체크
      if (this.lv_NundwMndtYn === 'Y' && ['00', '10', '70'].includes(this.lv_ZaDsclsJdgPrgStatCd)) {
        if (this.lv_NundwYn === 'N') {
          this.lv_AlertMsg = '심사에 필요한 유의지급정보가 있습니다. 선심사를 통한 예상결과 확인이 필요합니다.'
        }
      }
      // 2. 거절 상태
      else if (this.lv_NundwYn === 'Y' && this.lv_ZaDsclsJdgPrgStatCd === '60') {
        this.lv_AlertMsg = '선심사 결과 \'거절\'이 존재하여 청약서 발행이 불가능합니다. 결과를 확인하고 재설계하거나 인심사를 요청하세요.'
      }
    
      // 3. 심사중 상태
      else if (this.lv_NundwYn === 'Y' && this.lv_ZaDsclsJdgPrgStatCd === '40') {
        this.lv_AlertMsg = '선심사 심사중으로 청약서 발행은 불가능합니다.'
      }

      // 4. 재설계필요 상태
      // else if (this.lv_NundwYn === 'Y' && this.lv_ZaDsclsJdgPrgStatCd === '80') {
      //   this.lv_AlertMsg = '선심사 결과 재설계가 필요하여 청약서 발행이 불가능 합니다. 결과를 확인하고 가입설계를 다시 진행해 주세요.'
      // }

      // 5. 미결 & 발행불가
      else if (this.lv_NundwYn === 'Y' && this.lv_ZaDsclsJdgPrgStatCd === '50' && (this.lv_ZaDsclsExpJdgPrgStatCd === '60' || this.lv_ZaDsclsExpJdgPrgStatCd === '80')) {
        this.lv_AlertMsg = '선심사 결과 청약서 발행이 불가능합니다. 급부별·특약별 심사결과 또는 전산심사 결과를 확인하세요.'
      }
      if (this.lv_AlertMsg.length > 0) {
        this.fn_ShowErrorMsg()
        return
      }

      // 7. 작성중 상태
      // 선심사 진행을 중단하고 청약서를 발행하시겠습니까? 예/아니오
      // 예 : 선심사DB삭제 & 청약서 발행 가능
      // 아니오 : 청약서 발행 X, 제안서만 가능
      if (this.lv_NundwYn === 'Y' && this.lv_ZaDsclsJdgPrgStatCd === '10') {
        this.lv_NundwConfirmMsg = '선심사 진행을 중단하고 청약서를 발행하시겠습니까?'
        this.$refs.refNundwConfirm.open()
      }
    },
    /******************************************************************************
    * Function명 : fn_DelNundwInfo
    * 설명       : 선심사 진행 건 삭제 함수
    ******************************************************************************/
    fn_DelNundwInfo () {
      this.$refs.refNundwConfirm.close()
      // 선심사 중지
      let lv_vm = this
      window.vue.getStore('progress').dispatch('SHOW')
      let params = {
        nundwInfoTrtDto: {
          planId: this.MB_strPlanlId, // 설계ID
          // dsclsId: this.lv_dsclsId, // 고지ID
          nundwTrtScCd: '50', // 선심사처리구분코드(10:조회, 25:등록, 30:이전고지불러오기, 50:선심사중지)
        }
      }
      piCommonUtil.invoke2('UF1PQL0482', params, this.fn_ResDelNundwInfo, null, null, this, this)
    },
    /******************************************************************************
    * Function명 : fn_ResDelNundwInfo
    * 설명       : 선심사 진행 건 삭제 함수
    ******************************************************************************/
    fn_ResDelNundwInfo(resultData) {
      console.log(`UF1PQL0482 resultData =====>`, resultData)
      if (resultData !== null && resultData !== '') {
        let nundwTrtScCd = resultData.nundwInfoTrtDto.nundwTrtScCd // 선심사처리구분코드
        if (nundwTrtScCd === '55') {
          // 목록 다시 조회하기
          this.fn_RequestSearch()
        }
      }
      window.vue.getStore('progress').dispatch('HIDE')
    },
    /************************************************************************************************
     * 함수 : fn_BtnPreSancPrcsClickHandler
     * 설명 : 유의대체계약 기능 버튼 클릭 핸들러 통합
     ************************************************************************************************/
    fn_BtnPreSancPrcsClickHandler () {
        console.log('fn_BtnPreSancPrcsClickHandler')
        let strMsg = ''        
        strMsg = '대상 : ' + this.lv_AtntRplcContTypeCdList
        this.lv_AtntRplcContTxt = strMsg
        this.$refs.refPrcsPreSancAlertFlag.open()
    },    
    /*************************************************************************
     * 함수 : fn_ConfirmPrcsPreSanc
     * 설명 : 유의대체계약 팝업을 닫고, 사전결재 처리 수행
     *************************************************************************/
    fn_ConfirmPrcsPreSanc () {
      this.$refs.refPrcsPreSancAlertFlag.close()
      this.lv_PreSancReqYn = 'Y'
      this.$nextTick(function () {
    	  this.$nextTick(() => {   		  
    		  this.$refs.actionSnackbar.show() // 토스트 팝업 호출
    	      if(!piCommonUtil.isEmpty(this.lv_PreSancNextFn)){
    	        	this.fn_BtnNsEusPrcsClickHandler(this.lv_PreSancNextFn)
    	        	this.lv_PreSancNextFn = ''
    	      }
    	  })
      })
    },
    /*************************************************************************
     * 함수 : fn_CancelprcsPreSanc 
     * 설명 : 유의대체계약 팝업을 닫고, 사전결재 처리 '아니오' 수행
     *************************************************************************/
    fn_CancelprcsPreSanc () {
        this.$refs.refPrcsPreSancAlertFlag.close()
        this.lv_PreSancReqYn = 'N'
        if(!piCommonUtil.isEmpty(this.lv_PreSancNextFn)){
        	this.fn_BtnNsEusPrcsClickHandler(this.lv_PreSancNextFn)
        	this.lv_PreSancNextFn = ''
        }
    },
    /*************************************************************************
     * 함수 : fn_ConfirmPrcsPreSancNextPrc
     * 설명 : 발행, 미리보기, 전자서명, 모바일 청약 전 사전결재 여부 확인 후 다음 프로세스 진행
     *************************************************************************/
    fn_ConfirmPrcsPreSancNextPrc (targetBtn) {
      	if(this.lv_PreSancObjNum === '1' && piCommonUtil.isEmpty(this.lv_PreSancReqYn)){
      		this.lv_PreSancNextFn = targetBtn
    		this.fn_BtnPreSancPrcsClickHandler() 
    	}else{
    		this.lv_PreSancNextFn = ''
    		this.fn_BtnNsEusPrcsClickHandler(targetBtn)
    	}  
    },
    /******************************************************************************
    * Function명 : fn_getEltrnSignBtnDisableModel
    * 설명       : 컨피그 데이터 수신. 전자서명 불가 알림 출력 및 블로킹 기기모델명 목록
    ******************************************************************************/
    // ASR240700780_갤럭시Z폴드6 전자서명 발행시 안내메시지 추가
    // 예시: 갤럭시 폴드6 모델명: SM-F956N
    async fn_getEltrnSignBtnDisableModel () {
      // 본 기기 모델명 조회
      let isMobile = window.vue.getStore('deviceState').getters.getIsMobile
      if (isMobile) {
        this.lv_rsltMNo = await PSCommUtil.fn_GetDeviceModelPlugin()
      } else {
        if (process.env.NODE_ENV === 'local') {
          this.lv_rsltMNo = 'Local'
        } else {
          this.lv_rsltMNo = 'Other'
        }
      }

      // 컨피그 데이터 수신. 전자서명 불가 알림 출력 및 블로킹 기기모델명 목록
      var t_This = this
      let pParams = {}
      let btnId = 'S'
      pParams = {envId: 'ELTRN_STOP_MODEL'}
    
      this.post(t_This, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            t_This.lv_eltrnSignBtnDisableModelList = response.body.returnDtlList
            console.log('fn_getEltrnSignBtnDisableModel.lv_eltrnSignBtnDisableModelList:' + t_This.lv_eltrnSignBtnDisableModelList )
          }
        })
        .catch(function (error) {
          console.log('■■■ fn_getEltrnSignBtnDisableModel.......')
          window.vue.error(error)
        })
    },
  }
}
</script>
<style scoped>
</style>